import React from 'react';

import { DropdownButton, Dropdown, FormControl } from 'react-bootstrap'

import ActionListSelector from '../action-list-selector'
import ActionListColumnsSelector from '../action-list-columns-selector'
import ActionListExportOptions from '../action-list-export-options'
import ActionRun from '../action-run'

import ActionComparison from '../action-comparison'
import ActionMatchValue from '../action-match-value'
import ActionLookup from './action-lookup'

export default class ActionFilter extends React.Component {
  constructor(props) {
    super(props);

    this.renderFilterModeSelector  = this.renderFilterModeSelector.bind(this)
    this.renderFilterModeOptions   = this.renderFilterModeOptions.bind(this)
    this.renderExportOptions       = this.renderExportOptions.bind(this)

    this.selectFilterMode = this.selectFilterMode.bind(this)
  }

  selectFilterMode(mode) {
    this.props.setActionOptionValue('filter_mode', mode)
  }

  renderFilterModeSelector() {
    const { action, lists, selectedList } = this.props;
    if(!selectedList) return null;

    const options = action.options;

    return (
      <div className='row mt-3'>
        <div className='col-md-5'>
          <label><strong>Filter Mode</strong></label>

          <DropdownButton 
            id="dropdown-basic-button"
            className='mr-2'
            title={options.filter_mode ? options.filter_mode : 'Filter Mode'} 
            size='md'
            variant='light'
            onSelect={this.selectFilterMode}
          >
            <Dropdown.Item 
              eventKey={'Comparison'}
            >
              Comparison
            </Dropdown.Item>
            <Dropdown.Item 
              eventKey={'Remove Blanks'}
            >
              Remove Blanks
            </Dropdown.Item>
            <Dropdown.Item 
              eventKey={'Match Value'}
            >
              Match Value
            </Dropdown.Item>
            <Dropdown.Item 
              eventKey={'Lookup'}
            >
              Lookup
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
    )
  }

  renderFilterModeOptions() {
    const { action, lists, selectedList } = this.props;
    if(!selectedList) return null;

    const options = action.options;

    switch(options.filter_mode) {
      case 'Comparison':
        return (
          <ActionComparison
            setActionOptionValue={this.props.setActionOptionValue}
            setActionOptionValues={this.props.setActionOptionValues}
            setActionOptionHash={this.props.setActionOptionHash}
            action={action} 
          />
        )
      case 'Match Value':
        return (
          <ActionMatchValue
            setActionOptionValue={this.props.setActionOptionValue}
            setActionOptionValues={this.props.setActionOptionValues}
            setActionOptionHash={this.props.setActionOptionHash}
            action={action} 
          />
        );
      case 'Lookup':
        return (
          <ActionLookup
            setActionOptionValue={this.props.setActionOptionValue}
            setActionOptionValues={this.props.setActionOptionValues}
            setActionOptionHash={this.props.setActionOptionHash}
            action={action}
            lists={lists}
          />
        )
      default:
        return null;
    }
  }

  renderExportOptions() {
    const { action, lists, selectedList } = this.props;
    if(!selectedList) return null;

    return (
      <ActionListExportOptions
        selectedList={selectedList}
        setActionOptionValue={this.props.setActionOptionValue}
        setActionOptionValues={this.props.setActionOptionValues}
        setActionOptionHash={this.props.setActionOptionHash}
        action={action}
      />
    )
  }

  render() {
    const { action, lists, selectedList } = this.props;

    return (
      <div className=''>
        <ActionListSelector
          action={action}
          lists={lists}
          list={selectedList}
          title="Lists"
          selectListId={this.props.selectListId}
        />

        <ActionListColumnsSelector
          setActionOptionValue={this.props.setActionOptionValue}
          setActionOptionHash={this.props.setActionOptionHash}
          title="Select Columns To Process"
          headerField='headers'
          action={action} 
          list={selectedList}
        />

        { this.renderFilterModeSelector() }
        { this.renderFilterModeOptions() }
        { this.renderExportOptions() }

        { selectedList ? 
            <ActionRun
              saveAsDraft={this.props.saveAsDraft}
              runAction={this.props.runAction}
              cloneAction={this.props.cloneAction}
              action={action}
              displayDeleteModal={this.props.displayDeleteModal}
            /> : null
        }
      </div>
    );
  }
}
