import React from 'react';

const EmptyPollVersions = (props) => {
  return (
    <div className='px-4'>
      <div className='d-flex'>
        <h4 className='mb-0'>No Versions Imported</h4>
        <a className='btn btn-dark btn-sm ml-4' href={`/polls/${props.poll.id}/versions/new`}>
          Import New Version
        </a>
      </div>
    </div>
  )
}

export default EmptyPollVersions;