import axios from 'lib/axios-config'
import { list, lists } from 'lib/schema'
import { match } from 'lib/string'
import { normalize } from 'normalizr'
import compact from 'lodash/compact'

export const createNewList = (blob, file, fileIndex, parentId = null) => {
  return (dispatch, getState) => {
    const url = `/lists`

    const formData = new FormData();

    formData.append("list[name]", file.name)
    formData.append("list[file]", blob)

    if(parentId) {
      formData.append("list[parent_id]", parentId)
    }
    
    dispatch({
      type: 'CREATE_LIST_REQUEST'
    });

    return axios.post(url, formData, {
      onUploadProgress: function (progressEvent) {
        let uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)

        uploadPercentage = uploadPercentage >= 90 ? 90 : uploadPercentage

        dispatch(updateFileUploadProgress(file, uploadPercentage, fileIndex))
        return uploadPercentage
      },
      onDownloadProgress: function(progressEvent) {
        dispatch(updateFileUploadProgress(file, 100, fileIndex))
      }
    })
      .then((response) => {
        dispatch({
          type: 'CREATE_LIST_SUCCESS'
        })

        return Promise.resolve()
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_LIST_FAILURE',
          payload: {
            fileIndex,
            error: error.response.data.error,
          }
        })

        return Promise.reject()
      })
  }
}

export const updateList = (params = {}) => {
  return (dispatch, getState) => {
    const state = getState();
    const id = state.lists.listId
    const url = `/api/lists/${id}`

    if(state.lists.isUpdating || !id) {
      return Promise.reject()
    }

    dispatch({
      type: 'UPDATE_LIST_REQUEST'
    })

    return axios.put(url, params)
      .then((response) => {
        const normalized = normalize(response.data, list)
        dispatch({
          type: 'UPDATE_LIST_SUCCESS',
          payload: {
            entities: normalized.entities,
            id: normalized.result
          }
        })
        return Promise.resolve()
      }) 
      .catch((error) => {
        dispatch({
          type: 'UPDATE_LIST_FAILURE',
          payload: {
            error: error.response.data.error
          } 
        })

        return Promise.reject()
      })
  }
}

export const deleteList = (list, listIndex) => {
  return (dispatch, getState) => {
    const state = getState();
    const url = `/api/lists/${list.id}`

    if(state.lists.isDeleting) {
      return Promise.reject()
    }

    dispatch({
      type: 'DELETE_LIST_REQUEST'
    })

    return axios.delete(url)
      .then((response) => {
        dispatch({
          type: 'DELETE_LIST_SUCCESS',
          payload: {
            listId: list.id, 
            listIndex,
          }
        })
        return Promise.resolve()
      }) 
      .catch((error) => {
        dispatch({
          type: 'DELETE_LIST_FAILURE',
          payload: {
            error: error.response.data.error
          } 
        })

        return Promise.reject()
      })
  }
}

export const appendFile = (file) => {
  return {
    type: 'APPEND_FILE',
    payload: {
      file,
    }
  }
}

export const updateFileUploadProgress = (file, progress, fileIndex) => {
  return {
    type: 'UPDATE_FILE_UPLOAD_PROGRESS',
    payload: {
      file,
      progress,
      fileIndex,
    }
  }
}

export const clearFiles = () => {
  return {
    type: 'CLEAR_FILES'
  }
}

export const filterLists = (filter) => {
  return {
    type:  'FILTER_LISTS',
    payload: {
      filter,
    }
  }
}

export const selectList = (state) => {
  const lists = state.entities.lists;
  const id = state.lists.listId;

  if (!id) return {};

  return lists[id]
}

export const selectErrorMessage = (state) => {
  return formatErrors(state.lists.error)
}

export const selectFiles =  (state) => {
  return state.lists.files;
}

const defaultState = {
  isFetching: false,
  isDeleting: false,
  isUpdating: false,
  didInvalidate: false,
  filter: '',
  error: null,
  ids: [],
  listId: null,
  files: [],
}

const listReducer = (state = defaultState, action) => {
  switch(action.type) {
    case 'FETCH_LISTS_REQUEST':
      return {
        ...state,
        isFetching: true,
      }
    case 'FETCH_LISTS_SUCCESS':
      return {
        ...state,
        ids: action.payload.ids, 
        isFetching: false,
      }
    case 'FETCH_LISTS_FAILURE':
      return {
        ...state,
        didInvalidate: true,
        isFetching: false,
        error: action.payload.error
      }
    case 'FETCH_LIST_REQUEST':
      return {
        ...state,
        isFetching: true,
      }
    case 'FETCH_LIST_SUCCESS':
      return {
        ...state,
        listId: action.payload.id, 
        isFetching: false,
      }
    case 'FETCH_LIST_FAILURE':
      return {
        ...state,
        didInvalidate: true,
        isFetching: false,
        error: action.payload.error
      }
    case 'UPDATE_LIST_REQUEST':
      return {
        ...state,
        isUpdating: true,
      }
    case 'UPDATE_LIST_SUCCESS':
      return {
        ...state,
        isUpdating: false,
      }
    case 'UPDATE_LIST_FAILURE':
      return {
        ...state,
        isUpdating: false,
        didInvalidate: true,
        error: action.payload.error
      }
    case 'DELETE_LIST_REQUEST':
      return {
        ...state,
        isDeleting: true
      }
    case 'DELETE_LIST_SUCCESS':
      const listIndex = action.payload.listIndex
      
      return {
        ...state,
        isDeleting: false,
        ids: [
          ...state.ids.slice(0, listIndex),
          ...state.ids.slice(listIndex + 1)
        ]
      }
    case 'DELETE_LIST_FAILURE':
      return {
        ...state,
        isDeleting: false,
        didInvalidate: true,
        error: action.payload.error,
      }
    case 'APPEND_FILE':
      return {
        ...state,
        files: [...state.files, {
          file: action.payload.file,
          progress: 0,
        }]
      }
    case 'UPDATE_FILE_UPLOAD_PROGRESS':
      var updatedFiles = state.files.map((file, index) => {

        if(action.payload.fileIndex === index){
          return { 
            ...file,
            progress: action.payload.progress
          }
        }

        return file
      })

      return {
        ...state,
        files: updatedFiles
      }
    case 'CREATE_LIST_REQUEST':
      return {
        ...state,
      }
    case 'CREATE_LIST_FAILURE':
      var updatedFiles = state.files.map((file, index) => {
        if(action.payload.fileIndex === index){
          return { 
            ...file,
            didInvalidate: true,
            error: action.payload.error
          }
        }

        return file
      })

      return {
        ...state,
        files: updatedFiles
      }
    case 'CLEAR_FILES':
      return {
        ...state,
        files: []
      }
    case 'FILTER_LISTS':
      return {
        ...state,
        filter: action.payload.filter,
      }
    default:
      return state;
  }
}

export default listReducer;