import React from 'react'

const ActionHeader = (props) => {
  return (
    <thead>
      <tr>
        <th width="65%">Name</th>
        <th>Type</th>
        <th>Status</th>
        <th>Created</th>
        <th>&nbsp;</th>
      </tr>
    </thead>
  )
}

export default ActionHeader;
