import React from 'react';

import filter from 'lodash/filter'
import { isEmpty, match } from 'lib/string'

class ActionListColumnsSelector extends React.Component {
  constructor(props) {
    super(props);

    this.toggleHeaderOption = this.toggleHeaderOption.bind(this)
    this.getHeaderOptionState = this.getHeaderOptionState.bind(this)
    this.isActive = this.isActive.bind(this)

    this.displaySelectedHeaders = this.displaySelectedHeaders.bind(this)
    this.displaySearchFilter = this.displaySearchFilter.bind(this)

    this.setFilter = this.setFilter.bind(this)
    this.clearAll = this.clearAll.bind(this)

    this.selectHeaders = this.selectHeaders.bind(this)

    this.state = {
      q: '',
    }
  }

  selectHeaders(headers) {
    return filter(headers, (header) => {
      return match(header, this.state.q)
    })
  }

  displaySelectedHeaders() {
    const { action, headerField } = this.props;

    if(!action || !action.options || !action.options[headerField]) return;

    const filterHeaders = action.options[headerField];
    const keys = Object.keys(filterHeaders);

    let items = [];

    for(var i = 0; i < keys.length; i++) {
      const header = keys[i];
      const headerState = filterHeaders[header];

      if(headerState) {
        items.push(
          <span key={`header-summary-${i}`} 
            className="badge badge-pill badge-light mr-1"
            style={{'whiteSpace': 'normal', 'textAlign': 'left'}}
          >
            { header }
          </span>
        )
      } 
    }

    if(items.length === 0) return null;

    return (
      <div className='mb-2'>
        { items }
        <span 
          className="badge badge-pill badge-dark" 
          onClick={(e) => {
            e.preventDefault()
            this.clearAll();
          }}
        >
          Clear All
        </span>
      </div>
    )
  }

  setFilter(q) {
    this.setState({
      q
    })
  }

  getHeaderOptionState(header) {
    const { action, headerField } = this.props;

    if(!action || !action.options || !action.options[headerField]) return;

    return action.options[headerField][header];
  }

  isActive(header) {
    return this.getHeaderOptionState(header) == true
  }

  clearAll() {
    this.props.setActionOptionValue(this.props.headerField, {})
  }

  toggleHeaderOption(header) {
    const headerState = this.getHeaderOptionState(header)

    let newHeaderState = true;
    if(headerState) {
      newHeaderState = false;
    }

    if(this.props.multi) {
      this.props.setActionOptionHash(this.props.headerField, header, newHeaderState)
    } else {
      this.props.setActionOptionValue(this.props.headerField, {
        [header]: newHeaderState
      })
    }
  }

  displaySearchFilter() {
    return (
      <>
        <input 
          type="text" 
          className='form-control mb-3' 
          placeholder={"Filter options"}
          value={this.state.q}
          onChange={(e) => {
            e.preventDefault();
            this.setFilter(e.target.value)
          }}
        />
      </>
    )
  }

  render() {
    const { action, list } = this.props;
    if(!action || !list) return null;

    const headers = this.selectHeaders(list.headers)

    return (
      <div className='row mt-3'>
        <div className='col-md-12'>
          <label><strong>{this.props.title}</strong></label>

          { this.displaySearchFilter() }
          { this.displaySelectedHeaders() }

          <div style={{'maxHeight': '450px', 'overflowY': 'scroll'}}>
            <ul className='list-group' style={{'cursor': 'pointer'}}>
            { headers.map((header, index) => {

              let className = 'list-group-item';
              className += this.isActive(header) ? ' active' : ''

              return (
                <li 
                  className={className} 
                  key={`header-${index}`}
                  onClick={(e) => {
                    e.preventDefault();
                    this.toggleHeaderOption(header)
                  }}
                >
                  { header }
                </li>
              )
            })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

ActionListColumnsSelector.defaultProps = {
  multi: true
}

export default ActionListColumnsSelector; 