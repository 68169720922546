import React from 'react';

import ActionDestinationListName from './action-destination-list-name'
import { DropdownButton, Dropdown, FormControl } from 'react-bootstrap'

export default class ActionListExportOptions extends React.Component {
  constructor(props) {
    super(props);

    this.selectExportMode = this.selectExportMode.bind(this)
    this.setActionListName = this.setActionListName.bind(this)

    this.renderExportMode = this.renderExportMode.bind(this)
    this.renderWithOptions = this.renderWithOptions.bind(this)
  }

  renderDestinationListName() {
    const { action, lists, selectedList } = this.props;
    if(!selectedList) return null;

    return (
      <ActionDestinationListName
        list={selectedList}
        setActionListName={this.setActionListName}
        action={action}
      />
    )
  }

  setActionListName(name) {
    this.props.setActionOptionValue('destination_list_name', name) 
  }

  selectExportMode(mode) {
    this.props.setActionOptionValue('export_mode', mode)
  }

  renderExportMode() {
    const { action, lists, selectedlist } = this.props;
    const options = action.options;

    return (
      <div className='row mt-3'>
        <div className='col-md-5'>
          <label><strong>Export Mode</strong></label>
          <DropdownButton 
            id="dropdown-basic-button"
            className='mr-2'
            title={options && options.export_mode ? options.export_mode : 'Export Mode'} 
            size='md'
            variant='light'
            onSelect={this.selectExportMode}
          >
            <Dropdown.Item 
              eventKey={'New List'}
            >
              New List 
            </Dropdown.Item>
            <Dropdown.Item 
              eventKey={'Overwrite List'}
            >
              Overwrite List 
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
    )
  }

  renderWithOptions() {
    if(this.props.forceNewList || this.props.forceOverwriteList) {
      return null;
    } else {
      return this.renderExportMode()
    }
  }

  render() {
    const { action, lists, selectedlist } = this.props;
    const options = action.options;

    return (
      <div>
        { this.renderWithOptions() }
        { options && options.export_mode === 'New List' ? this.renderDestinationListName() : null }
      </div>
    );
  }
}
