import React from 'react';

import ResourceContainer from '../ui/resource-container'
import ResourceHeader from '../ui/resource-header'

import PollSelector from './poll-selector'
import PaginationDropdown from '../pagination/pagination-dropdown'
import PaginationSelector from '../pagination/pagination-selector'

import axios from 'lib/axios-config'
import eventBus from 'lib/event-bus'
import withToaster from '../common/toaster-wrapper'

class Polls extends React.Component {
  constructor(props) {
    super(props);

    this.toggleFilterSearch = this.toggleFilterSearch.bind(this)
    this.fetchPolls = this.fetchPolls.bind(this)
    this.changePerPage = this.changePerPage.bind(this)
    this.changePage = this.changePage.bind(this)

    this.resource = 'Poll'
    this.state = {
      filterSearchVisible: false,
      query: '',
      page: 1,
      perPage: 15,
      total: 0,
      polls: [],
      q: '',
      filterValues: {
        status: 'all',
        node: 'all'
      }
    }
  }

  changePage(page) {
    const newPage = page.selected + 1;
    this.setState({
      page: newPage
    });

    this.fetchPolls({
      perPage: this.state.perPage, 
      q: this.state.q, 
      page: newPage,
      filterValues: this.state.filterValues,
    })
  }

  changePerPage(count) {
    this.setState({
      perPage: count,
      page: 1,
    })

    this.fetchPolls({
      perPage: count,  
      page: 1, 
      q: this.state.q, 
      filterValues: this.state.filterValues
    })
  }

  fetchPolls(options) {
    axios.get('/polls', {
      params: {
        q: options.q,
        per_page: options.perPage,
        page: options.page,
        ...options.filterValues
      }
    })
      .then((response) => {
        this.setState({
          polls: response.data.polls,
          total: response.data.pagination.total
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  componentDidMount() {
    this.fetchPolls(this.state)

    eventBus.on('PollSearch', (data) => {
      this.setState({
        q: data.q,
        page: 1,
        filterValues: data.filterValues,
      })

      this.fetchPolls({
        q: data.q, 
        page: 1, 
        perPage: this.state.perPage, 
        filterValues: data.filterValues 
      })
    })
  }

  componentWillUnmount() {
    eventBus.remove('PollsSearch')
  }

  toggleFilterSearch(e) {
    e.preventDefault()

    if(this.state.filterSearchVisible) {
      eventBus.dispatch('FilterSearchToggleOff')
    } else {
      eventBus.dispatch('FilterSearchToggleOn', { 
        resource: this.resource,
        filters: this.filterOptions 
      })
    }

    this.setState({filterSearchVisible: !this.state.filterSearchVisible})
  }

  render() {
    return (
      <ResourceContainer>
        <ResourceHeader title='Polls' description='View your polls here' col={6} />
        <div className='resource-body mt-2'>
          <PollSelector polls={this.state.polls} />
        </div>
      </ResourceContainer>

    );
  }
}

export default withToaster(Polls)