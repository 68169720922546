import React from 'react';

const ActionColumnTags = (props) => {
  const { action, headerField } = props;

  if(!action || !action.options || !action.options[headerField]) {
    return null;
  } 

  const headers = action.options[headerField]
  const keys = Object.keys(headers);
  let tags = []
  for(var i = 0; i < keys.length; i++) {
    const header = keys[i];
    
    if(headers[header]) {
      tags.push(
        <span
          className='badge badge-pill badge-dark mr-1'
          key={`column-index-${i}`}
        >
          { header }
        </span>
      )
    }
  }

  return tags;
}

export default ActionColumnTags;