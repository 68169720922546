import React, { Component } from 'react';

import ResourceContainer from '../../ui/resource-container'
import ResourceHeader from '../../ui/resource-header'
import ActionTypeSelector from './action-type-selector'
              
import ActionChunk from './config/action-chunk'
import ActionConcat from './config/action-concat'
import ActionFilter from './config/action-filter'
import ActionDedup from './config/action-dedup'
import ActionTag from './config/action-tag'
import ActionKBID from './config/action-kbid'
import ActionRandomize from './config/action-randomize'
import ActionApi from './config/action-api'
import ActionColumn from './config/action-column'
import ActionOccurence from './config/action-occurence'
import ActionFindAndReplace from './config/action-find-and-replace'

import axios from 'lib/axios-config'
import find from 'lodash/find'

import { toasterCacheSet } from 'lib/toaster-cache'
import withToaster from '../../common/toaster-wrapper'
import withDeleteManager from '../../common/delete-wrapper'

import { validateAction } from 'lib/validate'
import ActionKBIDConcat from './config/action-kbid-concat';
import ActionKBIDWeighting from './config/action-kbid-weighting';
import { includes } from 'lodash';

export class ActionsEdit extends Component {
  constructor(props) {
    super(props);

    this.fetchLists = this.fetchLists.bind(this)
    this.fetchList = this.fetchList.bind(this)
    this.fetchApiEndpoints = this.fetchApiEndpoints.bind(this) 
    
    this.runAction = this.runAction.bind(this)
    this.saveAsDraft = this.saveAsDraft.bind(this)
    this.cloneAction = this.cloneAction.bind(this)

    this.selectActionTypeId = this.selectActionTypeId.bind(this)
    this.renderActionConfigPanel = this.renderActionConfigPanel.bind(this)

    this.selectListId = this.selectListId.bind(this);
    this.setActionOptionValue = this.setActionOptionValue.bind(this)
    this.setActionOptionValues = this.setActionOptionValues.bind(this)
    this.setActionOptionHash = this.setActionOptionHash.bind(this)

    this.validateAction = this.validateAction.bind(this);
    this.selectActionType = this.selectActionType.bind(this)

    this.handleDeleteAction = this.handleDeleteAction.bind(this)

    this.state = {
      lists: [],

      selectedList: null,

      actionType: {},
      apiEndpoints: [],
      
      actionTypes: [],

      action: {
        name: '',
        source_list_id: null,
        action_type_id: null,
        status: 'draft',
        options: {
          filter_mode: '',
          filter_value: '',
          export_mode: '',
          destination_list_name: ''
        }
      }
    }
  }

  setActionOptionValue(key, value) {
    this.setState({
      action: {
        ...this.state.action,
        options: {
          ...this.state.action.options,
          [key]: value
        }
      }
    })
  }

  setActionOptionValues(changes) {
    if(!changes) return;
    let options = {};

    const keys = Object.keys(changes)
    for(var i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = changes[key];
      
      options[key] = value;
    }

    this.setState({
      action: {
        ...this.state.action,
        options: {
          ...this.state.action.options,
          ...options
        }
      }
    })
  }

  setActionOptionHash(key, hashKey, value) {
    this.setState({
      action: {
        ...this.state.action,
        options: {
          ...this.state.action.options,
          [key]: {
            ...this.state.action.options[key],
            [hashKey]: value
          }
        }
      }
    })
  }
  
  fetchLists() {
    axios.get('/lists/simple')
      .then((response) => {
        this.setState({
          lists: response.data.lists,
        });
      })
  }

  fetchApiEndpoints() {
    axios.get('/api_endpoints')
      .then((response) => {
        this.setState({
          apiEndpoints: response.data
        })
      })
  } 

  fetchActionTypes() {
    return axios.get('/action_types')
      .then((response) => {
        this.setState({
          actionTypes: response.data
        })

        return response.data
      })
  }

  saveAsDraft(e) {
    e.preventDefault()

    const { action } = this.state;

    action.status = 'draft'

    axios.put(`/pipeline/actions/${action.id}`, {action_params: action})
    .then((response) => {
      toasterCacheSet(response.data.message, 'success')
      window.location = '/pipeline/actions';
    })
  }

  selectActionType(id) {
    if(!id) return;
    return find(this.state.actionTypes, ['id', id])
  }

  validateAction(action) {
    const actionType = this.selectActionType(action.action_type_id)
    const result = validateAction(action, actionType);

    if(result.valid) {
      return true;
    } else {
      this.props.toast(result.message, { type: 'error' })
      return false;
    }
  }

  runAction(e) {
    e.preventDefault()

    const { action } = this.state;

    action.status = 'pending'

    if(!this.validateAction(action)) return;

    axios.put(`/pipeline/actions/${action.id}`, {action_params: action})
    .then((response) => {
      toasterCacheSet(response.data.message, 'success')
      window.location = '/pipeline/actions';
    })
  }

  fetchList(id) {
    return axios.get(`/lists/${id}`)
      .then((response) => {
        const { action } = this.state;

        const options = {
          ...action.options
        }

        if(options.destination_list_name === '') {
          options.destination_list_name = response.data.name
        }

        this.setState({
          selectedList: response.data,
          action: {
            ...action,
            options, 
          }
        })

        return Promise.resolve(response.data)
      })
  }

  handleDeleteAction() {
    const { action } = this.props;
    if(!action || !action.id) return;

    axios.delete(`/pipeline/actions/${action.id}`)
      .then((response) => {
        this.props.hideDeleteModal()
        toasterCacheSet(`Action ${action.name} deleted.`, 'warning')
        window.location = '/pipeline/actions'
      }) 
  }

  cloneAction(e) {
    e.preventDefault();

    const { action } = this.state;
    if(!action || !action.id) return;

    axios.post(`/pipeline/actions/${action.id}/clone`)
      .then((response) => {
        toasterCacheSet('Action cloned', 'success')
        window.location = `/pipeline/actions/${response.data.id}/edit`
      })
  }

  componentDidMount() {
    this.props.initializeDeleteModalResource({
      resource: this.props.action,
      resourceName: 'action',
      url: '/pipeline/actions',
      deleteHandler: this.handleDeleteAction
    })

    this.setState({
      action: this.props.action
    }, () => {
      this.fetchLists();
      this.fetchApiEndpoints()
      this.fetchActionTypes()
        .then((actionTypes) => {
          this.selectActionTypeId(this.props.action.action_type_id, actionTypes)
        })
      this.selectListId(this.props.action.source_list_id)
    })
  }

  renderActionConfigPanel() {
    const { lists, action, actionType } = this.state;

    switch(actionType.name) {
      case 'API':
        return (
          <ActionApi
            runAction={this.runAction}
            saveAsDraft={this.saveAsDraft}
            action={action}
            lists={lists}
            apiEndpoints={this.state.apiEndpoints}
            selectedList={this.state.selectedList}
            selectListId={this.selectListId}
            setActionOptionValue={this.setActionOptionValue}
            setActionOptionValues={this.setActionOptionValues}
            setActionOptionHash={this.setActionOptionHash}
          />
        )
      case 'Chunk':
        return (
          <ActionChunk
            runAction={this.runAction}
            saveAsDraft={this.saveAsDraft}
            action={action}
            lists={lists}
            selectedList={this.state.selectedList}
            selectListId={this.selectListId}
            setActionOptionValue={this.setActionOptionValue}
            setActionOptionValues={this.setActionOptionValues}
            setActionOptionHash={this.setActionOptionHash}
          />
        )
      case 'Column':
        return (
          <ActionColumn
            runAction={this.runAction}
            saveAsDraft={this.saveAsDraft}
            action={action}
            lists={lists}
            selectedList={this.state.selectedList}
            selectListId={this.selectListId}
            setActionOptionValue={this.setActionOptionValue}
            setActionOptionValues={this.setActionOptionValues}
            setActionOptionHash={this.setActionOptionHash}
          />
        )
      case 'Concat':
        return (
          <ActionConcat
            runAction={this.runAction}
            saveAsDraft={this.saveAsDraft}
            action={action}
            lists={lists}
            selectedList={this.state.selectedList}
            selectListId={this.selectListId}
            setActionOptionValue={this.setActionOptionValue}
            setActionOptionValues={this.setActionOptionValues}
            setActionOptionHash={this.setActionOptionHash}
          />
        )
      case 'Filter':
        return (
          <ActionFilter
            runAction={this.runAction}
            saveAsDraft={this.saveAsDraft}
            action={action}
            lists={lists}
            selectedList={this.state.selectedList}
            selectListId={this.selectListId}
            setActionOptionValue={this.setActionOptionValue}
            setActionOptionValues={this.setActionOptionValues}
            setActionOptionHash={this.setActionOptionHash}
            displayDeleteModal={this.props.displayDeleteModal}
          />
        )
      case 'Find & Replace':
        return (
          <ActionFindAndReplace
            runAction={this.runAction}
            saveAsDraft={this.saveAsDraft}
            action={action}
            lists={lists}
            selectedList={this.state.selectedList}
            selectListId={this.selectListId}
            setActionOptionValue={this.setActionOptionValue}
            setActionOptionValues={this.setActionOptionValues}
            setActionOptionHash={this.setActionOptionHash}
            displayDeleteModal={this.props.displayDeleteModal}
          />
        )
      case 'Dedup':
        return (
          <ActionDedup
            runAction={this.runAction}
            saveAsDraft={this.saveAsDraft}
            cloneAction={this.cloneAction}
            action={action}
            lists={lists}
            selectedList={this.state.selectedList}
            selectListId={this.selectListId}
            setActionOptionValue={this.setActionOptionValue}
            setActionOptionValues={this.setActionOptionValues}
            setActionOptionHash={this.setActionOptionHash}
            displayDeleteModal={this.props.displayDeleteModal}
          />
        )
      case 'Occurence':
        return (
          <ActionOccurence
            runAction={this.runAction}
            saveAsDraft={this.saveAsDraft}
            action={action}
            lists={lists}
            selectedList={this.state.selectedList}
            selectListId={this.selectListId}
            setActionOptionValue={this.setActionOptionValue}
            setActionOptionValues={this.setActionOptionValues}
            setActionOptionHash={this.setActionOptionHash}
          />
        )
      case 'Randomize':
        return (
          <ActionRandomize
            runAction={this.runAction}
            saveAsDraft={this.saveAsDraft}
            action={action}
            lists={lists}
            selectedList={this.state.selectedList}
            selectListId={this.selectListId}
            setActionOptionValue={this.setActionOptionValue}
            setActionOptionValues={this.setActionOptionValues}
            setActionOptionHash={this.setActionOptionHash}
          />
        )
      case 'Tag':
        return (
          <ActionTag
            runAction={this.runAction}
            saveAsDraft={this.saveAsDraft}
            action={action}
            lists={lists}
            selectedList={this.state.selectedList}
            selectListId={this.selectListId}
            setActionOptionValue={this.setActionOptionValue}
            setActionOptionValues={this.setActionOptionValues}
            setActionOptionHash={this.setActionOptionHash}
          />
        )
      case 'Normalize':
        return (
          <ActionKBID
            runAction={this.runAction}
            saveAsDraft={this.saveAsDraft}
            action={action}
            lists={lists}
            disabled={(action?.name || "").length < 1}
            apiEndpoints={this.state.apiEndpoints}
            selectedList={this.state.selectedList}
            selectListId={this.selectListId}
            setActionOptionValue={this.setActionOptionValue}
            setActionOptionValues={this.setActionOptionValues}
            setActionOptionHash={this.setActionOptionHash}
          />
        )
        case 'Concat Normalize':
          return (
            <ActionKBIDConcat
              runAction={this.runAction}
              saveAsDraft={this.saveAsDraft}
              action={action}
              lists={lists}
              disabled={(action?.name || "").length < 1}
              apiEndpoints={this.state.apiEndpoints}
              selectedList={this.state.selectedList}
              selectListId={this.selectListId}
              setActionOptionValue={this.setActionOptionValue}
              setActionOptionValues={this.setActionOptionValues}
              setActionOptionHash={this.setActionOptionHash}
            />
          )
        case 'Weighting':
          return (
            <ActionKBIDWeighting
              runAction={this.runAction}
              saveAsDraft={this.saveAsDraft}
              action={action}
              lists={lists}
              disabled={(action?.name || "").length < 1}
              apiEndpoints={this.state.apiEndpoints}
              selectedList={this.state.selectedList}
              selectListId={this.selectListId}
              setActionOptionValue={this.setActionOptionValue}
              setActionOptionValues={this.setActionOptionValues}
              setActionOptionHash={this.setActionOptionHash}
            />
          )
      default:
        return null;
    }
  }

  selectListId(id) {
    if(!id) return;

    this.setState({
      action: {
        ...this.state.action,
        source_list_id: id
      }
    })

    return this.fetchList(id)
  }

  selectActionTypeId(id, actionTypes) {
    const { action } = this.state;
    const actionType = find(actionTypes, {'id': parseInt(id)})

    if(!actionType) return;

    this.setState({
      action: {
        ...action,
        action_type_id: actionType.id,
      },
      actionType,
    })
  }

  render() {
    const { action, actionTypes, actionType } = this.state;
    const colWidth = actionType.name === 'Normalize' || actionType.name === "Weighting" ? 'col-12' : 'col-8';
    const isNameDisabled = includes(["Normalize", "Weighting"], actionType.name);

    return (
      <ResourceContainer>
        <ResourceHeader title='Actions' description='Edit an existing action' />

        <div className='row'>
          <div className={colWidth}>
            <div className='resource-panel mb-5'>
              <div className='resource-body p-4'>

                <form className='form'>
                  <div className='row'>
                    <div className='col-md-8'>
                      <label><strong>Name</strong></label>
                      <input 
                        type='text' 
                        placeholder='Will use destination name if left blank'
                        className='form-control' 
                        disabled={isNameDisabled}
                        value={action.name}
                        onChange={(e) => {
                          this.setState({action: {
                            ...action,
                            name: e.target.value
                          }})
                        }} 
                      />
                    </div>

                    <div className='col-md-4'>
                      <label><strong>Action</strong></label>
                      <ActionTypeSelector
                        actionTypes={actionTypes}
                        selectActionTypeId={this.selectActionTypeId}
                        currentActionType={actionType}
                      />
                    </div>
                  </div>

                  { this.renderActionConfigPanel() }
                </form>

              </div>
            </div>
          </div>
        </div>
      </ResourceContainer>
    );
  }
}

export default withToaster(withDeleteManager(ActionsEdit));