import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone'

const UploadNewListForm = (props) => {
  const onDrop = useCallback(acceptedFiles => {
    props.uploadFiles(acceptedFiles)
  }, [])

  const { 
    getRootProps, 
    getInputProps, 
    isDragActive 
  } = useDropzone({
    onDrop,
    accept: '.csv',
    maxSize: 100000000,
  })

  return (
    <div {...getRootProps()} >
      <form className='form create-new-list-form'>
        <div className='form-group'>

          <i className="fas fa-cloud-upload-alt mx-auto"></i>
          <h3 className='text-center mt-3'>Drag and drop to upload</h3>

          <h6 className='text-center'>
            or 
            <span className='file-upload'>
              <input
                type="file"
                accept=".csv"
                className='upload'
                {...getInputProps()}
              />
              <a 
                href="" 
                id="upload-button"
              >browse</a>​
            </span>
            to choose a file
          </h6>

          <p className='text-center'>
            <em>
            (Only csv files will be accepted) 
            </em>
          </p>
        </div>
      </form>
    </div>
  )
}

export default UploadNewListForm;