import React from 'react';

import ResourceContainer from '../../ui/resource-container'
import ResourceHeader from '../../ui/resource-header'
import VersionListSelector from './version-list-selector'
import ListColumnSelector from './list-column-selector'
import ColumnNameMapper from './column-name-mapper'

import axios from 'lib/axios-config'

class NewPollVersion extends React.Component {
  constructor(props) {
    super(props);

    this.fetchLists = this.fetchLists.bind(this)
    this.fetchList = this.fetchList.bind(this)
    this.selectListId = this.selectListId.bind(this)

    this.resetMappings = this.resetMappings.bind(this)
    this.setColumn = this.setColumn.bind(this)
    this.clearColumns = this.clearColumns.bind(this)
    this.setMapping = this.setMapping.bind(this)
    this.setSourceColumn = this.setSourceColumn.bind(this)

    this.submit = this.submit.bind(this)

    this.state = {
      lists: [],
      list: null,
      version: {
        columns: {},
        source_column: "",
        poll_id: null,
        list_id: null,
        mappings: {},
      }
    }
  }

  submit(e) {
    e.preventDefault();
    const { poll } = this.props;
    const url = `/polls/${poll.id}/versions`
    const params = this.state.version

    axios.post(url, params)
      .then((response) => {
        window.location = `/polls/${poll.id}`
      })
      .catch((error) => {
        console.error(error);
      })
  }

  componentDidMount() {
    this.setState({
      version: {
        ...this.state.version,
        poll_id: this.props.poll.id, 
      }
    })

    this.fetchLists();
  }

  fetchLists() {
    axios.get('/lists/simple')
      .then((response) => {
        this.setState({
          lists: response.data.lists,
        });
      })
  }

  selectListId(id) {
    this.setState({
      version: {
        ...this.state.version,
        list_id: id,
      }
    })

    return this.fetchList(id)
  }

  fetchList(id) {
    return axios.get(`/lists/${id}`)
      .then((response) => {
        this.setState({
          list: response.data,
        })

        return Promise.resolve(response.data)
      })
  }

  resetMappings(e) {
    e.preventDefault();

    const columns = this.state.version.columns;
    let mappings = {};
    
    const keys = Object.keys(columns);
    for(var i = 0; i < keys.length; i++) {
      const key = keys[i];
      const headerState = columns[key];

      if(headerState) {
        mappings[key] = key;
      }
    }

    this.setState({
      version: {
        ...this.state.version,
        mappings,
      }
    })
  }

  setSourceColumn(header, headerState) {
    let { source_column } = this.state.version;
    let { mappings } = this.state.version;

    if(headerState) {
      source_column = header;
    } else {
      source_column = ''
    }

    delete mappings[header];

    this.setState({
      version: {
        ...this.state.version,
        source_column,
        columns: {
          ...this.state.version.columns,
          [header]: false
        },
        mappings,
      }
    })
  }

  setColumn(header, headerState) {
    const { version } = this.state;
    let mappings = version.mappings;

    if(headerState) {
      mappings[header] = header;
    } else if (mappings.hasOwnProperty(header)) {
      delete mappings[header];
    }

    this.setState({
      version: {
        ...version,
        source_column: header === version.source_column ? '' : version.source_column,
        columns: {
          ...version.columns,
          [header]: headerState
        },
        mappings,
      }
    })
  }

  clearColumns() {
    this.setState({
      version: {
        ...this.state.version,
        columns: {},
        mappings: {}
      }
    })
  }

  setMapping(field, value) {
    this.setState({
      version: {
        ...this.state.version,
        mappings: {
          ...this.state.version.mappings,
          [field]: value
        }
      }
    })
  }

  render() {
    const { poll } = this.props;

    return (
      <ResourceContainer>
        <ResourceHeader title={`Polls - ${poll.name}`} description='Add new version for poll.' />

        <div className='row'>
          <div className='col-md-8'>
            <div className='resource-panel mb-5'>
              <div className='resource-body p-4'>
                <VersionListSelector
                  title='Select List To Import'
                  list={this.state.list}
                  lists={this.state.lists}
                  version={this.state.version}
                  selectListId={this.selectListId}
                />

                {
                  this.state.list &&
                    <ListColumnSelector
                      title={"Select Aggregate Columns"}
                      clearColumns={this.clearColumns}
                      setColumn={this.setColumn}
                      list={this.state.list}
                      version={this.state.version}
                      multi={true}
                    />
                }

                {
                  this.state.list && 
                    <ListColumnSelector
                      title={"Select Source Column"}
                      setSourceColumn={this.setSourceColumn}
                      list={this.state.list}
                      version={this.state.version}
                      multi={false}
                    />
                }

                {
                  this.state.list && this.state.version && this.state.version.columns &&
                    <ColumnNameMapper
                      version={this.state.version}
                      resetMappings={this.resetMappings}
                      setMapping={this.setMapping}
                    />
                }

                { this.state.list &&
                    <div className='row mt-3'>
                      <div className='col-md-12'>
                        <div className='float-right'>
                          <a href={`/polls/${poll.id}`} className='btn btn-light'>
                            Cancel
                          </a>

                          <button type='button' className='btn btn-success ml-3' onClick={this.submit}>
                            Create Version
                          </button>
                        </div>
                      </div>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </ResourceContainer>
    );
  }
}

export default NewPollVersion;
