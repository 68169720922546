import React from 'react';

import { DropdownButton, Dropdown, FormControl } from 'react-bootstrap'
import ActionRun from '../action-run'
import ActionListColumnsSelector from '../action-list-columns-selector'
import ActionListExportOptions from '../action-list-export-options'

import ActionListSelector from '../action-list-selector'

import { isEmpty } from 'lib/string'

export default class ActionChunk extends React.Component {
  constructor(props) {
    super(props);

    this.selectListId = this.selectListId.bind(this)

    this.renderChunkOptions = this.renderChunkOptions.bind(this)

    this.DEFAULT_CHUNK_COUNT = 10;
  }

  prepareString(s) {
    if(isEmpty(s)) return '';
    return s.toLocaleString('en-US')
  }

  renderChunkOptions() {
    const { action, lists, selectedList } = this.props;
    const { options } = action;

    return (
      <div className='row mt-3'>
        <div className='col-md-4'>
          <label>
            <strong>Number of Chunks</strong>
          </label>

          <input
            type="text"
            className='form-control'
            value={this.prepareString(options.chunk_count)}
            onChange={(e) => {
              let value = e.target.value;

              if(isEmpty(value)){
                this.props.setActionOptionValues({
                  chunk_count: '',
                  lists_size: selectedList.rows
                })
                return;
              }

              value = value.replace(",", "")
              value = parseInt(value);

              if(value > selectedList.rows) {
                value = selectedList.rows
              }
              
              this.props.setActionOptionValues({
                chunk_count: value,
                lists_size: Math.ceil(selectedList.rows / value)
              }) 
            }}
          />
        </div>

        <div className='col-md-4'>
          <strong>Rows per list</strong>
          <input
            type="text"
            className='form-control'
            value={this.prepareString(options.lists_size)}
            onChange={(e) => {
              let value = e.target.value;

              if(isEmpty(value)) {
                this.props.setActionOptionValues({
                  lists_size: '',
                  chunk_count: selectedList.rows
                })

                return;
              }

              value = value.replace(",", "")
              value = parseInt(value);

              if(value > selectedList.rows) {
                value = selectedList.rows
              }

              this.props.setActionOptionValues({
                lists_size: value,
                chunk_count: Math.ceil(selectedList.rows / value)
              }) 
            }}
          />
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.props.setActionOptionValue('export_mode', 'New List')
  }

  selectListId(value) {
    this.props.selectListId(value)
      .then((list) => {
        const rows = list.rows;

        this.props.setActionOptionValues({
          lists_size: Math.ceil(rows / this.DEFAULT_CHUNK_COUNT),
          chunk_count: this.DEFAULT_CHUNK_COUNT 
        })
      })
  }

  render() {
    const { action, lists, selectedList } = this.props;

    return (
      <div>
        <ActionListSelector
          action={action}
          lists={lists}
          list={selectedList}
          title="Lists"
          selectListId={this.selectListId}
        />

        { selectedList ? this.renderChunkOptions() : null }

        {
          selectedList ?
            <ActionListExportOptions
              forceNewList={true}
              selectedList={selectedList}
              setActionOptionValue={this.props.setActionOptionValue}
              setActionOptionValues={this.props.setActionOptionValues}
              setActionOptionHash={this.props.setActionOptionHash}
              action={action}
            /> : null
        }

        { selectedList ? 
            <ActionRun
              saveAsDraft={this.props.saveAsDraft}
              runAction={this.props.runAction}
              cloneAction={this.props.cloneAction}
              displayDeleteModal={this.props.displayDeleteModal}
              action={action}
            /> : null
        }
      </div>
    );
  }
}
