import React from 'react';
import { DropdownButton, Dropdown, FormControl, Button, ButtonGroup } from 'react-bootstrap'

const NewFieldSplitButton = (props = {}) => {
  return (
    <Dropdown as={ButtonGroup} >
      <Button variant="light" size='sm' onClick={(e) => {
        props.addFieldMapping()
      }}>Add New Field</Button>

      <Dropdown.Toggle split variant="light"  size='sm' id="dropdown-split-basic" />
        <Dropdown.Menu align='start'>
          <Dropdown.Item onClick={(e) => {
            props.addEmptyFieldMapping()
          }}>
            Add Blank Field
          </Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
  )
}

export default NewFieldSplitButton;