import React from 'react'

const ListHeader = (props) => {
  return (
    <thead>
      <tr>
        <th>Name</th>
        <th>Node</th>
        <th>Rows</th>
        <th>Columns</th>
        <th>Size</th>
        <th>Status</th>
        <th>Created</th>
        <th>&nbsp;</th>
      </tr>
    </thead>
  )
}

export default ListHeader;