import React from 'react';
import { DropdownButton, Dropdown, FormControl } from 'react-bootstrap'

import filter from 'lodash/filter'
import { isEmpty, match } from 'lib/string'

class ActionHeaderDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.selectHeaders = this.selectHeaders.bind(this)
    this.getHeaderTitle = this.getHeaderTitle.bind(this)
    this.handleToggle = this.handleToggle.bind(this)

    this.state = {
      q: ''
    }
  }

  handleToggle(e) {
    if(!e) {
      this.setState({q: ''})
    }
  }

  selectHeaders() {
    let { headers, reverseMappedIndexes } = this.props;
    const { q } = this.state;

    switch(this.props.showMappedState) {
      case 'all':
        headers = headers;

        break;
      case 'mappedOnly':
        headers = filter(headers, (header) => {
          return reverseMappedIndexes.hasOwnProperty(header)
        })

        break;
      case 'unMappedOnly':
        headers = filter(headers, (header) => {
          return !reverseMappedIndexes.hasOwnProperty(header)
        }) 

        break;
      default:
        headers = headers;
    }

    return filter(headers, (header) => {
      return match(header, q)
    })
  }

  getHeaderTitle() {
    return this.props.displayHeader ?
      this.props.displayHeader : 'Select a Header'
  }

  render() {
    const { displayHeader } = this.props;
    
    const headers = this.selectHeaders();

    return (
      <div>
        <DropdownButton 
          id="dropdown-basic-button"
          className='d-inline-block mr-2 action-header-dropdown'
          title={this.getHeaderTitle()} 
          onToggle={this.handleToggle}
          size='sm'
          variant='seconary'
        >
          <div className='px-3'>
            <FormControl
              autoFocus
              className="my-2"
              placeholder="Type to filter..."
              onChange={(e) => {
                this.setState({q: e.target.value})
              }}
              value={this.state.q}
            />
          </div>

          <div className='items'>
            {
              headers.map((header, index) => {
                const className = displayHeader == header ?
                  'active' : ''
                return (
                  <Dropdown.Item 
                    key={`header-value-${index}`} 
                    eventKey={header}
                    className={className}
                    onClick={(e) => {
                      this.props.selectHeader(index, header)
                    }}
                  >
                    {header}
                  </Dropdown.Item>
                )
              })
            }
          </div>
        </DropdownButton>
      </div>
    );
  }
}

export default ActionHeaderDropdown;