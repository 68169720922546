import React from 'react';
import NormalizationTableHeader from './normalization-table-header'
import NormalizationTableBody from './normalization-table-body'

const NormalizationTable = (props) => {
  const { name, mappings, index, mappingUIVisible, fieldLabels = [] } = props;

  return (
    <>
      <NormalizationTableHeader
        name={name}
        isVisible={mappingUIVisible[name]}
        cloneTable={props.cloneTable}
        showDeleteState={props.showDeleteState}
        toggleMappingVisible={props.toggleMappingVisible}
        setTableName={props.setTableName}
        removeNormalizationTableMapping={props.removeNormalizationTableMapping}
      />
      { mappingUIVisible[name] ?
          <NormalizationTableBody
            name={name}
            latestNewFieldMappingIndex={props.latestNewFieldMappingIndex}
            setMappingField={props.setMappingField}
            renameMappingField={props.renameMappingField}
            showDeleteState={props.showDeleteState}
            addFieldMapping={props.addFieldMapping}
            removeMappingField={props.removeMappingField}
            mappings={mappings}
            fieldLabels={fieldLabels}
          /> 
          : null
      }
    </>
  )
}

export default NormalizationTable;
