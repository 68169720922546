import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap'
import Select from 'react-select'

import find from 'lodash/find'

const SourceSelector = (props) => {
  const { sources } = props;

  const options = [];
  Object.entries(sources).forEach(([key, value]) => {
    options.push({
      value: key,
      label: key
    })
  })

  return (
    <Select
      defaultValue={[{value: 'All', label: 'All'}]}
      options={options}
      isMulti
      isClearable={false}
      isSearchable={false}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={(selected) => {
        let visibleSources = sources;
        Object.entries(sources).forEach(([key, value]) => {
          const candidate = find(selected, ['label', key]);
          if(candidate) {
            visibleSources[key] = true
          } else {
            visibleSources[key] = false
          }
        })

        props.setVisibleSources(visibleSources)
      }}
    />
  )
}

export default SourceSelector;