import { combineReducers } from 'redux'

import { createBrowserHistory } from 'history'
import { connectRouter } from 'connected-react-router'

export const history = createBrowserHistory()
import lists from 'modules/lists'

export const makeRootReducer = (asyncReducers) => {
  return combineReducers({
    router: connectRouter(history),
    lists: lists,
  })
}

export default makeRootReducer
