import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export default class PollDisplayHighchart extends React.Component {
  constructor(props) {
    super(props);

    this.prepareOptions = this.prepareOptions.bind(this)
    this.chartComponent = React.createRef();

    this.state = {
      options: {
        chart: {
          type: 'bar',
          style: {
            fontFamily: 'Raleway, sans-serif'
          }
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          title: {
              text: ''
          },
        },
        colors: ['#D13839', '#1D4A96'],
        plotOptions: {
          series: {
            stacking: 'normal',
          }
        },
        credits: {
          position: {
              align: 'left',
              verticalAlign: 'bottom',
              x: 10,
              y: 1000
          }
        },
        legend: {
          reversed: true
        }
      } 
    }
  }

  prepareOptions() {
    const { data, currentQuestion } = this.props;

    let categories = new Set();
    let series = [];
    const sources = Object.keys(data);

    for(var i = 0; i < sources.length; i++) {
      const source = sources[i];

      if(source == 'All' && sources.length > 1)
        continue;

      let seriesItem = {
        name: source,
        data: []
      }

      let options = Object.keys(data[source][currentQuestion]).sort();
      for(var j = 0; j < options.length; j++) {
        const option = options[j];
        categories.add(option);

        seriesItem.data.push([option, data[source][currentQuestion][option]])
      }

      series.push(seriesItem)
    }

    this.setState({
      options: {
        ...this.state.options,
        xAxis: {
          categories: Array.from(categories),
        },
        series,
      }
    })
  }

  componentDidMount() {
    this.prepareOptions()

    const container = this.chartComponent.current.container.current;
    container.style.width = "100%";
    container.style.height = "100%";

    this.chartComponent.current.chart.reflow();
  }

  render() {
    return (
      <div style={{height: '450px', width: '100%'}} className={`mt-4 ${this.props.px ? this.props.px : 'px-3'}`}>
        <HighchartsReact
          ref={this.chartComponent}
          highcharts={Highcharts}
          options={this.state.options}
        />
      </div>
    );
  }
}
