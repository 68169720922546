import React from 'react';

import axios from 'lib/axios-config'

import ResourceContainer from '../ui/resource-container'
import ResourceHeader from '../ui/resource-header'
import Select from 'react-select'

import EmptyPollVersions from './empty-poll-versions'
import VersionPicker from './version-picker'
import PollDisplayHighchart from './poll-display-highchart'
import SourceSelector from './source-selector'

import { toasterCacheSet } from 'lib/toaster-cache'
import withToaster from '../common/toaster-wrapper'
import withDeleteManager from '../common/delete-wrapper'

import { selectLatestPollVersion } from 'lib/polls'

import { 
  fetchPollVersion, 
  selectColumns, 
  selectMappings, 
  selectDataSources,
  selectQuestion,
  selectFirstQuestionTitle
} from 'modules/polls'

class ShowPoll extends React.Component {
  constructor(props) {
    super(props);

    this.renderPollVersions = this.renderPollVersions.bind(this)
    this.renderVisibleSources = this.renderVisibleSources.bind(this)
    this.renderImportedPollVersion = this.renderImportedPollVersion.bind(this)

    this.fetchPollVersion = this.fetchPollVersion.bind(this)
    this.selectQuestion = this.selectQuestion.bind(this)
    this.selectVersion = this.selectVersion.bind(this)
    this.setVisibleSources = this.setVisibleSources.bind(this)
    this.handleDeletePoll = this.handleDeletePoll.bind(this)
    this.deletePollVersion = this.deletePollVersion.bind(this)

    this.state = {
      loading: true,
      currentVersion: null,
      aggregates: {},
      mappings: {},
      columns: [],
      data: null,
      sources: [],
      currentQuestion: null,
      // visibleSources: {'All': true}
    }
  }

  handleDeletePoll() {
    const { poll } = this.props;
    if(!poll || !poll.id) return;

    axios.delete(`/polls/${poll.id}`)
      .then((response) => {
        this.props.hideDeleteModal()
        toasterCacheSet(`Poll ${poll.name} deleted.`, 'warning')
        window.location = '/polls'
      }) 
  }    
  setVisibleSources(visibleSources) {
    this.setState({
      visibleSources
    })
  }

  fetchPollVersion(pollId, versionId) {
    fetchPollVersion(pollId, versionId)
      .then((data) => {
        if(data.status !== 'success') {
          this.setState({loading: false})
        } else {
          const dataSources = selectDataSources(data);

          this.setState({
            loading: false,
            aggregates: data,
            columns: selectColumns(data),
            mappings: selectMappings(data),
            data: dataSources.data,
            sources: dataSources.sources,
            currentQuestion: selectFirstQuestionTitle(dataSources.data['All']),
          })
        }
      }) 
  }

  selectVersion(poll, version) {
    this.setState({
      currentVersion: version,
      currentQuestion: null,
      loading: true
    })

    this.fetchPollVersion(poll.id, version.id)
  }

  componentDidMount() {
    const { poll } = this.props;
    const versions = poll.poll_versions;

    this.props.initializeDeleteModalResource({
      resource: poll,
      resourceName: 'poll',
      url: '/polls',
      deleteHandler: this.handleDeletePoll
    })

    if(versions && versions.length > 0) {
      const version = selectLatestPollVersion(versions)
      if(!version) return;

      this.selectVersion(poll, version)
    }
  }

  selectQuestion(column) {
    this.setState({
      currentQuestion: column,
    })
  }

  renderVisibleSourcesOld() {
    const { visibleSources, data, currentQuestion, sources } = this.state;
    let renderedGraphSources = [];

    const visibleSourceKeys = Object.keys(visibleSources);
    for(var i = 0; i < visibleSourceKeys.length; i++) {
      const source = visibleSourceKeys[i];

      // Don't display a source
      if(!visibleSources[source]) {
        continue
      }

      renderedGraphSources.push(
        <div key={`source-${source}-${currentQuestion}-${i}`} className='col-md-6'>
          <PollDisplay
            data={data[source][currentQuestion]}
            source={source}
            key={`source-${source}-${currentQuestion}-${i}`}
          />
        </div>
      )
    }

    return renderedGraphSources;
  }

  renderVisibleSourcesOld2() {
    const { visibleSources, data, currentQuestion, sources } = this.state;

    const visibleSourceKeys = Object.keys(visibleSources);
    const visible = [];
    for(var i = 0; i < visibleSourceKeys.length; i++) {
      const source = visibleSourceKeys[i];

      // Don't display a source
      if(!visibleSources[source]) {
        continue
      }

      visible.push(source)
    }

    return (
      <PollDisplayBar
        data={data}
        currentQuestion={currentQuestion}
        sources={visible}
        key={`sources-${visible.join('-')}-${currentQuestion}`}
      />

      
    )
  }

  renderVisibleSources() {
    return (
      <PollDisplayHighchart 
        px={'px-0'}
        key={`sources-${this.state.currentQuestion}`}
        data={this.state.data} 
        currentQuestion={this.state.currentQuestion}
      />
    )
  }

  renderInitializedPollVersion() {
    return (
      <div className='px-4'>
        <div className='row'>
          <div className='col-md-6'>
            <p>Poll processing</p>
          </div>

          <div className='col-md-6'>
            <button className='btn btn-sm btn-muted float-right' onClick={this.deletePollVersion}>
              <i className="fas fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    )
  }

  deletePollVersion(e) {
    e.preventDefault();
    const { currentVersion } = this.state;
    const { poll } = this.props;

    if(!currentVersion) return; 

    axios.delete(`/polls/${poll.id}/versions/${currentVersion.id}`)
      .then((response) => {
        toasterCacheSet(`Poll version ${currentVersion.version} delete for ${poll.name}`, 'warning')
        window.location = `/polls/${poll.id}`
      })
  }

  renderImportedPollVersion() {
    const { mappings, columns, currentQuestion, currentVersion } = this.state;

    const options = columns.map((column, index) => {
      return { value: column, label: mappings[column]}
    })

    return (
      <div className='px-4'>
        <div className='row'>
          <div className='col-md-12'>
            <h5><strong>Question</strong></h5>
            <Select
              options={options} 
              onChange={(option) => {
                this.selectQuestion(option.value)
              }}
              width="300"
              defaultValue={{
                value: currentQuestion.column, 
                label: mappings[currentQuestion]
              }} 
            />
          </div>
        </div>

        <div className='row poll-graphs'>
            { this.renderVisibleSources() }
        </div>
      </div>
    )
  }

  renderPollVersions() {
    const { poll } = this.props;
    const versions = poll.poll_versions;

    if(!versions || versions.length === 0) {
      return (
        <div className='px-3'>
          <h5 className='mb-0'>No versions to display</h5>
        </div>
      )
    }

    if(this.state.loading) {
      return (
        <div className='px-4'/>
      );
    } else {
      const { mappings, columns, currentQuestion, currentVersion } = this.state;

      return (
        <>
          <div className='px-4'>
            <div className='row'>
              <div className='col-md-12'>
                <VersionPicker
                  isMobile={true}
                  versions={versions}
                  poll={poll}
                  currentVersion={this.state.currentVersion}
                  selectVersion={this.selectVersion}
                />
              </div>
            </div>
          </div>

          <hr/>

          {
            currentVersion.status === 'success' ? this.renderImportedPollVersion() : this.renderInitializedPollVersion()
          }
        </>
      )
    }
  }

  render() {
    return (
      <ResourceContainer>
        <ResourceHeader title='Polls' description='View poll data' />
        <div className='resource-panel mb-5'>
          <div className='resource-body pb-4'>
            <div className='pt-4 d-flex'>
              <h2 className='my-0 ml-3 font-weight-bolder' style={{'wordBreak': 'break-all'}}>
                { this.props.poll.name }
              </h2>
            </div>
            <hr/>
            { this.renderPollVersions() }
          </div>
        </div>
      </ResourceContainer>
    )
  }
}

export default withToaster(withDeleteManager(ShowPoll)); 