export function toasterCacheSet(message, type = 'info') {
  const string = JSON.stringify({
    message: message, 
    type
  })

  localStorage.setItem('toasterCache', string)
}

export function toasterCacheGet() {
  const string = localStorage.getItem('toasterCache')
  return JSON.parse(string);
}

export function toasterCacheClear() {
  localStorage.removeItem('toasterCache')
}
