import axios from "lib/axios-config";
import { titleCase } from "lib/string";
import { toasterCacheSet } from "lib/toaster-cache";
import { groupBy, isEmpty, keyBy, reject, without } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import flatten from "lodash/flatten";
import get from "lodash/get";
import map from "lodash/map";
import set from "lodash/set";
import uniq from "lodash/uniq";
import React, { Component } from "react";
import TextareaAutosize from "react-textarea-autosize";
import SelectWithFilter from "../../../../common/select-with-filter";
import ActionRun from "./kbid-action-run";
import SectionForm from "./kbid-common-section-form";
import KbidContentLoader from "./kbid-content-loader";
import { FixedSizeList } from "react-window";
import WeightingDownloadReportBtn from "./kbid-weighting-download-report-btn";

const OPTIONAL_DEFAULT_KEY = "OPTIONAL DEFAULT";

const cellStyle = {
  maxWidth: "420px",
  minWidth: "420px",
  width: "420px",
  wordWrap: "break-word",
  padding: "16px 4px",
};

const cellStyle2 = {
  maxWidth: "280px",
  minWidth: "280px",
  width: "280px",
  wordWrap: "break-word",
  padding: "16px 10px",
  overflow: "hidden",
};

const inputStyle = {
  padding: "4px 12px",
  fontSize: "1em",
  resize: "none",
};

const clickable = {
  cursor: "pointer",
};

const headerRowStyle = {
  border: "0px",
};

const lightBorder = {
  borderColor: "lightgray",
};

function PreviewBox({ values, record }) {
  return (
    <div className="col border rounded" style={lightBorder}>
      <div
        className="d-flex mt-2 font-weight-bold"
        style={{ height: "46px", borderBottom: "1px solid lightgray" }}
      >
        <div style={cellStyle2}>Old Answer</div>
        <div style={cellStyle2}>New Answer</div>
      </div>

      <FixedSizeList
        className="table__body"
        height={300}
        width={500}
        itemData={values}
        itemKey={(index) => `${values[index].old_answer}-${index}`}
        itemCount={values.length}
        itemSize={64}
      >
        {({ index, style }) => {
          const row = values[index];
          return (
            <div
              key={`${row.old_answer}-${index}`}
              style={style}
              className="d-flex mt-2 mb-3"
            >
              <div
                style={cellStyle2}
                className="ellipsis-2"
                title={row.old_answer}
              >
                {row.old_answer}
              </div>
              <div style={cellStyle2}>{row.new_answer} </div>
            </div>
          );
        }}
      </FixedSizeList>
    </div>
  );
}

function PreviewRow(props) {
  const { record } = props;

  return (
    <>
      <tr style={headerRowStyle}>
        <td style={cellStyle} className="font-weight-bold">
          Question Name
        </td>
        <td style={cellStyle} className="font-weight-bold">
          New Answers
        </td>
      </tr>
      <tr>
        <td style={cellStyle}>{record.question_name}</td>
        <td style={cellStyle}>
          <PreviewBox record={record} values={record.new_answers} />
        </td>
      </tr>
    </>
  );
}

const TableRow = ({ row, index, style, handleInputChange, disabled }) => {
  return (
    <div
      key={`${row.old_answer}-${index}`}
      style={style}
      className="d-flex mt-2 mb-3"
    >
      <div style={cellStyle2} className="ellipsis-2" title={row.old_answer}>
        {row.old_answer}
      </div>
      <div style={cellStyle2}>
        <TextareaAutosize
          value={row.new_answer ?? ""}
          className="form-control"
          style={inputStyle}
          onChange={(e) => handleInputChange(e.target.value, index)}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

function EditingRow(props) {
  const {
    record,
    onQuestionChange,
    onAnswersChange,
    disabled,
    statsQuestionsList,
    selectedQuestions,
    renderVirtualItem,
  } = props;
  const newAnswers = record.new_answers || [];

  const handleInputChange = (value, idx) => {
    const clone = [...newAnswers];
    clone[idx].new_answer = value;
    onAnswersChange(clone);
  };

  return (
    <tr>
      <td style={cellStyle}>
        <h6>Question Name</h6>
        <SelectWithFilter
          options={statsQuestionsList}
          title={record.question_name || "Select a Value"}
          onSelect={(val) => onQuestionChange(val)}
          excludeSet={selectedQuestions}
        />
      </td>
      <td style={{ paddingLeft: "20px" }}>
        <h6>New Answers</h6>
        <div
          className="d-flex mt-2 font-weight-bold"
          style={{ height: "46px" }}
        >
          <div style={{ ...cellStyle2, borderBottom: "1px solid lightgray" }}>
            Old Answer
          </div>
          <div style={{ ...cellStyle2, borderBottom: "1px solid lightgray" }}>
            New Answer
          </div>
        </div>
        <FixedSizeList
          className="table__body"
          height={300}
          width={580}
          itemData={{ newAnswers, handleInputChange, disabled }}
          itemKey={(index) => `${newAnswers[index].old_answer}-${index}`}
          itemCount={newAnswers.length}
          itemSize={64}
        >
          {renderVirtualItem}
        </FixedSizeList>
      </td>
    </tr>
  );
}

function HeaderRow(props) {
  return null;
}

function NoDataView(props) {
  return (
    <div
      className="col justify-content-center"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "40px",
      }}
    >
      <h4 className="text-center mt-4">No Answers</h4>
      <button
        className="btn btn-secondary mt-3 ml-3"
        onClick={(e) => {
          e.preventDefault();
          props.onAddRow();
        }}
      >
        Add Row
      </button>
    </div>
  );
}

const INITIAL_STATE = {
  lastId: -1,
  overallVisibilityState: "contract",
  sectionVisibility: {},
  newAnswers: [],
  statsQuestions: [],
  statsQuestionsMap: {},
  statsQuestionsList: [],
  selectedQuestions: new Set([]),
  isLoading: true,
  isSubmitting: false,
  interactionMode: "previewing",
};

class KBIDWeightingNewAnswers extends Component {
  constructor(props) {
    super(props);
    this.goToNext = this.goToNext.bind(this);
    this.fetchInitialData = this.fetchInitialData.bind(this);
    this.saveAction = this.saveAction.bind(this);
    this.handleRowAdd = this.handleRowAdd.bind(this);
    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.rebuildStep = this.rebuildStep.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.toggleSectionVisibility = this.toggleSectionVisibility.bind(this);
    this.toggleOverallVisibility = this.toggleOverallVisibility.bind(this);
    this.renderVirtualItem = this.renderVirtualItem.bind(this);

    const kbids = get(
      props.action,
      ["options", "step-kbids", "form", "kbids"],
      [""]
    );

    this.state = { ...INITIAL_STATE, kbids };
  }

  componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData() {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/weighting`;
    const newAnswersRequest = axios.get(uri, {
      params: { type: "new_answers" },
    });
    const statsColumnsRequest = axios.get(uri, {
      params: { type: "new_answers_stats" },
    });

    return Promise.all([newAnswersRequest, statsColumnsRequest]).then(
      ([newAnswersRes, statsRes]) => {
        const newAnswers = map(
          newAnswersRes.data.new_answers || [],
          (col, idx) => {
            const row = { ...col, deleted: false, id: idx, updated: false };
            const hasOptionalDefault = (row.new_answers || []).find(
              (ans) => ans.old_answer === OPTIONAL_DEFAULT_KEY
            );

            if (hasOptionalDefault) return row;

            return {
              ...row,
              new_answers: [
                ...row.new_answers,
                {
                  old_answer: OPTIONAL_DEFAULT_KEY,
                  new_answer: "",
                },
              ],
            };
          }
        );
        const statsQuestions = statsRes.data.new_answers;
        // dropdown values
        const statsQuestionsList = uniq(
          flatten(map(statsQuestions, (rec) => rec.question_name))
        ).map((name) => ({ name, id: name }));
        let sectionVisibility = {};
        newAnswers.forEach((_key, idx) => {
          sectionVisibility[idx] =
            this.state.overallVisibilityState === "expand";
        });

        this.setState((prev) => ({
          ...prev,
          ...INITIAL_STATE,
          lastId: newAnswers.length - 1,
          newAnswers,
          statsQuestions,
          statsQuestionsMap: keyBy(statsQuestions, "question_name"),
          selectedQuestions: new Set(map(newAnswers, "question_name")),
          statsQuestionsList,
          sectionVisibility,
          isLoading: false,
        }));
      }
    );
  }

  toggleSectionVisibility(id) {
    const updated = set(
      cloneDeep(this.state.sectionVisibility),
      id,
      !this.state.sectionVisibility[id]
    );
    this.setState({ sectionVisibility: updated });
  }

  toggleOverallVisibility() {
    this.setState((prevState) => {
      const newVisibilityState =
        prevState.overallVisibilityState === "expand" ? "contract" : "expand";
      let sectionVisibility = {};
      prevState.newAnswers.forEach((_key, idx) => {
        sectionVisibility[idx] = newVisibilityState === "expand";
      });
      return {
        ...prevState,
        overallVisibilityState: newVisibilityState,
        sectionVisibility,
      };
    });
  }

  handleRowUpdate(idx, path, value) {
    const updated = set(
      cloneDeep(this.state.newAnswers),
      `${idx}.${path}`,
      value
    );
    set(updated, `${idx}.updated`, true);

    if (path === "question_name") {
      const statRecord =
        this.state.statsQuestionsMap[updated[idx].question_name];
      set(updated, `${idx}.new_answers`, [
        ...statRecord.new_answers,
        {
          old_answer: OPTIONAL_DEFAULT_KEY,
          new_answer: "",
        },
      ]);
    }

    const selectedQuestionsStateUpdate =
      path === "question_name"
        ? { selectedQuestions: new Set(map(updated, "question_name")) }
        : {};
    this.setState({ newAnswers: updated, ...selectedQuestionsStateUpdate });
  }

  saveAction() {
    const { action, id } = this.props;
    action.status = "draft";
    action.options = {
      ...action.options,
      [id]: "done",
    };

    // :update action
    return axios.put(`/pipeline/actions/${action.id}`, {
      action_params: action,
    });
  }

  handleRowAdd() {
    this.setState((prev) => ({
      ...prev,
      newAnswers: [
        ...prev.newAnswers,
        {
          question_name: "",
          new_answers: [],
          deleted: false,
          updated: true,
          id: prev.lastId + 1,
          new: true,
        },
      ],
      lastId: prev.lastId + 1,
      interactionMode: "editing",
      sectionVisibility: { ...prev.sectionVisibility, [prev.lastId + 1]: true },
    }));
  }

  submitForm(submitData) {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/weighting`;

    return axios
      .post(uri, { type: "new_answers", data: submitData })
      .then((res) => res.data);
  }

  goToNext() {
    const { newAnswers: records } = this.state;
    const { goToStep, stepIdx, action, id, mode } = this.props;
    const isUpdated = records.some((q) => q.updated);

    // If re-visiting the step, dont submit data to api. Just go to next step.
    if ((!isUpdated && action.options[id] != null) || mode === "read-only") {
      goToStep(stepIdx + 1);
      return;
    }

    if (records.length < 1) return;

    const submitData = {
      new_answers: filter(records, (rec) => !rec.deleted).map((q) => ({
        question_name: q.question_name,
        new_answers: reject(
          q.new_answers,
          (answerObj) =>
            answerObj.old_answer === OPTIONAL_DEFAULT_KEY &&
            isEmpty(answerObj.new_answer)
        ),
      })),
    };

    this.setState({ isSubmitting: true }, () => {
      // If no changes were made on initial visit too,
      // dont submit data to api. Just mark as done and go to next step.
      const submitToApi =
        action.options[id] == null && !isUpdated
          ? Promise.resolve()
          : this.submitForm(submitData);

      submitToApi
        .then(() => this.saveAction())
        .then(() => goToStep(stepIdx + 1))
        .catch(() => {
          this.setState({ isSubmitting: false });
          toasterCacheSet("Unknown error", "error");
        });
    });
  }

  rebuildStep(type = "new_answers") {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/rebuild_weighting`;

    return this.setState({ isLoading: true }, () => {
      axios
        .post(uri, { type })
        .then(() => this.props.rebuildStep())
        .catch(() => {
          this.setState({ isSubmitting: false });
          toasterCacheSet("Unknown error", "error");
        });
    });
  }

  renderRow({ record }) {
    const Row =
      this.state.interactionMode !== "editing" ||
      this.props.mode === "read-only"
        ? PreviewRow
        : EditingRow;

    return (
      <Row
        record={record}
        statsQuestionsList={this.state.statsQuestionsList}
        selectedQuestions={this.state.selectedQuestions}
        onQuestionChange={(value) =>
          this.handleRowUpdate(record.id, "question_name", value)
        }
        onAnswersChange={(value) =>
          this.handleRowUpdate(record.id, "new_answers", value)
        }
        disabled={this.props.mode === "read-only"}
        renderVirtualItem={this.renderVirtualItem}
      />
    );
  }

  renderVirtualItem({ index, data, style }) {
    const item = data.newAnswers[index];

    return (
      <TableRow
        index={index}
        style={style}
        row={item}
        handleInputChange={data.handleInputChange}
        disabled={data.disabled}
      />
    );
  }

  render() {
    const { action, stepIdx, goToStep, disabled, mode } = this.props;
    const {
      isSubmitting,
      isLoading,
      overallVisibilityState,
      sectionVisibility,
      newAnswers,
    } = this.state;

    const sections = newAnswers.filter((rec) => !rec.deleted);

    return (
      <div>
        <h4 className="text-center mt-4">Step 3 - New Answers</h4>
        <div className="row justify-content-end">
          <button
            className="btn btn-sm btn-light mb-2 mr-2"
            onClick={(e) => {
              e.preventDefault();
              this.toggleOverallVisibility();
            }}
          >
            {overallVisibilityState === "expand"
              ? "Collapse All"
              : "Expand All"}
          </button>
          {mode !== "read-only" && (
            <button
              className="btn btn-sm btn-light mb-2 mr-2"
              onClick={(e) => {
                e.preventDefault();
                const nextMode =
                  this.state.interactionMode === "editing"
                    ? "previewing"
                    : "editing";

                this.setState({
                  interactionMode: nextMode,
                });
              }}
            >
              {titleCase(this.state.interactionMode)}
            </button>
          )}
        </div>
        <div>
          {isLoading ? (
            <KbidContentLoader />
          ) : sections.length === 0 ? (
            <NoDataView onAddRow={this.handleRowAdd} />
          ) : (
            map(sections, (record, idx) => {
              return (
                <SectionForm
                  key={`${record.id}-${idx}`}
                  rowKey={record.id}
                  id={
                    record.question_name === "" && record.new
                      ? `New Row ${idx}`
                      : record.question_name
                  }
                  records={[record]}
                  renderHeaderRow={HeaderRow}
                  Row={this.renderRow}
                  showVerified={false}
                  isVisible={sectionVisibility[record.id]}
                  toggleVisibility={() =>
                    this.toggleSectionVisibility(record.id)
                  }
                  deleteSection={
                    this.state.interactionMode === "editing"
                      ? () => this.handleRowUpdate(record.id, "deleted", true)
                      : undefined
                  }
                />
              );
            })
          )}
        </div>
        {this.state.interactionMode === "editing" && (
          <div className="row justify-content-start">
            <button
              className="btn btn-secondary mt-3 ml-3"
              onClick={(e) => {
                e.preventDefault();
                this.handleRowAdd();
              }}
            >
              Add Row
            </button>
          </div>
        )}
        <ActionRun
          action={action}
          displayDeleteModal={this.props.displayDeleteModal}
          goToNext={this.goToNext}
          goToPrevious={() => goToStep(stepIdx - 1)}
          isSubmitting={isSubmitting}
          rebuildStep={this.rebuildStep}
          hasRebuildDropdown
          stepDisabled={disabled}
          renderCustomAction={() => (
            <WeightingDownloadReportBtn action={action} />
          )}
        />
      </div>
    );
  }
}

export default KBIDWeightingNewAnswers;
