import React from 'react';
import { humanFileSize } from 'lib/string'
import { ProgressBar } from 'react-bootstrap'

const ListUploadRow = (props) => {
  const { file } = props;

  function getFileVariant(file) {
    if(file.didInvalidate)  {
      return 'danger'
    } else if (file.progress < 100) {
      return 'primary'
    } else {
      return 'success'
    }
  }

  function fileStatusMessage(file) {
    if(file.progress < 100) {
      return `${file.progress}%`
    } else if (file.progress === 100 && !file.didInvalidate) {
      return `Complete`
    } else {
      return file.error
    }
  }

  return (
    <div className='file-upload-status'>
      <i className="fas fa-file-csv"></i>

      <div className='info'>
        <h5>{ file.file.name }</h5>
        <span>{ humanFileSize(file.file.size, true) }</span>
        <ProgressBar
          now={file.progress}
          variant={getFileVariant(file)}
        />

        <div className='mt-2'>
          { fileStatusMessage(file) }
        </div>
      </div>
    </div>
  )
}

export default ListUploadRow;