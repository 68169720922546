import React from 'react';
import { 
  VictoryLegend, 
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryContainer, 
  VictoryAxis,
  VictoryLabel
} from 'victory'

import { chunkString } from 'lib/string'
import reverse from 'lodash/reverse'

export default class PollDisplayBar extends React.Component {
  constructor(props) {
    super(props);

    this.prepareData = this.prepareData.bind(this)
    this.multiLineLabel = this.multiLineLabel.bind(this)
    this.calcHeight = this.calcHeight.bind(this)

    this.BAR_WIDTH = 10;
    this.BAR_OFFSET = 11;

    this.colors = [
      '#D13839', 
      '#1D4A96', 
      '#4C12CA', 
      '#06192E', 
    ]

    this.state = {
      sources: {},
      labels: [],
      sum: 0,
      lines: 0
    }
  }

  multiLineLabel(label) {
    if(!label || label === '') return [''];
    return chunkString(label, 20)
  }

  componentDidMount() {
    if(!this.props.data) return;
    this.prepareData();
  }

  calcHeight() {
    const { sources, currentQuestion } = this.props;

    const responses = {}
    for(var i = 0; i < sources.length; i++) {
      const source = sources[i];

      const data = this.props.data[source][currentQuestion];
      const keys = Object.keys(data);
      for(var j = 0; j < keys.length; j++) {
        const question = keys[j];
        responses[question] = true;
      }
    }

    const uniqueReponseCount = Object.keys(responses).length
    return ((sources.length) * uniqueReponseCount * (this.BAR_WIDTH + 6.5)) + 70;
  }

  prepareData() {
    let sources = {};
    for(var i = 0; i < this.props.sources.length; i++) {
      const source = this.props.sources[i];

      sources[source] = []

      const data = this.props.data[source][this.props.currentQuestion];
      const keys = reverse(Object.keys(data).sort());

      for(var j = 0; j < keys.length; j++) {
        const question = keys[j];
        const value = data[question];

        const lines = this.multiLineLabel(question);

        sources[source].push({
          x: `${lines.join("\n")}`,
          y: value
        })
      }
    }

    this.setState({
      sources 
    })
  }

  render() {
    let { sources } = this.props;

    if(sources.length < 1) {
      return null;
    }

    return (
      <VictoryChart
        horizontal
        padding={{ top: 25, left: 65, right: 30, bottom: 45 }}
        height={this.calcHeight()}
      >
        <VictoryLegend
          y={10}
          x={180}
          padding={{ top: 0, bottom: 0 }}
          rowGutter={{ top: 0, bottom: 2 }}
          width={200}
          colorScale={this.colors}
          centerTitle
          orientation="horizontal"
          gutter={10}
          style={{
            labels: { fontSize: 6 },
          }}
          data={sources.map((source) => {
            return {name: source, symbol: { type: "square" }}
          })}
        />
        <VictoryAxis 
          style={{ 
            tickLabels: { fontSize: 6 }
          }}
        />
        <VictoryGroup 
          colorScale={this.colors}
          offset={this.BAR_OFFSET}
        >
          {
            sources.map((source, index) => {
              return (
                <VictoryBar
                  style={{ labels: { fontSize: 12 }}}
                  key={`source-${source}-${index}`}
                  data={this.state.sources[source]}
                  barWidth={this.BAR_WIDTH}
                />
              )
            })
          }
        </VictoryGroup>
        <VictoryAxis 
          dependentAxis={true} 
          label="Responses"
          style={{ 
            tickLabels: { fontSize: 6 },
            axisLabel: { fontSize: 8, padding: 20}
          }}
        />
      </VictoryChart>
    )
  }
}