import React from 'react'
import moment from 'moment'
import StatusBadge from '../../common/status-badge'

const ActionRow = (props) => {
  const { index, action } = props;
  const url = action.status === 'draft' ?
    `/pipeline/actions/${action.id}/edit` :
    `/pipeline/actions/${action.id}`

  return (
    <tr key={`action-${index}`}>
      <td>
        <div style={{'width': '400px', 'wordWrap': 'break-word'}}>
          { action.name }
        </div>
      </td>

      <td>
        { action && action.action_type ? action.action_type.name : '' }
      </td>
 
      <td>
        <StatusBadge status={action.status} />
      </td>

      <td>
        { moment(action.created_at).format('MM/DD/YYYY') }
      </td>

      <td>
        <a href={url}>
          View
        </a>
      </td>
      
    </tr>
  )
}

export default ActionRow;