import React from 'react';

import { DropdownButton, Dropdown, FormControl } from 'react-bootstrap'
import ActionRun from '../action-run'
import ActionListColumnsSelector from '../action-list-columns-selector'
import ActionListExportOptions from '../action-list-export-options'

import ActionListSelector from '../action-list-selector'

export default class ActionTag extends React.Component {
  constructor(props) {
    super(props);

    this.renderColumnOptions = this.renderColumnOptions.bind(this)
    this.renderFieldOptions = this.renderFieldOptions.bind(this)
    this.renderTagModeOptions = this.renderTagModeOptions.bind(this)
    this.renderTagModeSelector = this.renderTagModeSelector.bind(this)
    this.selectTagMode = this.selectTagMode.bind(this)
  }

  componentDidMount() {
    this.props.setActionOptionValues({
      new_column_value: 'tag',
      wireless_number_value: 'wireless',
      wireline_number_value: 'landline',
      malformed_number_value: 'malformed',
      invalid_number_value: 'invalid'
    })
  }

  renderColumnOptions() {
    const { action, lists, selectedList } = this.props;

    if(!selectedList) return null;

    const { options } = action;

    return (
      <div className='row mt-3'>
        <div className='col-md-4'>
          <label><strong>Column Name</strong></label>
          <input 
            type="text"
            className='form-control'
            value={options.new_column_value}
            onChange={(e) => {
              this.props.setActionOptionValue('new_column_value', e.target.value)
            }}
          />
        </div>
      </div>
    )
  }

  renderTagModeOptions() {
    const { action, lists, selectedList } = this.props;
    if(!selectedList) return null;
    const { options } = action;

    switch(options.tag_mode) {
      case 'Repeated Field':
        return (
          <div className='row mt-3'>
            <div className='col-md-4'>
              <label><strong>Column Name</strong></label>
              <input 
                type="text"
                className='form-control'
                value={options.new_column_value}
                onChange={(e) => {
                  this.props.setActionOptionValue('new_column_value', e.target.value)
                }}
              />
            </div>

            <div className='col-md-4'>
              <label><strong>Tag Value</strong></label>
              <input 
                type="text"
                className='form-control'
                value={options.tag_value}
                onChange={(e) => {
                  this.props.setActionOptionValue('tag_value', e.target.value)
                }}
              />
            </div>
          </div>
        )
      case 'Phone Type Lookup':
        return (
          <div>
            <ActionListColumnsSelector
              setActionOptionValue={this.props.setActionOptionValue}
              setActionOptionHash={this.props.setActionOptionHash}
              title="Select a Single Column To Process"
              headerField='headers'
              action={action} 
              multi={false}
              list={selectedList}
            />

            { this.renderColumnOptions() }
            { this.renderFieldOptions() }
          </div>
        )
      default:
        return null;
    }
  }

  renderFieldOptions() {
    const { action, lists, selectedList } = this.props;
    if(!selectedList) return null;
    const { options } = action;

    return (
      <div className='row mt-3'>
        <div className='col-md-3'>
          <label><strong>wireline_number</strong></label>
          <input 
            type="text"
            className='form-control'
            value={options.wireline_number_value}
            onChange={(e) => {
              this.props.setActionOptionValue('wireline_number_value', e.target.value)
            }}
          />
        </div>
        <div className='col-md-3'>
          <label><strong>wireless_number</strong></label>
          <input 
            type="text"
            className='form-control'
            value={options.wireless_number_value}
            onChange={(e) => {
              this.props.setActionOptionValue('wireless_number_value', e.target.value)
            }}
          />
        </div>
        <div className='col-md-3'>
          <label><strong>malformed_number</strong></label>
          <input 
            type="text"
            className='form-control'
            value={options.malformed_number_value}
            onChange={(e) => {
              this.props.setActionOptionValue('malformed_number_value', e.target.value)
            }}
          />
        </div>

        <div className='col-md-3'>
          <label><strong>invalid_number</strong></label>
          <input 
            type="text"
            className='form-control'
            value={options.invalid_number_value}
            onChange={(e) => {
              this.props.setActionOptionValue('invalid_number_value', e.target.value)
            }}
          />
        </div>
      </div>
    )
  }

  selectTagMode(mode) {
    this.props.setActionOptionValue('tag_mode', mode)
  }

  renderTagModeSelector() {
    const { action, lists, selectedList } = this.props;
    if(!selectedList) return null;

    const options = action.options;

    return (
      <div className='row mt-3'>
        <div className='col-md-5'>
          <label><strong>Tag Mode</strong></label>

          <DropdownButton 
            id="dropdown-basic-button"
            className='mr-2'
            title={options.tag_mode ? options.tag_mode : 'Tag Mode'} 
            size='md'
            variant='light'
            onSelect={this.selectTagMode}
          >
            <Dropdown.Item 
              eventKey={'Repeated Field'}
            >
              Repeated Field
            </Dropdown.Item>
            <Dropdown.Item 
              eventKey={'Phone Type Lookup'}
            >
              Phone Type Lookup
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
    )
  }
 
  render() {
    const { action, lists, selectedList } = this.props;

    return (
      <div className=''>
        <ActionListSelector
          action={action}
          lists={lists}
          list={selectedList}
          title="Lists"
          selectListId={this.props.selectListId}
        />

        { this.renderTagModeSelector() }
        { this.renderTagModeOptions() }

        {
          selectedList ?
            <ActionListExportOptions
              selectedList={selectedList}
              setActionOptionValue={this.props.setActionOptionValue}
              setActionOptionValues={this.props.setActionOptionValues}
              setActionOptionHash={this.props.setActionOptionHash}
              action={action}
            /> : null
        }

        { selectedList ? 
            <ActionRun
              saveAsDraft={this.props.saveAsDraft}
              runAction={this.props.runAction}
              cloneAction={this.props.cloneAction}
              action={action}
              displayDeleteModal={this.props.displayDeleteModal}
            /> : null
        }
      </div>
    );
  }
}