import React from 'react';

const StatusBadge = (props) => {
  let className = 'badge ';
  const { status } = props;

  switch(status) {
    case 'success':
      className += 'badge-success';
      break;
    case 'error':
      className += 'badge-danger';
      break;
    case 'processing':
      className += 'badge-info';
      break;
    case 'initialized':
      className += 'badge-secondary';
      break; 
    default:
      className += 'badge-light'
  }

  return (
    <div className={className}>
      { status ? status.toUpperCase() : '' }
    </div>
  )
}

export default StatusBadge;