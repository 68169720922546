import React from 'react';


const ActionApiSettings = (props) => {
  const { action } = props;
  const { options } = action;

  const renderVPAccount = () => {
    const vp_account = options.vp_account;
    if(!vp_account || !vp_account.account_data || !vp_account.account_data.company) {
      return null;
    }

    return (
      <div className='col-md-4'>
        <h4>VP Account</h4>
        { vp_account.account_data.company.name }
      </div>
    )
  }

  const renderVTAccount = () => {
    const vt_account = options.vt_account;
    if(!vt_account || !vt_account.account_data) {
      return null;
    }

    return (
      <div className='col-md-4'>
        <h4>VT Account</h4>
        { vt_account.account_data.name }
      </div>
    )
  }

  const endpoint = options.api_endpoint;
  let endpointView = null;

  switch(endpointView.name) {
    case 'Victory Phones':
      endpointView = renderVPAccount();
      break;
    case 'Victory Text':
      endpointView = renderVTAccount();
      break;
    default:
      endpointView = null;
  }

  return (
    <div className='row mt-3 text-break'>
      <div className='col-md-4'>
        <h4>API Endpoint</h4>
        { endpoint.name }
      </div>
      
      { endpointView }
    </div>
  );
}

export default ActionApiSettings;