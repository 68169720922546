import React from 'react';

import filter from 'lodash/filter'
import { isEmpty, match } from 'lib/string'

class ListColumnsSelector extends React.Component {
  constructor(props) {
    super(props);

    this.toggleHeaderOption = this.toggleHeaderOption.bind(this)
    this.getHeaderOptionState = this.getHeaderOptionState.bind(this)
    this.isActive = this.isActive.bind(this)

    this.displaySelectedHeaders = this.displaySelectedHeaders.bind(this)
    this.displaySearchFilter = this.displaySearchFilter.bind(this)

    this.setFilter = this.setFilter.bind(this)
    this.clearAll = this.clearAll.bind(this)

    this.selectHeaders = this.selectHeaders.bind(this)

    this.state = {
      q: '',
    }
  }

  selectHeaders(headers) {
    return filter(headers, (header) => {
      return match(header, this.state.q)
    })
  }

  displaySelectedHeaders() {
    const { version } = this.props;

    if(!version || !version.columns) return;

    let items = [];
    const keys = Object.keys(version.columns)

    for(var i = 0; i < keys.length; i++) {
      const key = keys[i]
      const columnState = version.columns[key];

      if(columnState) {
        items.push(
          <span key={`header-summary-${i}`} 
            className="badge badge-pill badge-light mr-1"
            style={{'whiteSpace': 'normal', 'textAlign': 'left'}}
          >
            { key }
          </span>
        )
      }
    }

    if(items.length === 0) return null;

    return (
      <div className='mb-2'>
        { items }
        <span 
          className="badge badge-pill badge-dark" 
          onClick={(e) => {
            e.preventDefault()
            this.clearAll();
          }}
        >
          Clear All
        </span>
      </div>
    )
  }

  setFilter(q) {
    this.setState({
      q
    })
  }

  getHeaderOptionState(header) {
    const { version } = this.props;

    if(!version || !version.columns) return;
    return version.columns[header];
  }

  isActive(header) {
    if(this.props.multi) {
      return this.getHeaderOptionState(header) == true
    } else {
      return this.props.version.source_column === header;
    }
  }

  clearAll() {
    this.props.clearColumns()
  }

  toggleHeaderOption(header) {
    let headerState = null;

    if(this.props.multi) {
      headerState = this.getHeaderOptionState(header)
    } else {
      headerState = this.props.version.source_column === header;
    }

    let newHeaderState = true;
    if(headerState) {
      newHeaderState = false;
    }

    if(this.props.multi) {
      this.props.setColumn(header, newHeaderState)
    } else {
      this.props.setSourceColumn(header, newHeaderState)
    }
  }

  displaySearchFilter() {
    return (
      <>
        <input 
          type="text" 
          className='form-control mb-3' 
          placeholder={"Filter options"}
          value={this.state.q}
          onChange={(e) => {
            e.preventDefault();
            this.setFilter(e.target.value)
          }}
        />
      </>
    )
  }

  render() {
    const { version, list } = this.props;
    if(!version || !list) return null;

    const headers = this.selectHeaders(list.headers)
    let style = {
      'overflowY': 'scroll'
    };

    if(this.props.multi) {
      style['maxHeight'] = '450px'
    } else {
      style['maxHeight'] = '250px'
    }

    return (
      <div className='row mt-5'>
        <div className='col-md-12'>
          <label><strong>{this.props.title}</strong></label>

          { this.displaySearchFilter() }
          { this.props.multi ? this.displaySelectedHeaders() : null }

          <div style={{...style}}>
            <ul className='list-group' style={{'cursor': 'pointer'}}>
            { headers.map((header, index) => {

              let className = 'list-group-item';
              className += this.isActive(header) ? ' active' : ''

              return (
                <li 
                  className={className} 
                  key={`header-${index}`}
                  onClick={(e) => {
                    e.preventDefault();
                    this.toggleHeaderOption(header)
                  }}
                >
                  { header }
                </li>
              )
            })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default ListColumnsSelector; 