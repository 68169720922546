import React from 'react';
import { connect } from 'react-redux'

import withProvider from '../common/provider-wrapper'
import ResourceContainer from '../ui/resource-container'
import ResourceHeader from '../ui/resource-header'
import UploadNewListForm from './upload-new-list-form'
import ListUploadRow from './list-upload-row'
import NewListOptions from './new-list-options'

import { dataURItoBlob } from 'lib/to-blob'
import withToaster from '../common/toaster-wrapper'
import axios from 'lib/axios-config'

import  {
  updateFileUploadProgress,
  appendFile,
  createNewList,
  selectFiles,
  clearFiles,
} from 'modules/lists'

import 'css/lists'

const mapStateToProps = (state) => {
  return {
    files: selectFiles(state),
  }
}

const mapDispatchToProps = {
  updateFileUploadProgress,
  appendFile,
  createNewList,
  clearFiles 
} 

class ListsNew extends React.Component {
  constructor(props) {
    super(props);

    this.renderBody = this.renderBody.bind(this)
    this.renderUploadingProgress = this.renderUploadingProgress.bind(this)

    this.uploadFiles = this.uploadFiles.bind(this)
    this.fileToBlob = this.fileToBlob.bind(this)

    this.fetchLists = this.fetchLists.bind(this)
    this.changeParentId = this.changeParentId.bind(this)

    this.state = {
      filesAdded: false,
      uploadComplete: false,
      lists: [],
      parentListId: null,
    }
  }

  componentDidMount() {
    this.fetchLists()
  }

  fetchLists() {
    axios.get('/lists/simple')
      .then((response) => {
        this.setState({
          lists: response.data.lists,
        });
      })
  }

  fileToBlob(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.readAsDataURL(file);

      reader.onload = () => {
        if (!!reader.result) {
          const blob = dataURItoBlob(reader.result);

          resolve(blob)
        } else {
          reject(Error('Failed converting to base64'))
        }
      }
    })
  }

  renderUploadingProgress() {
    const files = this.props.files.map((file, index) =>  {
      return (
        <ListUploadRow
          key={`file-${index}`}
          file={file}
        />
      )
    });

    return (
      <div className='col-md-6'>
        <h4>Uploading</h4>

        { files }
      </div>
    )
  }

  uploadFiles(files) {
    this.setState({
      filesAdded: true,
      uploadComplete: false,
    })

    const offset = parseInt(this.props.files.length);

    for(var index in files) {
      const file = files[index];
      const fileIndex = parseInt(index) + offset;
      this.props.appendFile(file);

      this.fileToBlob(file)
        .then((blob) => {
          this.props.updateFileUploadProgress(file, 5, fileIndex);

          this.props.createNewList(blob, file, fileIndex, this.state.parentListId)
            .then(() => {
              this.props.toast(`List uploaded ${file.name}`, { type: 'info' })
            })
            .catch(() => {

            })
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  changeParentId(id) {
    this.setState({
      parentListId: !id ? null : parseInt(id)
    })
  } 

  renderBody() {
    const className = this.state.filesAdded  ? 'col-md-6' :  'col-md-12'

    return (
      <div className='resource-panel p-4'>
        <NewListOptions   
          lists={this.state.lists} 
          parentListId={this.state.parentListId} 
          changeParentId={this.changeParentId}
        />

        <div className='row'>
          <div className={className}>

            <UploadNewListForm 
              uploadFiles={this.uploadFiles}
            />
          </div>

          {
            this.state.filesAdded ?
              this.renderUploadingProgress() :
              null 
          }
        </div>

        <p className='mt-3 mb-0 text-center'>
          Have a file larger than 100MB?
          <a href='/lists/upload_basic'> Click here </a> 
          for the basic uploader
        </p>

      </div>
    )
  }

  render() {
    return (
      <ResourceContainer>
        <ResourceHeader title='Lists' description='Upload lists' />

        <div className='lists-new mt-3'>
          { this.renderBody() }
        </div>

      </ResourceContainer>
    );
  }
}

export default withProvider(withToaster(connect(
  mapStateToProps, 
  mapDispatchToProps)(ListsNew)
));
