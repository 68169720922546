export function selectLatestPollVersion(versions) {
  if(!versions) return;
  let n = -1;
  let version = null;

  for(var i = 0; i < versions.length; i++) {
    const candidate = versions[i];

    if(candidate.version >= n) {
      n = candidate.version;
      version = candidate;
    }
  }

  return version;
}
