import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap'

import ParentListSelector from './parent-list-selector'

export default class NewListOptions extends React.Component {
  constructor(props) {
    super(props);

    this.renderVisible = this.renderVisible.bind(this)
    this.renderHidden = this.renderHidden.bind(this)

    this.toggleVisibility = this.toggleVisibility.bind(this)

    this.state = {
      isVisible: false
    }
  }

  toggleVisibility() {
    this.setState({
      isVisible: !this.state.isVisible
    })
  }

  renderHidden() {
    return (
      <div className='row'>
        <div className='col-md-3 offset-md-9'>
          <div className='float-right'>
            <button className='btn btn-light mb-4' onClick={this.toggleVisibility}>
              <i className="fas fa-cog"></i>
            </button>
          </div>
        </div>
      </div>
    )
  }

  renderVisible() {
    return (
      <div>
        <div className='row'>
          <div className='col-md-9'>
            <ParentListSelector
              lists={this.props.lists}
              changeParentId={this.props.changeParentId}
              parentListId={this.props.parentListId}
            />
          </div>

          <div className='col-md-3'>
            <div className='float-right'>
              <button className='btn btn-dark mb-4' onClick={this.toggleVisibility}>
                <i className="fas fa-cog"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return this.state.isVisible ? this.renderVisible() : 
      this.renderHidden()
  }
}
