import React from 'react';

export default class PollRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { poll } = this.props;

    return (
      <tr>
        <td>
          {poll.name}
        </td>
        <td>
          {poll.versions_count}
        </td>
        <td>
          {poll.created_at}
        </td>
        <td>
          <a href={`/polls/${poll.id}`} className='btn btn-light btn-sm'>
            View
          </a>
        </td>
      </tr>
    );
  }
}
