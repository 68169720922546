import axios from 'lib/axios-config'

export const fetchPollVersion = (pollId, versionId) => {
  const url = `/polls/${pollId}/versions/${versionId}`
  
  return axios.get(url)
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(errors.response.data)
    })
}

export const selectMappings = (pollVersion) => {
  return pollVersion.mappings;
} 

export const selectColumns = (pollVersion) => {
  let columns = [];
  const keys = Object.keys(pollVersion.columns);

  for(var i = 0; i < keys.length; i++) {
    const key = keys[i];
    const columnState = pollVersion.columns[key];
    if(columnState) {
      columns.push(key);
    }
  }

  return columns;
}

const appendSource = (data, source) => {
  if(!data.hasOwnProperty(source)) {
    data[source] = {}
  }

  return data;
}

const appendHeader = (data, source, header) => {
  if(!data[source].hasOwnProperty(header)) {
    data[source][header] = {}
  }

  return data
}

const appendData = (data, source, header, title, count) => {
  if(data[source][header].hasOwnProperty(title)) {
    data[source][header][title] += parseInt(count)
  } else {
    data[source][header][title] = parseInt(count)
  }

  return data;
}

export const selectDataSources = (pollVersion) => {
  const aggregates = pollVersion.poll_version_aggregates;

  let sources = new Set();
  let questions = {} 

  let data = {}
  data = appendSource(data, 'All')

  for(var i = 0; i < aggregates.length; i++) {
    const aggregate = aggregates[i];
    data = appendHeader(data, 'All', aggregate.name)

    if(aggregate.source) {
      questions[aggregate.name] = new Set();
      sources.add(aggregate.name)

      data = appendSource(data, aggregate.source)
      data = appendHeader(data, aggregate.source, aggregate.name)
    }

    for (const [title, count] of Object.entries(aggregate.counts)) {
      questions[aggregate.name].add(title);
      data = appendData(data, 'All', aggregate.name, title, count)

      if(aggregate.source) {
        data = appendData(data, aggregate.source, aggregate.name, title, count)
      }
    }
  }

  // Append missing option values with 0 counts
  const questionTitles = Object.keys(questions);
  const sourceTitles = Object.keys(data);
  for(var q = 0; q < questionTitles.length; q++) {
    const title = questionTitles[q];

    for (const [source, questionOptions] of Object.entries(data)) {
      if(!data[source].hasOwnProperty(title)) {
        data[source][title] = {}
      }

      for (var it = questions[title].values(), option= null; option=it.next().value; ) {
        if(!data[source][title].hasOwnProperty(option)) {
          data[source][title][option] = 0
        }
      }
    }
  } 

  return {
    data: data,
    sources: Array.from(sources),
  }
}

export const selectFirstQuestionTitle = (questions) => {
  if(!questions) return;

  const keys = Object.keys(questions);
  return keys.sort()[0] 
}

export const selectQuestion = (data, source, title) => {
  if(!data.hasOwnProperty(source) || !data[source].hasOwnProperty(title)) return;

  return data[source][title];
}