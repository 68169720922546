import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import axios from "lib/axios-config";
import { toasterCacheSet } from "lib/toaster-cache";
import _ from "lodash";
import Modal from "react-modal";

import "css/user-param-modal";

const UserParamModal = (props) => {
  const { visible, closeModal, onSubmit } = props;
  const [value, setValue] = useState("");

  return (
    <div>
      <Modal
        isOpen={visible}
        className="user-param-modal"
        overlayClassName="user-param-modal-overlay"
        onRequestClose={closeModal}
        contentLabel="User Param Modal"
      >
        <div className="modal-header pt-4 px-4">
          <div className="offset-10 col-md-2">
            <button
              className="btn btn-sm btn-muted btn-close"
              onClick={(e) => {
                e.preventDefault();
                closeModal();
              }}
            >
              <i className="fas fa-times ml-auto mr-auto d-block"></i>
            </button>
          </div>
        </div>
        <div className="d-flex" style={{ width: "480px", margin: "0px auto", padding: '0px 10px' }}>
          <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            User Param
          </span>
        </div>
        <input
          type="text"
          placeholder="Enter your value here"
          className="form-control user-param-input"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <div
          className="d-flex mt-4 px-4 pb-4"
          style={{ justifyContent: "flex-end" }}
        >
          <button
            className="btn btn-white btn-submit"
            onClick={(e) => {
              e.preventDefault();
              onSubmit(value);
            }}
          >
            Submit
          </button>
        </div>
      </Modal>
    </div>
  );
};

const WeightingDownloadReportBtn = (props) => {
  const { action } = props;
  const [dowloading, setDownloading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const downloadFile = (type, user = "") => {
    const uri = `/pipeline/actions/${action.id}/kbid/download_weighting`;
    setShowModal(false);
    axios
      .post(uri, { type, user }, { responseType: "blob" })
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));

        const a = document.createElement("a");
        a.href = url;
        const extension = type === "project_zip" ? ".zip" : ".pdf";
        a.download = `${action.id}_${type}${extension}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
        toasterCacheSet("Unknown error", "error");
      });
  };

  const onChange = (type) => {
    setDownloading(true);
    if (type === "project_zip") {
      setShowModal(true);
    } else {
      downloadFile(type);
    }
  };
  return (
    <div className="">
      {dowloading ? (
        <button
          type="button"
          className="btn btn-secondary ml-3"
          onClick={_.noop}
          disabled
        >
          Downloading
        </button>
      ) : (
        <Dropdown
          className="d-inline-block mr-2"
          size="md"
          variant="secondary"
          title="Download"
          onSelect={onChange}
        >
          <Dropdown.Toggle variant="secondary">
            {dowloading ? "Downloading" : "Download"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item key="raw_report" eventKey="raw_report">
              Download Raw Preview
            </Dropdown.Item>
            <Dropdown.Item key="weighted_report" eventKey="weighted_report">
              Download Weighted Preview
            </Dropdown.Item>
            <Dropdown.Item key="project_zip" eventKey="project_zip">
              Download ZIP
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
      <UserParamModal
        visible={showModal}
        closeModal={() => setShowModal(false)}
        onSubmit={(user) => {
          downloadFile("project_zip", user);
        }}
      />
    </div>
  );
};

export default WeightingDownloadReportBtn;
