import React from 'react';
import ActionColumnTags from './action-column-tags'
import TagCount from '../../../ui/tag-count'

const ActionTagSettings = (props) => {
  const { action } = props;
  const { options } = action;
  const { results } = action;

  return (
    <div className='row mt-3 text-break'>
      <div className='col-md-4'>
        <h4>Column</h4>
        <ActionColumnTags
          action={action}
          headerField='headers'
        />
      </div>
      <div className='col-md-4'>
        <h4>Tags</h4>
        <TagCount
          tags={results.tags}
        />
      </div>
    </div>
  )
}

export default ActionTagSettings;