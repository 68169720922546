import React from 'react';

import UIInputEditor from './ui-input-editor'

const NormalizationTableHeader = (props) => {
  const { name, isVisible } = props;

  const chevronClassName = isVisible ?
    'fas fa-chevron-down' : 'fas fa-chevron-up'

  let style = {}
  if(isVisible) {
    style['borderBottomLeftRadius'] = '0px' 
    style['borderBottomRightRadius'] = '0px' 
    style['borderBottom'] = '0px'
  } else {
    style['marginBottom'] = '1.5rem'
  }

  return (
      <div className="card" style={style}>
        <div className="card-header" style={{'borderBottom': '0px'}} >
          <div className='row'>
            <div className='col-md-9'>
              <div className='d-flex'>
                <button className='btn btn-sm btn-muted mr-2' onClick={(e) => {
                  e.preventDefault();
                  props.cloneTable(name)
                }}>
                  <i className='fas fa-clone'></i>
                </button>
                <UIInputEditor
                  name={name}
                  inputWidth="350px"
                  swapFields={props.setTableName}
                />
              </div>
            </div>
            <div className='col-md-3'>
              <div className='float-right'>
                <button className='btn btn-sm btn-muted mr-2' onClick={(e) => {
                  e.preventDefault();
                  props.toggleMappingVisible(name, !isVisible)
                }}>
                  <i className={chevronClassName}></i>
                </button>

                {
                  props.showDeleteState ?
                    <button className='btn btn-sm btn-danger' onClick={(e) => {
                      e.preventDefault();
                      props.removeNormalizationTableMapping(name)
                    }}>
                      <i className="fas fa-times"></i>
                    </button>
                  : null
                }

              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default NormalizationTableHeader;