import React from 'react';
import { Provider } from 'react-redux'
import configureStore, { StoreContext } from 'lib/configure-store'
import { history }  from 'lib/reducers'

const store = configureStore()

function withProvider(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <Provider store={store}>
          <StoreContext.Provider value={store}>
            <WrappedComponent {...this.props} />
          </StoreContext.Provider>
        </Provider>
      ) 
    }
  }
}

export default withProvider;