import React from 'react';
import ActionListDetail from './action-list-detail'

const ActionDestinationList = (props) => {
  const { action } = props;
  if(!action || !action.lists) return null;

  return action.lists.map((list, index) => {
    return (
      <ActionListDetail
        title='Destination List'
        key={`action-list-row-${index}`}
        list={list}
      />
    )
  })
}

export default ActionDestinationList;
