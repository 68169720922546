import React from 'react';
import ListSelector from '../../lists/list-selector'

export default class VersionListSelector extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { version, lists, list } = this.props;

    return (
      <div className='row mt-3'>
        <div className='col-md-8'>
          <label><strong>{ this.props.title }</strong></label>

          <div>
            <span className='d-inline-block mr-2'>
              <ListSelector
                lists={lists}
                selectListId={this.props.selectListId}
                currentListId={version.list_id}
              />
            </span>
            <span>
              { list ? list.name : 'No list selected' }
            </span>
          </div>
        </div>
      </div>
    );
  }
}
