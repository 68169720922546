import React from 'react';
import Modal from 'react-modal'
import axios from 'lib/axios-config'

import 'css/delete-modal'

export default class ResourceDeleteModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const{ resourceName, resource } = this.props;

    return (
      <div>
        <Modal
          isOpen={this.props.visible}
          className='delete-modal'
          overlayClassName='delete-modal-overlay'
          onRequestClose={this.props.closeModal}
          contentLabel='DeleteModal'
        >
          <div className='modal-header pt-4 px-4'>
              <div className='offset-10 col-md-2'>
                <button className='btn btn-sm btn-muted btn-close' onClick={(e) => {
                  e.preventDefault()
                  this.props.closeModal()
                }}>
                  <i className="fas fa-times ml-auto mr-auto d-block" ></i>
                </button>
              </div>
          </div>

          <div className='px-4 pb-4'>
            <div className='trash mb-3'>
              <i className="fas fa-trash-alt"></i>
            </div>

            <h3>Delete { resourceName }?</h3>

            { this.props.children }

            <button className='btn btn-white btn-delete' onClick={(e) => {
              e.preventDefault()
              this.props.handleDelete()
            }}>
              Yes, Delete This { resourceName } 
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}
