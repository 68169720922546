import React from 'react';
import { DropdownButton, Dropdown, FormControl } from 'react-bootstrap'

import filter from 'lodash/filter'
import { match } from 'lib/string'

export default class ListSelector extends React.Component {
  constructor(props) {
    super(props);

    this.selectLists = this.selectLists.bind(this)
    this.handleToggle = this.handleToggle.bind(this)

    this.state = {
      q: ''
    }
  }

  handleToggle(e) {
    if(!e) {
      this.setState({q: ''})
    }
  }

  selectLists() {
    const { lists } = this.props;
    const { q } = this.state;

    return filter(lists, (list) => {
      return match(list.name, q)
    })
  }

  render() {
    const { currentListId } = this.props;
    
    const lists = this.selectLists();

    return (
      <div>
        <DropdownButton 
          id="dropdown-basic-button"
          className='d-inline-block mr-2'
          title={this.props.title || 'List'} 
          onToggle={this.handleToggle}
          size='md'
          variant='light'
          onSelect={this.props.selectListId}
        >
          <div className='px-3'>
            <FormControl
              autoFocus
              className="my-2"
              placeholder="Type to filter..."
              onChange={(e) => {
                this.setState({q: e.target.value})
              }}
              value={this.state.q}
            />
          </div>

          <div style={{'overflowY': 'scroll', 'maxHeight': '250px'}}>
            {
              lists.map((list, index) => {
                const className = currentListId == list.id ?
                  'active' : ''
                return (
                  <Dropdown.Item 
                    key={`list-option-${index}`} 
                    eventKey={list.id}
                    className={className}
                  >
                    {list.name}
                  </Dropdown.Item>
                )
              })
            }
          </div>
        </DropdownButton>
      </div>
    );
  }
}
