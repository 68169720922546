import React from 'react';
import ActionColumnTags from './action-column-tags'

const ActionColumnSettings = (props) => {
  const { action } = props;
  const { options } = action;
  const { results } = action;

  const mode = options['processing_mode'];
  let matched = 0;

  return (
    <div className='row mt-3 text-break'>
      <div className='col-md-4'>
        <h4>Columns to Process</h4>
        <ActionColumnTags
          action={action}
          headerField='headers'
        />
      </div>
      <div className='col-md-4'>
        <h4>Processing Mode</h4>
        { mode }
      </div>
    </div>
  );
}

export default ActionColumnSettings;
