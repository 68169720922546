import React from 'react';

const ResourceHeader = (props) => {
  let colWidthClassName = props.col ?
    `col-md-${props.col}` : 'col-md-12'

  return (
    <div className='resource-header'>
      <div className='row'>
        <div className={colWidthClassName}>
          <h1 className='title'>{ props.title }</h1>
          <h2 className='description'>{ props.description }</h2>
        </div>

        <div className='col-md-6'>
          { props.children }
        </div>
      </div> 
    </div>
  )
}

export default ResourceHeader;