import React from 'react';

export default class PollHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <thead>
        <tr>
          <th>Name</th>
          <th>Versions</th>
          <th>Created At</th>
          <th></th>
        </tr>
      </thead>
    );
  }
}
