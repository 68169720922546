import React from 'react';
import NormalizationTablesEditor from './editor'
import uuid from 'uuid';

import { toasterCacheSet } from 'lib/toaster-cache'
import withToaster from '../../common/toaster-wrapper'
import axios from 'lib/axios-config'

class EditNormalizationTable extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this)
    this.processOpenAI = this.processOpenAI.bind(this)

    this.state = {
      normalization_table: {
        id: null,
        name: '',
        list_id: null,
        mappings: {}
      }
    }
  }

  componentDidMount() {
    this.setState({
      normalization_table: this.props.normalization_table
    })
  }

  submit(opts) {
    let params = opts;

    axios.put(`/pipeline/normalization_tables/${params.id}`, params)
      .then((response) => {
        this.props.toast(`Normalization table updated`)
        window.location.reload()
      })
      .catch((error) => {
        const messages = error.response.data.messages;
        for(var i = 0; i < messages.length; i++){
          this.props.toast(messages[i], { type: 'error' })
        }
      })
  }

  processOpenAI(tableId) {
    axios.put(`/pipeline/normalization_tables/${tableId}/process_open_ai`)
      .then((response) => {
        this.props.toast('Processing with Open AI')
        window.location.reload()
      })
  }

  render() {
    const { normalization_table } = this.state;

    if(!normalization_table || !normalization_table.id) {
      return null;
    }

    return (
      <>
        <NormalizationTablesEditor
          edit={true}
          processOpenAI={this.processOpenAI}
          saveNormalizationTable={this.submit}
          normalization_table={normalization_table}
        />
      </>
    );
  }
}

export default withToaster(EditNormalizationTable);