import React, { Component } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap'

export class ActionTypeSelector extends Component {
  render() {
    const { currentActionType, actionTypes } = this.props;

    return ( 
      <div>
        <DropdownButton 
          id="dropdown-basic-button"
          className='d-inline-block mr-2'
          title={currentActionType && currentActionType.name ? currentActionType.name : 'Type'} 
          size='md'
          variant='light'
          onSelect={(id) => this.props.selectActionTypeId(id, actionTypes)}
        >
          <div style={{'overflowY': 'scroll', 'maxHeight': '200px'}}> 
            {
              actionTypes.map((actionType, index) => {
                const className = currentActionType && currentActionType.id == actionType.id ?
                  'active' : ''
                return (
                  <Dropdown.Item 
                    key={`list-option-${index}`} 
                    eventKey={actionType.id}
                    className={className}
                  >
                    {actionType.name}
                  </Dropdown.Item>
                )
              })
            }
          </div>
        </DropdownButton>
      </div>
    );
  }
}

export default ActionTypeSelector;
