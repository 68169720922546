import React from 'react';

export default class ColumnNameMapper extends React.Component {
  constructor(props) {
    super(props);

    this.renderMappingTables = this.renderMappingTables.bind(this)
  }

  renderMappingTables() {
    const { version } = this.props;

    if(!version || !version.mappings || 
      Object.keys(version.mappings).length === 0) {
      return null;
    }

    const mappings = version.mappings;

    return (
      <table className='table'>
        <thead>
          <tr>
            <th>Original</th>
            <th>Rename</th>
          </tr>
        </thead>
        <tbody>
          {
            Object.keys(mappings).map((key, index) => {
              return (
                <tr key={`occurences-${name}-${index}`}>
                  <td width="50%">
                    { key }
                  </td>

                  <td width="50%">
                    <textarea 
                      type="text"
                      value={mappings[key]}
                      className='form-control'
                      onChange={(e) => {
                        e.preventDefault();
                        this.props.setMapping(key, e.target.value)
                      }}
                    />
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    ) 
  }

  render() {
    return (
      <div className='row mt-5'>
        <div className='col-md-12'>
          <div className='d-flex mb-2'>
            <label><strong>Map Nicknames for Selected Columns</strong></label>
            <div className='ml-auto'>
              <button className='btn btn-dark btn-sm' onClick={this.props.resetMappings}>
                Reset
              </button>
            </div>
          </div>
          { this.renderMappingTables() }
        </div>
      </div>
    );
  }
}
