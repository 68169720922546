import React from 'react'
import SidebarLink from './sidebar-link'

import 'css/sidebar'

export class AdminSidebar extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
  	return (
			<div className='card'>
				<ul className='vertical-nav'>
					<SidebarLink to="/polls" title="Polls" icon="poll"/>
					<SidebarLink to="/reports" title="Reports" icon="file-pdf" />
					<SidebarLink to="/models" title="Models" icon="project-diagram" />
				</ul>
			</div>
  	)
  }
}

export default AdminSidebar;
