import React from "react";

import NormalizationTableRow from "./normalization-table-row";
import NewFieldSplitButton from "./new-field-split-button";

export default class NormalizationTableBody extends React.Component {
  constructor(props) {
    super(props);

    this.addFieldMapping = this.addFieldMapping.bind(this);
    this.addEmptyFieldMapping = this.addEmptyFieldMapping.bind(this);
  }

  addFieldMapping() {
    let { latestNewFieldMappingIndex } = this.props;
    let newFieldName = "Field ";

    if (!latestNewFieldMappingIndex) {
      latestNewFieldMappingIndex = 0;
    } else {
      latestNewFieldMappingIndex = parseInt(latestNewFieldMappingIndex);
    }

    latestNewFieldMappingIndex += 1;
    newFieldName += `${latestNewFieldMappingIndex}`;

    this.props.addFieldMapping(
      this.props.name,
      newFieldName,
      latestNewFieldMappingIndex
    );
  }

  addEmptyFieldMapping() {
    let { latestNewFieldMappingIndex } = this.props;
    this.props.addFieldMapping(
      this.props.name,
      "BLANK",
      latestNewFieldMappingIndex
    );
  }

  render() {
    const { name, mappings, fieldLabels = [], showDeleteState } = this.props;

    return (
      <table className="table">
        <thead>
          <tr>
            {fieldLabels.map((label) => (
              <th key={label}>{label}</th>
            ))}
            {showDeleteState ? <th /> : null}
          </tr>
        </thead>
        <tbody>
          {Object.keys(mappings).map((mapping, index) => {
            return (
              <NormalizationTableRow
                key={`mappings-${name}-${mapping}-${index}`}
                mappings={mappings}
                mapping={mapping}
                name={name}
                showDeleteState={this.props.showDeleteState}
                setMappingField={this.props.setMappingField}
                renameMappingField={this.props.renameMappingField}
                removeMappingField={this.props.removeMappingField}
              />
            );
          })}

          <tr>
            <td colSpan={2}>
              <NewFieldSplitButton
                addFieldMapping={this.addFieldMapping}
                addEmptyFieldMapping={this.addEmptyFieldMapping}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
