import React from 'react';
import NormalizationTablesEditor from './editor'
import uuid from 'uuid';

import { toasterCacheSet } from 'lib/toaster-cache'
import withToaster from '../../common/toaster-wrapper'
import axios from 'lib/axios-config'

class NewNormalizationTable extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this)

    this.state = {
      normalization_table: {
        id: null,
        name: '',
        list_id: null,
        mappings: {}
      }
    }
  }

  submit(opts) {
    let params = opts;
    delete params.id;

    axios.post('/pipeline/normalization_tables', params)
      .then((response) => {
        toasterCacheSet(`Normalization table created`, 'info')
        window.location = '/pipeline/normalization_tables'
      })
      .catch((error) => {
        const messages = error.response.data.messages;
        for(var i = 0; i < messages.length; i++){
          this.props.toast(messages[i], { type: 'error' })
        }
      })
  }

  render() {
    return (
      <>
        <NormalizationTablesEditor
          saveNormalizationTable={this.submit}
          normalization_table={this.state.normalization_table}
        />
      </>
    );
  }
}

export default withToaster(NewNormalizationTable);