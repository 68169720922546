import React from 'react';

import 'css/resource-panel'


const ResourceContainer = (props) => {
  return (
    <div className='resource-container container'>
      { props.children }
    </div>
  );
}

export default ResourceContainer;