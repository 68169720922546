import axios from "lib/axios-config";
import { titleCase } from "lib/string";
import { toasterCacheSet } from "lib/toaster-cache";
import { without } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import flatten from "lodash/flatten";
import get from "lodash/get";
import map from "lodash/map";
import set from "lodash/set";
import uniq from "lodash/uniq";
import React, { Component } from "react";
import TextareaAutosize from "react-textarea-autosize";
import ActionRun from "./kbid-action-run";
import SectionForm from "./kbid-common-section-form";
import KbidContentLoader from "./kbid-content-loader";
import SelectWithFilter from "../../../../common/select-with-filter";
import WeightingDownloadReportBtn from "./kbid-weighting-download-report-btn";

const cellStyle = {
  maxWidth: "420px",
  minWidth: "420px",
  width: "420px",
  wordWrap: "break-word",
  padding: "16px 10px",
};

const inputStyle = {
  padding: "4px 12px",
  fontSize: "1em",
  resize: "none",
};

const clickable = {
  cursor: "pointer",
};

const headerRowStyle = {
  border: "0px",
};

const lightBorder = {
  borderColor: "lightgray",
};

function PreviewBox({ values }) {
  return (
    <div className="col border rounded" style={lightBorder}>
      {map(values, (value, idx) => (
        <div key={`${value}-${idx}`} className="mt-2 mb-3">
          {value}
        </div>
      ))}
    </div>
  );
}

function PreviewRow(props) {
  const { record } = props;

  return (
    <>
      <tr style={headerRowStyle}>
        <td style={cellStyle} className="font-weight-bold">
          New Column Name
        </td>
        <td style={cellStyle} className="font-weight-bold">
          Old Columns
        </td>
      </tr>
      <tr>
        <td style={cellStyle}>{record.new_column_name}</td>
        <td style={cellStyle}>
          <PreviewBox values={record.old_columns} />
        </td>
      </tr>
    </>
  );
}

function EditingRow(props) {
  const { record, onInputChange, onOptionsChange, disabled, statColumns } =
    props;
  const oldColumns = record.old_columns || [];

  const handleOptionChange = (value, idx) => {
    const clone = [...oldColumns];
    clone[idx] = value;
    onOptionsChange(clone);
  };

  const handleOptionAdd = () => {
    const clone = [...oldColumns, ""];
    onOptionsChange(clone);
  };

  return (
    <tr>
      <td style={cellStyle}>
        <h6>New Column Name</h6>
        <TextareaAutosize
          value={record.new_column_name}
          className="form-control"
          style={inputStyle}
          onChange={(e) => onInputChange(e.target.value)}
          disabled={disabled}
        />
      </td>
      <td style={{ paddingLeft: "120px" }}>
        <h6>Old Column</h6>
        <div className="col p-0">
          {oldColumns.map((oldCol, idx) => (
            <div
              className="row align-items-midde mb-3"
              key={`${oldCol}-${idx}`}
            >
              <SelectWithFilter
                options={statColumns}
                title={oldCol || "Select a Value"}
                onSelect={(val) => handleOptionChange(val, idx)}
                excludeSet={new Set(without(oldColumns, ""))}
              />
              <div className="d-flex flex-col align-items-center justify-content-center ml-3">
                <i
                  className="fas fa-minus-circle"
                  onClick={() => {
                    const clone = [...oldColumns];
                    clone.splice(idx, 1);
                    onOptionsChange(clone);
                  }}
                  style={clickable}
                />
              </div>
            </div>
          ))}
          <button
            className="btn btn-secondary mt-3"
            onClick={(e) => {
              e.preventDefault();
              handleOptionAdd();
            }}
          >
            Add Column
          </button>
        </div>
      </td>
    </tr>
  );
}

function HeaderRow(props) {
  return null;
}

function NoDataView(props) {
  return (
    <div
      className="col justify-content-center"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "40px",
      }}
    >
      <h4 className="text-center mt-4">No Columns</h4>
      <button
        className="btn btn-secondary mt-3 ml-3"
        onClick={(e) => {
          e.preventDefault();
          props.onAddRow();
        }}
      >
        Add Row
      </button>
    </div>
  );
}

const INITIAL_STATE = {
  lastId: -1,
  overallVisibilityState: "contract",
  sectionVisibility: {},
  newColumns: [],
  statColumns: [],
  isLoading: true,
  isSubmitting: false,
  interactionMode: "previewing",
};

class KBIDWeightingNewColumns extends Component {
  constructor(props) {
    super(props);
    this.goToNext = this.goToNext.bind(this);
    this.fetchInitialData = this.fetchInitialData.bind(this);
    this.saveAction = this.saveAction.bind(this);
    this.handleRowAdd = this.handleRowAdd.bind(this);
    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.rebuildStep = this.rebuildStep.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.toggleSectionVisibility = this.toggleSectionVisibility.bind(this);
    this.toggleOverallVisibility = this.toggleOverallVisibility.bind(this);

    const kbids = get(
      props.action,
      ["options", "step-kbids", "form", "kbids"],
      [""]
    );

    this.state = { ...INITIAL_STATE, kbids };
  }

  componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData() {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/weighting`;
    const newColumnsRequest = axios.get(uri, {
      params: { type: "new_columns" },
    });
    const statsColumnsRequest = axios.get(uri, {
      params: { type: "new_columns_stats" },
    });

    return Promise.all([newColumnsRequest, statsColumnsRequest]).then(
      ([newColumnsRes, statsRes]) => {
        const newColumns = map(
          newColumnsRes.data.new_columns || [],
          (col, idx) => ({ ...col, deleted: false, id: idx, updated: false })
        );
        const statColumns = uniq(
          flatten(
            map(statsRes.data.new_columns, (rec) =>
              map(rec.old_columns, (name) => ({ name, id: name }))
            )
          )
        );
        let sectionVisibility = {};
        newColumns.forEach((_key, idx) => {
          sectionVisibility[idx] =
            this.state.overallVisibilityState === "expand";
        });

        this.setState((prev) => ({
          ...prev,
          ...INITIAL_STATE,
          lastId: newColumns.length - 1,
          newColumns,
          statColumns,
          sectionVisibility,
          isLoading: false,
        }));
      }
    );
  }

  toggleSectionVisibility(id) {
    const updated = set(
      cloneDeep(this.state.sectionVisibility),
      id,
      !this.state.sectionVisibility[id]
    );
    this.setState({ sectionVisibility: updated });
  }

  toggleOverallVisibility() {
    this.setState((prevState) => {
      const newVisibilityState =
        prevState.overallVisibilityState === "expand" ? "contract" : "expand";
      let sectionVisibility = {};
      prevState.newColumns.forEach((_key, idx) => {
        sectionVisibility[idx] = newVisibilityState === "expand";
      });
      return {
        ...prevState,
        overallVisibilityState: newVisibilityState,
        sectionVisibility,
      };
    });
  }

  handleRowUpdate(idx, path, value) {
    const updated = set(
      cloneDeep(this.state.newColumns),
      `${idx}.${path}`,
      value
    );
    set(updated, `${idx}.updated`, true);
    this.setState({ newColumns: updated });
  }

  saveAction() {
    const { action, id } = this.props;
    action.status = "draft";
    action.options = {
      ...action.options,
      [id]: "done",
    };

    // :update action
    return axios.put(`/pipeline/actions/${action.id}`, {
      action_params: action,
    });
  }

  handleRowAdd() {
    this.setState((prev) => ({
      ...prev,
      newColumns: [
        ...prev.newColumns,
        {
          old_columns: [],
          new_column_name: "",
          deleted: false,
          updated: true,
          id: prev.lastId + 1,
          new: true,
        },
      ],
      lastId: prev.lastId + 1,
      interactionMode: "editing",
      sectionVisibility: { ...prev.sectionVisibility, [prev.lastId + 1]: true },
    }));
  }

  submitForm(submitData) {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/weighting`;

    return axios
      .post(uri, { type: "new_columns", data: submitData })
      .then((res) => res.data);
  }

  goToNext() {
    const { newColumns: records } = this.state;
    const { goToStep, stepIdx, action, id, mode } = this.props;
    const isUpdated = records.some((q) => q.updated);

    // If re-visiting the step, dont submit data to api. Just go to next step.
    if ((!isUpdated && action.options[id] != null) || mode === "read-only") {
      goToStep(stepIdx + 1);
      return;
    }

    if (records.length < 1) return;

    const submitData = {
      new_columns: filter(records, (rec) => !rec.deleted).map((q) => ({
        new_column_name: q.new_column_name,
        old_columns: without(q.old_columns, ""),
      })),
    };

    this.setState({ isSubmitting: true }, () => {
      // If no changes were made on initial visit too,
      // dont submit data to api. Just mark as done and go to next step.
      const submitToApi =
        action.options[id] == null && !isUpdated
          ? Promise.resolve()
          : this.submitForm(submitData);

      submitToApi
        .then(() => this.saveAction())
        .then(() => goToStep(stepIdx + 1))
        .catch(() => {
          this.setState({ isSubmitting: false });
          toasterCacheSet("Unknown error", "error");
        });
    });
  }

  rebuildStep(type = "new_columns") {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/rebuild_weighting`;

    return this.setState({ isLoading: true }, () => {
      axios
        .post(uri, { type })
        .then(() => this.props.rebuildStep())
        .catch(() => {
          this.setState({ isSubmitting: false });
          toasterCacheSet("Unknown error", "error");
        });
    });
  }

  renderRow({ record, index, id }) {
    const Row =
      this.state.interactionMode !== "editing" ||
      this.props.mode === "read-only"
        ? PreviewRow
        : EditingRow;

    return (
      <Row
        record={record}
        statColumns={this.state.statColumns}
        onInputChange={(value) =>
          this.handleRowUpdate(record.id, "new_column_name", value)
        }
        onOptionsChange={(value) =>
          this.handleRowUpdate(record.id, "old_columns", value)
        }
        disabled={this.props.mode === "read-only"}
      />
    );
  }

  render() {
    const { action, stepIdx, goToStep, disabled, mode } = this.props;
    const {
      isSubmitting,
      isLoading,
      overallVisibilityState,
      sectionVisibility,
      newColumns,
    } = this.state;

    const sections = newColumns.filter((rec) => !rec.deleted);

    return (
      <div>
        <h4 className="text-center mt-4">Step 2 - New Columns</h4>
        <div className="row justify-content-end">
          <button
            className="btn btn-sm btn-light mb-2 mr-2"
            onClick={(e) => {
              e.preventDefault();
              this.toggleOverallVisibility();
            }}
          >
            {overallVisibilityState === "expand"
              ? "Collapse All"
              : "Expand All"}
          </button>
          {mode !== "read-only" && (
            <button
              className="btn btn-sm btn-light mb-2 mr-2"
              onClick={(e) => {
                e.preventDefault();
                const nextMode =
                  this.state.interactionMode === "editing"
                    ? "previewing"
                    : "editing";

                this.setState({
                  interactionMode: nextMode,
                });
              }}
            >
              {titleCase(this.state.interactionMode)}
            </button>
          )}
        </div>
        <div>
          {isLoading ? (
            <KbidContentLoader />
          ) : sections.length === 0 ? (
            <NoDataView onAddRow={this.handleRowAdd} />
          ) : (
            map(sections, (record, idx) => (
              <SectionForm
                key={`${record.id}-${idx}`}
                rowKey={record.id}
                id={
                  record.new_column_name === "" && record.new
                    ? `New Row ${idx}`
                    : record.new_column_name
                }
                records={[record]}
                renderHeaderRow={HeaderRow}
                Row={this.renderRow}
                showVerified={false}
                isVisible={sectionVisibility[record.id]}
                toggleVisibility={() => this.toggleSectionVisibility(record.id)}
                deleteSection={
                  this.state.interactionMode === "editing"
                    ? () => this.handleRowUpdate(record.id, "deleted", true)
                    : undefined
                }
              />
            ))
          )}
        </div>
        {this.state.interactionMode === "editing" && (
          <div className="row justify-content-start">
            <button
              className="btn btn-secondary mt-3 ml-3"
              onClick={(e) => {
                e.preventDefault();
                this.handleRowAdd();
              }}
            >
              Add New Question
            </button>
          </div>
        )}
        <ActionRun
          action={action}
          displayDeleteModal={this.props.displayDeleteModal}
          goToNext={this.goToNext}
          goToPrevious={() => goToStep(stepIdx - 1)}
          isSubmitting={isSubmitting}
          rebuildStep={this.rebuildStep}
          hasRebuildDropdown
          stepDisabled={disabled}
          renderCustomAction={() => (
            <WeightingDownloadReportBtn action={action} />
          )}
        />
      </div>
    );
  }
}

export default KBIDWeightingNewColumns;
