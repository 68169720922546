import React from 'react';
import { DropdownButton, Dropdown, FormControl } from 'react-bootstrap'

export default class ActionMatchValue extends React.Component {
  constructor(props) {
    super(props);

    this.handleCheckChange = this.handleCheckChange.bind(this)
    this.renderDropdown = this.renderDropdown.bind(this)
    this.selectFilterOperator = this.selectFilterOperator.bind(this)
  }

  componentDidMount() {
    this.props.setActionOptionValues({'ignore_case': true, 'operator': 'contains'})
  }

  handleCheckChange(e) {
    e.preventDefault();
    const { action } = this.props;
    const options = action.options;
    console.log(options['ignore_case']);

    this.props.setActionOptionValue('ignore_case', !options['ignore_case'])
  }

  selectFilterOperator(value) {
    this.props.setActionOptionValue('operator', value)
  }

  renderDropdown() {
    const { action } = this.props;
    const options = action.options;

    return (
      <DropdownButton 
        id="dropdown-basic-button"
        className='mr-2 d-inline-block'
        title={options.operator ? options.operator : 'contians'} 
        size='md'
        variant='light'
        onSelect={this.selectFilterOperator}
      >
        <Dropdown.Item 
          eventKey={'contains'}
        >
          contains
        </Dropdown.Item>
        <Dropdown.Item 
          eventKey={'excludes'}
        >
          excludes
        </Dropdown.Item>
      </DropdownButton>
    )
  }

  render() {
    const { action } = this.props;
    const options = action.options;

    return (
      <div className='row mt-3'>
        <div className='col-md-12'>
          <label><strong>Match</strong></label>
          <br/>
          <span className='mr-2'>Value</span>

          { this.renderDropdown() }

          <input
            type='text'
            className='form-control d-inline-block w-50'
            value={options.filter_value || ''}
            onChange={(e) => {
              e.preventDefault();
              const value = e.target.value;
              this.props.setActionOptionValue('filter_value', value)
            }}
          />

          <div onClick={this.handleCheckChange} className='mt-2'>
            <input 
              checked={options['ignore_case']} 
              className='mr-1' 
              type="checkbox"
            />
            <label>Case Insensitive</label>
          </div>

        </div>
      </div>
    );
  }
}
