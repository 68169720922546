import axios from "lib/axios-config";
import { titleCase } from "lib/string";
import { toasterCacheSet } from "lib/toaster-cache";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import map from "lodash/map";
import set from "lodash/set";
import React, { Component, useState } from "react";
import ActionRun from "./kbid-action-run";
import SectionForm from "./kbid-common-section-form";
import KbidContentLoader from "./kbid-content-loader";
import TextareaAutosize from "react-textarea-autosize";

const cellStyle = {
  maxWidth: "50%",
  minWidth: "50%",
  width: "50%",
  wordWrap: "break-word",
  padding: "16px 10px",
};

const clickable = {
  cursor: "pointer",
};

const inputStyle = {
  padding: "4px 12px",
  fontSize: "1em",
  resize: "none",
};

const headerRowStyle = {
  border: "0px",
};

const lightBorder = {
  borderColor: "lightgray",
};

function PreviewAnswers({ answers }) {
  return (
    <div className="col border rounded" style={lightBorder}>
      {map(answers, (answer, idx) => (
        <div key={`${answer}-${idx}`} className="mt-2 mb-3">
          {answer}
        </div>
      ))}
    </div>
  );
}

function PreviewRow(props) {
  const { record } = props;

  return (
    <>
      <tr style={headerRowStyle}>
        <td style={cellStyle} className="font-weight-bold">
          Original Question Header
        </td>
        <td style={cellStyle} className="font-weight-bold">
          Answer Options
        </td>
      </tr>
      <tr>
        <td style={cellStyle}>{record.question_text}</td>
        <td style={cellStyle}>
          <PreviewAnswers answers={record.normalized_answers} />
        </td>
      </tr>
    </>
  );
}

function EditingRow(props) {
  const { record, onChange } = props;
  const answersCount = record.normalized_answers?.length;

  return (
    <tr>
      <td style={cellStyle}>
        <div className="col mb-2">
          <h6>Normalized Question Header</h6>
          <TextareaAutosize
            className="form-control"
            style={inputStyle}
            rows={1}
            maxRows={1}
            value={record.normalized_column_name}
            onChange={(e) => {
              onChange("normalized_column_name", e.target.value.replace(/(\r\n|\n|\r)/gm, ""));
            }}
          />
        </div>
        <div className="col mb-2">
          <h6>Original Question Header</h6>
          <TextareaAutosize
            className="form-control"
            style={inputStyle}
            rows={1}
            value={record.question_text}
            onChange={(e) => onChange("question_text", e.target.value)}
          />
        </div>
      </td>
      <td style={cellStyle}>
        <div className="col">
          <h6>Answer Options</h6>
          {record.normalized_answers.map((answer, idx) => (
            <div
              className="row align-items-midde mb-3"
              key={`${answersCount}-${idx}`}
            >
              <div className="col-9">
                <TextareaAutosize
                  className="form-control"
                  style={inputStyle}
                  rows={1}
                  value={answer}
                  onChange={(e) => {
                    const answers = [...record.normalized_answers];
                    answers[idx] = e.target.value;
                    onChange("normalized_answers", answers);
                  }}
                />
              </div>
              <div className="col-3 align-items-middle justify-content-center">
                <i
                  className="fas fa-minus-circle"
                  onClick={() => {
                    const answers = [...record.normalized_answers];
                    answers.splice(idx, 1);
                    onChange("normalized_answers", answers);
                  }}
                  style={clickable}
                />
              </div>
            </div>
          ))}
          <button
            type="button"
            className="btn btn-secondary mt-3"
            onClick={() => {
              const answers = [...record.normalized_answers, ""];
              onChange("normalized_answers", answers);
            }}
          >
            Add Answer Option
          </button>
        </div>
      </td>
    </tr>
  );
}

function HeaderRow() {
  return null;
}

class KBIDReviewQuestions extends Component {
  constructor(props) {
    super(props);
    this.runAction = this.runAction.bind(this);
    this.saveAction = this.saveAction.bind(this);
    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.rebuildStep = this.rebuildStep.bind(this);
    this.addNewQuestion = this.addNewQuestion.bind(this);
    this.toggleSectionVisibility = this.toggleSectionVisibility.bind(this);
    this.toggleOverallVisibility = this.toggleOverallVisibility.bind(this);

    const kbids = get(
      props.action,
      ["options", "step-kbids", "form", "kbids"],
      [""]
    );

    this.state = {
      kbids,
      questions: [],
      lastIndex: -1,
      overallVisibilityState: "contract",
      sectionVisibility: {},
      interactionMode: "previewing",
      isLoading: true,
      isSubmitting: false,
    };
  }

  componentDidMount() {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/question_keys`;

    return axios.get(uri).then((res) => {
      const questions = (res.data.questions || []).map((q, qIdx) => ({
        ...q,
        updated: false,
        questionId: qIdx,
      }));

      let sectionVisibility = {};
      questions.forEach((question) => {
        sectionVisibility[question.questionId] =
          this.state.overallVisibilityState === "expand";
      });

      this.setState({
        questions,
        sectionVisibility,
        lastIndex: questions.length - 1,
        isLoading: false,
      });
    });
  }

  toggleSectionVisibility(id) {
    const updated = set(
      cloneDeep(this.state.sectionVisibility),
      id,
      !this.state.sectionVisibility[id]
    );
    this.setState({ sectionVisibility: updated });
  }

  toggleOverallVisibility() {
    this.setState((prevState) => {
      const newVisibilityState =
        prevState.overallVisibilityState === "expand" ? "contract" : "expand";
      let sectionVisibility = {};
      prevState.questions.forEach((question) => {
        sectionVisibility[question.questionId] =
          newVisibilityState === "expand";
      });
      return {
        ...prevState,
        overallVisibilityState: newVisibilityState,
        sectionVisibility,
      };
    });
  }

  addNewQuestion() {
    this.setState((prevState) => {
      const updatedIndex = prevState.lastIndex + 1;
      const newQuestion = {
        normalized_column_name: `Question ${updatedIndex}`,
        question_text: "",
        normalized_answers: [""],
        updated: true,
        questionId: updatedIndex,
      };
      return {
        ...prevState,
        questions: [...prevState.questions, newQuestion],
        lastIndex: updatedIndex,
      };
    });
  }

  handleRowUpdate(idx, path, value) {
    const updated = set(
      cloneDeep(this.state.questions),
      `${idx}.${path}`,
      value
    );
    set(updated, `${idx}.updated`, true);
    this.setState({ questions: updated });
  }

  saveAction() {
    const { action, id } = this.props;
    action.status = "draft";
    action.options = {
      ...action.options,
      [id]: "submitted",
    };

    // :update action
    return axios.put(`/pipeline/actions/${action.id}`, {
      action_params: action,
    });
  }

  submitForm(submitData) {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/question_keys`;

    const data = {
      questions: submitData,
    };

    return axios.post(uri, data).then((res) => res.data);
  }

  runAction() {
    const { questions: records } = this.state;
    const { action, id, mode } = this.props;
    const isUpdated = records.some((q) => q.updated);

    // If re-visiting the step, dont submit data to api. Just go to next step.
    if ((!isUpdated && action.options[id] != null) || mode === "read-only") {
      window.location = "/pipeline/actions";
      return;
    }

    if (records.length < 1) return;

    const submitData = map(records, (question) => {
      return {
        normalized_column_name: question.normalized_column_name,
        question_text: question.question_text,
        normalized_answers: question.normalized_answers,
      };
    });
    this.setState({ isSubmitting: true }, () => {
      // If no changes were made on initial visit too,
      // dont submit data to api. Just mark as done and go to next step.
      const submitToApi =
        action.options[id] == null && !isUpdated
          ? Promise.resolve()
          : this.submitForm(submitData);

      submitToApi
        .then(() => this.saveAction())
        .then(() => {
          toasterCacheSet("Action saved successfully", "success");
          window.location = "/pipeline/actions";
        })
        .catch(() => {
          this.setState({ isSubmitting: false });
          toasterCacheSet("Unknown error", "error");
        });
    });
  }

  rebuildStep() {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/rebuild_questions`;

    return this.setState({ isLoading: true }, () => {
      axios
        .post(uri)
        .then(() => this.props.rebuildStep())
        .catch(() => {
          this.setState({ isSubmitting: false });
          toasterCacheSet("Unknown error", "error");
        });
    });
  }

  renderRow({ record }) {
    const Row =
      this.state.interactionMode !== "editing" ||
      this.props.mode === "read-only"
        ? PreviewRow
        : EditingRow;
    return (
      <Row
        record={record}
        onChange={(key, value) =>
          this.handleRowUpdate(record.questionId, key, value)
        }
      />
    );
  }

  render() {
    const { action, stepIdx, goToStep, disabled, mode } = this.props;
    const {
      questions,
      isSubmitting,
      isLoading,
      overallVisibilityState,
      sectionVisibility,
    } = this.state;

    return (
      <div>
        <h4 className="text-center mt-4">Step 5 - Review Questions</h4>
        <div className="row justify-content-end">
          <button
            className="btn btn-sm btn-light mb-2 mr-2"
            onClick={(e) => {
              e.preventDefault();
              this.toggleOverallVisibility();
            }}
          >
            {overallVisibilityState === "expand"
              ? "Collapse All"
              : "Expand All"}
          </button>
          {mode !== "read-only" && (
            <button
              className="btn btn-sm btn-light mb-2 mr-2"
              onClick={(e) => {
                e.preventDefault();
                const nextMode =
                  this.state.interactionMode === "editing"
                    ? "previewing"
                    : "editing";

                this.setState({
                  interactionMode: nextMode,
                });
              }}
            >
              {titleCase(this.state.interactionMode)}
            </button>
          )}
        </div>
        <div className="overflow-auto">
          {isLoading ? (
            <KbidContentLoader />
          ) : (
            map(questions, (question, idx) => (
              <SectionForm
                key={`${question.questionId}-${idx}`}
                rowKey={question.questionId}
                id={question.normalized_column_name}
                records={[question]}
                renderHeaderRow={HeaderRow}
                Row={this.renderRow}
                showVerified={false}
                isVisible={sectionVisibility[question.questionId]}
                toggleVisibility={() =>
                  this.toggleSectionVisibility(question.questionId)
                }
              />
            ))
          )}
        </div>
        {this.state.interactionMode === "editing" && (
          <div className="row justify-content-start">
            <button
              className="btn btn-secondary mt-3 ml-3"
              onClick={(e) => {
                e.preventDefault();
                this.addNewQuestion();
              }}
            >
              Add New Question
            </button>
          </div>
        )}
        <ActionRun
          action={action}
          displayDeleteModal={this.props.displayDeleteModal}
          runAction={this.runAction}
          goToPrevious={() => goToStep(stepIdx - 1)}
          isSubmitting={isSubmitting}
          rebuildStep={this.rebuildStep}
          stepDisabled={disabled}
        />
      </div>
    );
  }
}

export default KBIDReviewQuestions;
