import React from 'react';

import { DropdownButton, Dropdown, FormControl } from 'react-bootstrap'

export default class ActionComparison extends React.Component {
  constructor(props) {
    super(props);

    this.selectFilterOperator = this.selectFilterOperator.bind(this)
    this.renderDropdown = this.renderDropdown.bind(this)
  }

  selectFilterOperator(operator) {
    this.props.setActionOptionValue('operator', operator)
  }

  componentDidMount() {
    this.props.setActionOptionValues({'operator': '>=', filter_value: ''})
  }

  renderDropdown() {
    const { action } = this.props;
    const options = action.options;

    return (
      <DropdownButton 
        id="dropdown-basic-button"
        className='mr-2 d-inline-block'
        title={options.operator ? options.operator : '>='} 
        size='md'
        variant='light'
        onSelect={this.selectFilterOperator}
      >
        <Dropdown.Item 
          eventKey={'>='}
        >
          >=
        </Dropdown.Item>
        <Dropdown.Item 
          eventKey={'>'}
        >
          >
        </Dropdown.Item>
        <Dropdown.Item 
          eventKey={'=='}
        >
          ==
        </Dropdown.Item>
        <Dropdown.Item 
          eventKey={'!='}
        >
          !=
        </Dropdown.Item>
        <Dropdown.Item 
          eventKey={'<'}
        >
          { "<" }
        </Dropdown.Item>
        <Dropdown.Item 
          eventKey={'<='}
        >
          { "<=" }
        </Dropdown.Item>
      </DropdownButton>
    )
  }

  validateDouble(value) {
    return !isNaN(parseFloat(value))
  }

  render() {
    const { action } = this.props;
    const options = action.options;

    return (
      <div className='row mt-3'>
        <div className='col-md-6'>
          <label><strong>Operator</strong></label>
          <br/>
          <span className='mr-2'>Value</span>

          { this.renderDropdown() }
          <input
            type='text'
            className='form-control d-inline-block w-50'
            value={options.filter_value || ''}
            onChange={(e) => {
              e.preventDefault();
              const value = e.target.value;
              this.props.setActionOptionValue('filter_value', value)
            }}
          />
        </div>
      </div>
    );
  }
}
