import React from 'react';

import { DropdownButton, Dropdown, FormControl } from 'react-bootstrap'

import ActionListSelector from '../action-list-selector'
import ActionListColumnsSelector from '../action-list-columns-selector'
import ActionListExportOptions from '../action-list-export-options'
import ActionRun from '../action-run'

import ActionComparison from '../action-comparison'
import ActionMatchValue from '../action-match-value'
import ActionLookup from './action-lookup'

import ListDistinctValueCount from '../../../lists/list-distinct-value-count'

export default class ActionOccurence extends React.Component {
  constructor(props) {
    super(props);

    this.renderColumnModeSelector  = this.renderColumnModeSelector.bind(this)
    this.renderExportOptions       = this.renderExportOptions.bind(this)
    this.renderDistinctColumnCounts = this.renderDistinctColumnCounts.bind(this)

    this.selectMode = this.selectMode.bind(this)
  }

  selectMode(mode) {
    this.props.setActionOptionValue('processing_mode', mode)
  }

  renderColumnModeSelector() {
    const { action, lists, selectedList } = this.props;
    if(!selectedList) return null;

    const options = action.options;

    return (
      <div className='row mt-3'>
        <div className='col-md-5'>
          <label><strong>Processing Mode</strong></label>

          <DropdownButton 
            id="dropdown-basic-button"
            className='mr-2'
            title={options.processing_mode ? options.processing_mode : 'Select Mode'} 
            size='md'
            variant='light'
            onSelect={this.selectMode}
          >
            <Dropdown.Item 
              eventKey={'Remove Selected Columns'}
            >
              Remove Selected Columns
            </Dropdown.Item>
            <Dropdown.Item 
              eventKey={'Keep Selected Columns'}
            >
              Keep Selected Columns
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
    )
  }

  renderExportOptions() {
    const { action, lists, selectedList } = this.props;
    if(!selectedList) return null;

    return (
      <ActionListExportOptions
        forceNewList={false}
        forceOverwriteList={true}
        selectedList={selectedList}
        setActionOptionValue={this.props.setActionOptionValue}
        setActionOptionValues={this.props.setActionOptionValues}
        setActionOptionHash={this.props.setActionOptionHash}
        action={action}
      />
    )
  }

  renderDistinctColumnCounts() {
    const { action, lists, selectedList } = this.props;
    if(!selectedList) return null;

    if(!selectedList.distinct_value_count)
      return (
        <p>No distinct value counts for list generated</p>
      )
    else {
      return (
        <div className='mt-3'>
          <label><strong>Distinct Column Count</strong></label>
          <ListDistinctValueCount list={selectedList} />
        </div>
      )
    }
  }

  componentDidMount() {
    this.props.setActionOptionValues({
      export_mode: 'Overwrite List',
    })
  }

  render() {
    const { action, lists, selectedList } = this.props;

    return (
      <div className=''>
        <ActionListSelector
          action={action}
          lists={lists}
          list={selectedList}
          title="Lists"
          selectListId={this.props.selectListId}
        />

        { this.renderDistinctColumnCounts() }

        <ActionListColumnsSelector
          setActionOptionValue={this.props.setActionOptionValue}
          setActionOptionHash={this.props.setActionOptionHash}
          title="Select Columns To Process"
          headerField='headers'
          action={action} 
          list={selectedList}
        />

        { this.renderExportOptions() }

        { selectedList ? 
            <ActionRun
              saveAsDraft={this.props.saveAsDraft}
              runAction={this.props.runAction}
              cloneAction={this.props.cloneAction}
              action={action}
              displayDeleteModal={this.props.displayDeleteModal}
            /> : null
        }
      </div>
    );
  }
}
