import React from "react";

import UIInputEditor from "./ui-input-editor";

export default class NormalizationTableRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { mapping, mappings, name } = this.props;
    const values = Array.isArray(mappings[mapping])
      ? mappings[mapping]
      : [mapping];

    return (
      <tr>
        <td width="50%">
          {mapping === "BLANK" ? (
            mapping
          ) : (
            <UIInputEditor
              name={mapping}
              swapFields={(oldName, newName) => {
                return this.props.renameMappingField(name, oldName, newName);
              }}
            />
          )}
        </td>
        {values.map((value, idx) => (
          <td width="50%" key={idx}>
            <div className="d-flex">
              <input
                type="text"
                value={value}
                className="form-control"
                onFocus={function (e) {
                  var val = e.target.value;
                  e.target.value = "";
                  e.target.value = val;
                }}
                onChange={(e) => {
                  e.preventDefault();
                  this.props.setMappingField(
                    name,
                    mapping,
                    e.target.value,
                    idx
                  );
                }}
              />
            </div>
          </td>
        ))}
        {this.props.showDeleteState ? (
          <td width="50%">
            <button
              className="btn btn-sm btn-danger ml-2"
              onClick={(e) => {
                e.preventDefault();
                this.props.removeMappingField(name, mapping);
              }}
            >
              <i className="fas fa-times"></i>
            </button>
          </td>
        ) : null}
      </tr>
    );
  }
}
