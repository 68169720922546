import React from 'react';

const TagCount = (props) => {
  if(!props.tags) return null;

  const { tags } = props;

  const keys = Object.keys(tags);
  let tagsResult = []

  for(var i = 0; i < keys.length; ++i) {
    const tagName = keys[i];
    const tagValue = tags[tagName];

    tagsResult.push(
      <span className='badge badge-dark mr-2' key={`tag-${i}`}>
        {tagName} <span className='font-weight-lighter'>{tagValue.toLocaleString('en-US')}</span>
      </span>
    )
  }

  return tagsResult;
}

export default TagCount;