import React from 'react';
import axios from 'lib/axios-config'

import { DropdownButton, Dropdown, FormControl } from 'react-bootstrap'
import ActionListSelector from '../action-list-selector'
import ActionListColumnsSelector from '../action-list-columns-selector'

export default class ActionLookup extends React.Component {
  constructor(props) {
    super(props);

    this.fetchList = this.fetchList.bind(this)
    this.renderDropdown = this.renderDropdown.bind(this)
    this.selectFilterOperator = this.selectFilterOperator.bind(this)

    this.state = {
      selectList: {}
    }
  }

  componentDidMount() {
    this.selectFilterOperator('excluded')
  }

  fetchList(id) {
    axios.get(`/lists/${id}`)
      .then((response) => {
        this.props.setActionOptionValue('lookup_list_id', id)

        this.setState({
          selectedList: response.data
        })
      })
  }

  selectFilterOperator(value) {
    this.props.setActionOptionValue('operator', value)
  }

  renderDropdown() {
    const { action } = this.props;
    const options = action.options;

    return (
      <DropdownButton 
        id="dropdown-basic-button"
        className='mr-2 d-inline-block'
        title={options.operator ? options.operator : 'excluded'} 
        size='md'
        variant='light'
        onSelect={this.selectFilterOperator}
      >
        <Dropdown.Item 
          eventKey={'excluded'}
        >
          excluded
        </Dropdown.Item>
        <Dropdown.Item 
          eventKey={'included'}
        >
          included
        </Dropdown.Item>
      </DropdownButton>
    )
  }

  render() {
    const { action, lists } = this.props;
    const { selectedList } = this.state;

    return (
      <div>
        <ActionListSelector
          action={action}
          lists={lists}
          list={selectedList}
          title="Select List To Lookup"
          selectListId={this.fetchList}
        />

        <ActionListColumnsSelector
          setActionOptionValue={this.props.setActionOptionValue}
          setActionOptionHash={this.props.setActionOptionHash}
          title="Select Columns To Lookup"
          headerField='lookup_headers'
          action={action} 
          list={selectedList}
        />

        <div className='row mt-3'>
          <div className='col-md-12'>
            <label><strong>Match</strong></label>
            <br/>
            <span className='mr-2'>Value is</span>

            { this.renderDropdown() }
          </div>
        </div>
      </div>
    );
  }
}
