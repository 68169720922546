import React from 'react';

const NestedLink = (props) => {
  const isActive = () => {
    return window.location.pathname.includes(props.match)
  }

  let className = "vm-nested-link-item"
  className += isActive() ? " active" : ""

  return (
    <li className={className}>
      <a href={ props.to }>
        <span className='ml-5'>
          { props.title }
        </span>
      </a>
    </li>
  )
}

export default NestedLink;