import React from 'react';

export default class ActionListDetail extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { list } = this.props;

    if(!list) return null;

    return (
      <div className='row mt-3 text-break'>
        <div className='col-md-4'>
          <h4>{ this.props.title }</h4>
          <a href={`/lists/${list.id}/`} target="_blank">
            { list.name }
          </a>
        </div>

        <div className='col-md-4'>
          <h4>Rows</h4>
          { list.rows.toLocaleString('en-US') }
        </div>

        <div className='col-md-4'>
          <h4>Columns</h4>
          { list.headers.length }
        </div>
      </div>
    );
  }
}
