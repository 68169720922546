import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap'

import axios from 'lib/axios-config'
import withToaster from '../common/toaster-wrapper'

const ListResampleToolBar = (props) => {
  return (
    <div className='row'>
      <div className='col-md-12'>
    <div className='p-3 float-right'>
      <span className='font-weight-bold mr-2'>Resample</span>

      <button className='btn btn-sm btn-light' onClick={props.resampleRandomEntries}>
        <i className="fas fa-redo-alt"></i>
      </button>

      <DropdownButton 
        id="dropdown-basic-button" 
        className='d-inline-block ml-3'
        title={props.entries} 
        size='sm'
        variant='light'
        onSelect={props.changeEntries}
      >
        <Dropdown.Item eventKey={5}>5</Dropdown.Item>
        <Dropdown.Item eventKey={15}>15</Dropdown.Item>
        <Dropdown.Item eventKey={25}>25</Dropdown.Item>
        <Dropdown.Item eventKey={50}>50</Dropdown.Item>
      </DropdownButton>
    </div>
    </div>
    </div>
  )
}

class ListRandomEntries extends React.Component {
  constructor(props) {
    super(props);

    this.resampleRandomEntries = this.resampleRandomEntries.bind(this)
    this.changeEntries = this.changeEntries.bind(this)

    this.state = {
      entries: 15,
    }
  }

  resampleRandomEntries() {
    const { list } = this.props;

    axios.post(`/pipeline/actions/${list.id}/random_entries`, {
      entries: this.state.entries
    })
    .then((response) => {
      this.props.toast(`Sampling ${this.state.entries} random entries`)
      this.props.fetchList()
    })
  }

  changeEntries(entries) {
    this.setState({ entries })
  }

  render() {
    const { list } = this.props;
    const { headers, random_entries } = list;

    if(!random_entries || random_entries.length === 0) {
      return (
        <div className='event-logs'>
          <ListResampleToolBar 
            entries={this.state.entries} 
            resampleRandomEntries={this.resampleRandomEntries}
            changeEntries={this.changeEntries}
          />
        </div>
      )
    }

    return (
      <div>
        <div className='resource-table-wrapper event-logs'>
          <table className='table table-striped'>
            <thead>
              <tr>
                { headers.map((column, index) => {
                  return (
                    <th key={`col-header-${index}`}>{column}</th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              { random_entries.map((entry, rIndex) => {
                const randomEntry = random_entries[rIndex];
                const entryFields = [];

                headers.forEach((column, hIndex) => {
                  entryFields.push(
                    <td key={`col-entry-${hIndex}`}>
                      { randomEntry[column] }
                    </td>
                  )
                })

                return (
                  <tr key={`col-row-value-${rIndex}`}>
                    { entryFields }
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <ListResampleToolBar 
          entries={this.state.entries} 
          resampleRandomEntries={this.resampleRandomEntries}
          changeEntries={this.changeEntries}
        />
      </div>
    )
  }
}

export default withToaster(ListRandomEntries);