import React from 'react';
import AsyncListSelector from '../../lists/async-list-selector'

export default class ActionListSelector extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { action, lists, list } = this.props;

    return (
      <div className='row mt-3'>
        <div className='col-md-8'>
          <label><strong>{ this.props.title }</strong></label>

          <div>
            <span className='d-inline-block mr-2'>
              <AsyncListSelector
                onSelect={this.props.selectListId}
                currentListId={action.source_list_id}
              />
            </span>

            <span>
              { list ? list.name : 'No list selected' }
            </span>
          </div>
        </div>
        <div className='col-md-2'>
          <label><strong>Rows</strong></label>
          <br/>
          { list && list.rows ? list.rows.toLocaleString('en-US') : null }
        </div>

        <div className='col-md-2'>
          <label><strong>Columns</strong></label>
          <br/>
          { list && list.headers ? list.headers.length.toLocaleString('en-US') : null }
        </div>
      </div>
    );
  }
}
