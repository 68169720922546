import React, { Component } from 'react';

import { DropdownButton, Dropdown, FormControl } from 'react-bootstrap'
import ActionRun from '../action-run'
import ActionListColumnsSelector from '../action-list-columns-selector'
import ActionListExportOptions from '../action-list-export-options'

import ActionListSelector from '../action-list-selector'

import { isEmpty } from 'lib/string'
import find from 'lodash/find'

import axios from 'lib/axios-config'

export class ActionApi extends Component {
  constructor(props) {
    super(props);

    this.renderApiOptions = this.renderApiOptions.bind(this)
    this.selectEndpoint = this.selectEndpoint.bind(this)

    this.selectVpAccount = this.selectVpAccount.bind(this)
    this.selectVtAccount = this.selectVtAccount.bind(this)

    this.fetchVpAccounts = this.fetchVpAccounts.bind(this)
    this.fetchVtAccounts = this.fetchVtAccounts.bind(this)

    this.renderAccountSelector = this.renderAccountSelector.bind(this)
    this.renderVpAccountsSelector = this.renderVpAccountsSelector.bind(this)
    this.renderVtAccountsSelector = this.renderVtAccountsSelector.bind(this)

    this.state = {
      activeEndpoint: null,
      vp_accounts: null,
      vt_accounts: null
    }
  }

  findItem(items, id) {
    return find(items, {id: parseInt(id)})
  }

  selectEndpoint(endpoint_id) {
    const endpoint = this.findItem(this.props.apiEndpoints, endpoint_id)
    if(!endpoint) return;

    this.props.setActionOptionValue('api_endpoint', endpoint)

    if(endpoint.name === 'Victory Phones' && !this.state.vp_accounts) {
      this.fetchVpAccounts();
    } else if(endpoint.name === 'Victory Text' && !this.state.vt_accounts) {
      this.fetchVtAccounts();
    }

    this.setState({
      activeEndpoint: endpoint.name
    })
  }

  selectVpAccount(account_id) {
    const account = this.findItem(this.state.vp_accounts, account_id)
    if(!account) return;

    this.props.setActionOptionValue('vp_account', account)
  }

  selectVtAccount(account_id) {
    const account = this.findItem(this.state.vt_accounts, account_id)
    if(!account) return;

    this.props.setActionOptionValue('vt_account', account)
  }

  fetchVpAccounts() {
    axios.get('/vp_accounts')
      .then((response) => {
        this.setState({
          vp_accounts: response.data
        })
      })
  }

  fetchVtAccounts() {
    axios.get('/vt_accounts')
      .then((response) => {
        this.setState({
          vt_accounts: response.data
        })
      })
  }

  renderVpAccountsSelector() {
    const { apiEndpoints, action, lists, selectedList } = this.props;
    const { options } = action;
    const { vp_accounts } = this.state;

    if(!selectedList || !options.api_endpoint || !vp_accounts) {
      return null;
    }

    return (
      <div className='row mt-3'>
        <div className='col-md-5'>
          <label><strong>VP Account</strong></label>

          <DropdownButton 
            id="dropdown-basic-button"
            className='mr-2'
            title={options.vp_account ? options.vp_account.account_data.company.name : 'Select VP Account'} 
            size='md'
            variant='light'
            onSelect={this.selectVpAccount}
          >
            {
              vp_accounts.map((account, index) => {
                return (
                  <Dropdown.Item
                    key={`account-${index}`}
                    eventKey={account.id}
                    className={options.vp_account && options.vp_account.id === account.id ? 'active' : ''}
                  >
                    { account.account_data.company.name }
                  </Dropdown.Item>
                )
              })
            }
          </DropdownButton>
        </div>
      </div>
    );
  }

  renderVtAccountsSelector() {
    const { apiEndpoints, action, lists, selectedList } = this.props;
    const { options } = action;
    const { vt_accounts } = this.state;

    if(!selectedList || !options.api_endpoint || !vt_accounts) {
      return null;
    }

    return (
      <div className='row mt-3'>
        <div className='col-md-5'>
          <label><strong>VT Account</strong></label>

          <DropdownButton 
            id="dropdown-basic-button"
            className='mr-2'
            title={options.vt_account ? options.vt_account.account_data.name : 'Select VT Account'} 
            size='md'
            variant='light'
            onSelect={this.selectVtAccount}
          >
            {
              vt_accounts.map((account, index) => {
                return (
                  <Dropdown.Item
                    key={`account-${index}`}
                    eventKey={account.id}
                    className={options.vt_account && options.vt_account.id === account.id ? 'active' : ''}
                  >
                    { account.account_data.name }
                  </Dropdown.Item>
                )
              })
            }
          </DropdownButton>
        </div>
      </div>
    );
  }

  renderApiOptions() {
    const { apiEndpoints, action, lists, selectedList } = this.props;
    const { options } = action;

    return (
      <div className='row mt-3'>
        <div className='col-md-5'>
          <label><strong>API Endpoint</strong></label>

          <DropdownButton 
            id="dropdown-basic-button"
            className='mr-2'
            title={options.api_endpoint ? options.api_endpoint.name : 'Select Endpoint'} 
            size='md'
            variant='light'
            onSelect={this.selectEndpoint}
          >
            {
              apiEndpoints.map((endpoint, index) => {
                return (
                  <Dropdown.Item
                    key={`api-endpoint-${index}`}
                    eventKey={endpoint.id}
                    className={options.api_endpoint && options.api_endpoint.name === endpoint.name ? 'active' : ''}
                  >
                    { endpoint.name }
                  </Dropdown.Item>
                )
              })
            }
          </DropdownButton>
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.props.setActionOptionValue('export_mode', 'New List')
    this.fetchVtAccounts()
    this.fetchVpAccounts()
  }

  renderAccountSelector() {
    switch(this.state.activeEndpoint) {
      case 'Victory Phones':
        return this.renderVpAccountsSelector();
      case 'Victory Text':
        return this.renderVtAccountsSelector();
      default:
        return null;
    }
  }

  render() {
    const { action, lists, selectedList } = this.props;

    return (
      <div>
        <ActionListSelector
          action={action}
          lists={lists}
          list={selectedList}
          title="Lists"
          selectListId={this.props.selectListId}
        />

        { selectedList ? this.renderApiOptions() : null }
        { selectedList ? this.renderAccountSelector() : null }

        {
          selectedList ?
            <ActionListExportOptions
              forceNewList={true}
              selectedList={selectedList}
              setActionOptionValue={this.props.setActionOptionValue}
              setActionOptionValues={this.props.setActionOptionValues}
              setActionOptionHash={this.props.setActionOptionHash}
              action={action}
            /> : null
        }

        { selectedList ? 
            <ActionRun
              saveAsDraft={this.props.saveAsDraft}
              runAction={this.props.runAction}
              cloneAction={this.props.cloneAction}
              displayDeleteModal={this.props.displayDeleteModal}
              action={action}
            /> : null
        }
      </div>
    );
  }
}

export default ActionApi