import axios from "lib/axios-config";
import { toasterCacheSet } from "lib/toaster-cache";
import React, { Component } from "react";
import ActionRun from "./kbid-action-run";

import get from "lodash/get";
import some from "lodash/some";
import KbidInput from "./kbid-input";

class KBIDForm extends Component {
  constructor(props) {
    super(props);
    this.onKbidChange = this.onKbidChange.bind(this);
    this.addNewRow = this.addNewRow.bind(this);
    this.deleteKbid = this.deleteKbid.bind(this);
    this.goToNext = this.goToNext.bind(this);
    this.saveAction = this.saveAction.bind(this);

    const kbids = get(
      props.action,
      ["options", "step-kbids", "form", "kbids"],
      [""]
    );

    this.state = {
      kbids,
      isSubmitting: false,
    };
  }

  addNewRow() {
    if (this.props.disabled) return;

    this.setState((prevState) => ({
      ...prevState,
      kbids: [...prevState.kbids, ""],
    }));
  }

  deleteKbid(idx) {
    if (this.props.disabled) return;

    const { kbids } = this.state;

    const clone = [...kbids];
    clone.splice(idx, 1);

    this.setState({ kbids: clone });
  }

  onKbidChange(idx, value) {
    if (this.props.disabled) return;

    const { kbids } = this.state;

    const clone = [...kbids];
    clone[idx] = value;

    this.setState({ kbids: clone });
  }

  saveAction(validKbids) {
    const { action, id } = this.props;
    action.status = "draft";
    action.options = {
      ...action.options,
      [id]: {
        form: { kbids: validKbids },
      },
    };

    const isEdit = action.id != null;

    return isEdit
      ? axios.put(`/pipeline/actions/${action.id}`, { action_params: action })
      : axios.post("/pipeline/actions", { action_params: action });
  }

  goToNext() {
    const { goToStep, disabled, stepIdx } = this.props;

    if (disabled) {
      goToStep(stepIdx + 1);
      return;
    }

    const { kbids = [] } = this.state;

    const validKbids = kbids
      .map((str) => str.trim())
      .filter((str) => str.length > 0);

    if (validKbids.length < 1) return;

    this.setState({ isSubmitting: true }, () => {
      this.saveAction(validKbids)
        .then((actionResponse) => {
          window.location = `/pipeline/actions/${actionResponse.data.action.id}/edit`;
        })
        .catch(() => {
          this.setState({ isSubmitting: false });
          toasterCacheSet("Unknown error", "error");
        });
    });
  }

  render() {
    const { action, disabled } = this.props;
    const { kbids, isSubmitting } = this.state;
    const isValid =
      kbids.length > 0 &&
      some(kbids, (val) => val && val.length > 0) &&
      (action?.name || "").length > 0;

    return (
      <div>
        <h4 className="text-center mt-4">Step 1 - Enter KBIDs</h4>
        <div className="mt-4 mb-2">
          {kbids.map((kbid, idx) => (
            <KbidInput
              key={idx}
              name={idx}
              value={kbid}
              onChange={(e) => this.onKbidChange(idx, e.target.value)}
              onDelete={() => this.deleteKbid(idx)}
              disabled={disabled}
            />
          ))}
        </div>
        {!disabled && (
          <div className="row mb-4">
            <button
              type="button"
              className="btn btn-secondary ml-3"
              onClick={this.addNewRow}
            >
              Add KBID
            </button>
          </div>
        )}{" "}
        {isValid ? (
          <ActionRun
            action={action}
            displayDeleteModal={this.props.displayDeleteModal}
            goToNext={this.goToNext}
            isSubmitting={isSubmitting}
            stepDisabled={disabled}
          />
        ) : null}
      </div>
    );
  }
}

export default KBIDForm;
