import axios from "lib/axios-config";
import { toasterCacheSet } from "lib/toaster-cache";
import cloneDeep from "lodash/cloneDeep";
import flatten from "lodash/flatten";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import set from "lodash/set";
import keys from "lodash/keys";
import values from "lodash/values";
import React, { Component, useState } from "react";
import ActionRun from "./kbid-action-run";
import SectionForm from "./kbid-common-section-form";
import KbidContentLoader from "./kbid-content-loader";
import TextareaAutosize from "react-textarea-autosize";

const cellStyle = {
  maxWidth: "220px",
  minWidth: "220px",
  width: "220px",
  wordWrap: "break-word",
  padding: "16px 10px",
};

const scoreCellStyle = {
  ...cellStyle,
  maxWidth: "60px",
  minWidth: "60px",
  width: "60px",
};

const inputStyle = {
  padding: "4px",
  fontSize: "1em",
  resize: "none",
};

const verifiedCellStyle = {
  width: "50px",
  minWidth: "50px",
  maxWidth: "50px",
  padding: "0px 8px",
  verticalAlign: "middle",
};

const clickable = {
  cursor: "pointer",
};

function Row(props) {
  const { record, onInputChange, onVerifiedToggle, disabled } = props;

  return (
    <tr>
      <td style={cellStyle}>{record.old_answer}</td>
      <td style={cellStyle}>
        <TextareaAutosize
          value={record.new_answer}
          className="form-control"
          style={inputStyle}
          onChange={(e) => onInputChange(e.target.value)}
          disabled={disabled}
        />
      </td>
      <td style={cellStyle}>{record.source_file}</td>
      <td style={scoreCellStyle}>{record.confidence_score}</td>
      <td style={verifiedCellStyle}>
        <input
          className="mr-1"
          type="checkbox"
          style={clickable}
          checked={record.verified}
          onChange={() => onVerifiedToggle(!record.verified)}
        />
      </td>
    </tr>
  );
}

function HeaderRow(props) {
  const { verifiedVisible, toggleVerifiedVisibility } = props;
  return (
    <tr>
      <th>Original</th>
      <th>Rename</th>
      <th>Source</th>
      <th>Score</th>
      <th style={verifiedCellStyle}>
        <i
          className={verifiedVisible ? "fas fa-eye-slash" : "fas fa-eye"}
          onClick={toggleVerifiedVisibility}
          style={clickable}
        />
      </th>
    </tr>
  );
}

class KBIDNormalizeValues extends Component {
  constructor(props) {
    super(props);
    this.goToNext = this.goToNext.bind(this);
    this.saveAction = this.saveAction.bind(this);
    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.rebuildStep = this.rebuildStep.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.toggleSectionVisibility = this.toggleSectionVisibility.bind(this);
    this.toggleOverallVisibility = this.toggleOverallVisibility.bind(this);

    const kbids = get(
      props.action,
      ["options", "step-kbids", "form", "kbids"],
      [""]
    );

    this.state = {
      kbids,
      answers: {},
      overallVisibilityState: "contract",
      sectionVisibility: {},
      isLoading: true,
      isSubmitting: false,
    };
  }

  componentDidMount() {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/normalization_keys`;

    return axios.get(uri).then((res) => {
      const answers = (res.data.normalized_answers || []).map((q) => {
        return {
          ...q,
          verified: q.confidence_score === 100,
          updated: false,
        };
      });

      const sortedAnswers = orderBy(
        answers,
        ["confidence_score", "new_answer"],
        ["asc", "asc"]
      );

      const groupedAnswers = groupBy(
        flatten(sortedAnswers),
        "normalized_column_name"
      );

      let sectionVisibility = {};
      keys(groupedAnswers).forEach((key) => {
        sectionVisibility[key] = this.state.overallVisibilityState === "expand";
      });

      this.setState({
        answers: groupedAnswers,
        sectionVisibility,
        demos: res.data.demos,
        isLoading: false,
      });
    });
  }

  toggleSectionVisibility(id) {
    const updated = set(
      cloneDeep(this.state.sectionVisibility),
      id,
      !this.state.sectionVisibility[id]
    );
    this.setState({ sectionVisibility: updated });
  }

  toggleOverallVisibility() {
    this.setState((prevState) => {
      const newVisibilityState =
        prevState.overallVisibilityState === "expand" ? "contract" : "expand";
      let sectionVisibility = {};
      keys(prevState.answers).forEach((key) => {
        sectionVisibility[key] = newVisibilityState === "expand";
      });
      return {
        ...prevState,
        overallVisibilityState: newVisibilityState,
        sectionVisibility,
      };
    });
  }

  handleRowUpdate(key, idx, path, value) {
    const updated = set(
      cloneDeep(this.state.answers),
      `${key}.${idx}.${path}`,
      value
    );
    set(updated, `${key}.${idx}.updated`, true);
    this.setState({ answers: updated });
  }

  saveAction() {
    const { action, id } = this.props;
    action.status = "draft";
    action.options = {
      ...action.options,
      [id]: "submitted",
    };

    // :update action
    return axios.put(`/pipeline/actions/${action.id}`, {
      action_params: action,
    });
  }

  submitForm(submitData) {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/normalize_keys`;

    const data = {
      normalized_answers: submitData,
    };

    return axios.post(uri, data).then((res) => res.data);
  }

  goToNext() {
    const { answers } = this.state;
    const records = flatten(values(answers));
    const { goToStep, stepIdx, action, id, mode } = this.props;
    const isUpdated = records.some((q) => q.updated);

    // If re-visiting the step, dont submit data to api. Just go to next step.
    if ((!isUpdated && action.options[id] != null) || mode === 'read-only') {
      goToStep(stepIdx + 1);
      return;
    }

    if (records.length < 1) return;

    const submitData = map(records, (answer) => {
      return {
        normalized_column_name: answer.normalized_column_name,
        old_answer: answer.old_answer,
        new_answer: answer.new_answer,
        confidence_score: answer.confidence_score,
        source_file: answer.source_file,
      };
    });
    this.setState({ isSubmitting: true }, () => {
      // If no changes were made on initial visit too,
      // dont submit data to api. Just mark as done and go to next step.
      const submitToApi =
        action.options[id] == null && !isUpdated
          ? Promise.resolve()
          : this.submitForm(submitData);

      submitToApi
        .then(() => this.saveAction())
        .then(() => goToStep(stepIdx + 1))
        .catch(() => {
          this.setState({ isSubmitting: false });
          toasterCacheSet("Unknown error", "error");
        });
    });
  }

  rebuildStep() {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/rebuild_normalization`;

    return this.setState({ isLoading: true }, () => {
      axios
        .post(uri)
        .then(() => this.props.rebuildStep())
        .catch(() => {
          this.setState({ isSubmitting: false });
          toasterCacheSet("Unknown error", "error");
        });
    });
  }

  renderRow({ record, index, id }) {
    return (
      <Row
        record={record}
        onInputChange={(value) =>
          this.handleRowUpdate(id, index, "new_answer", value)
        }
        onVerifiedToggle={(verified) =>
          this.handleRowUpdate(id, index, "verified", verified)
        }
        disabled={this.props.mode === 'read-only'}
      />
    );
  }

  render() {
    const { action, stepIdx, goToStep, disabled } = this.props;
    const {
      answers,
      isSubmitting,
      isLoading,
      overallVisibilityState,
      sectionVisibility,
    } = this.state;

    return (
      <div>
        <h4 className="text-center mt-4">Step 4 - Normalize Values</h4>
        <div className="row justify-content-end">
          <button
            className="btn btn-sm btn-light mb-2 mr-2"
            onClick={(e) => {
              e.preventDefault();
              this.toggleOverallVisibility();
            }}
          >
            {overallVisibilityState === "expand"
              ? "Collapse All"
              : "Expand All"}
          </button>
        </div>
        <div className="overflow-auto">
          {isLoading ? (
            <KbidContentLoader />
          ) : (
            map(answers, (records, id) =>
              records?.length > 0 ? (
                <SectionForm
                  key={id}
                  id={id}
                  records={records}
                  renderHeaderRow={HeaderRow}
                  Row={this.renderRow}
                  isVisible={sectionVisibility[id]}
                  toggleVisibility={() => this.toggleSectionVisibility(id)}
                />
              ) : null
            )
          )}
        </div>
        <ActionRun
          action={action}
          displayDeleteModal={this.props.displayDeleteModal}
          goToNext={this.goToNext}
          goToPrevious={() => goToStep(stepIdx - 1)}
          isSubmitting={isSubmitting}
          rebuildStep={this.rebuildStep}
          stepDisabled={disabled}
        />
      </div>
    );
  }
}

export default KBIDNormalizeValues;
