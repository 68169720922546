import React, { useState } from "react";
import KBIDForm from "./action-kbid-form-components/kbid-form";
import KBIDHeaderForm from "./action-kbid-form-components/kbid-header-form";
import axios from "lib/axios-config";
import KBIDDemoHeaders from "./action-kbid-form-components/kbid-demo-headers";
import KBIDNormalizeValues from "./action-kbid-form-components/kbid-normalize-values";
import KBIDReviewQuestions from "./action-kbid-form-components/kbid-review-questions";

const StepComponents = [
  {
    id: "step-kbids",
    Component: KBIDForm,
  },
  { id: "step-demo-headers", Component: KBIDDemoHeaders },
  { id: "step-header-map", Component: KBIDHeaderForm },
  { id: "step-normalize-values", Component: KBIDNormalizeValues },
  { id: "step-review-questions", Component: KBIDReviewQuestions },
];

function ActionKBID(props) {
  const { action, mode = "edit" } = props;
  const { options = [] } = action;
  const pendingNextStep = StepComponents.findIndex(
    (step) => options[step.id] == null
  );
  const stepToShow = pendingNextStep === -1 ? 0 : pendingNextStep;
  const [stepIdx, setStepIdx] = useState(stepToShow);
  const { id, Component: StepComponent } = StepComponents[stepIdx];

  const rebuildStep = () => {
    const resetData = StepComponents.reduce((acc, step, idx) => {
      if (idx >= stepIdx) {
        acc[step.id] = null;
      }
      return acc;
    }, {});

    const actionData = { ...action };

    actionData.options = {
      ...actionData.options,
      ...resetData,
    };

    // :update action
    return axios
      .put(`/pipeline/actions/${actionData.id}`, {
        action_params: actionData,
      })
      .then(() => {
        window.location.reload();
      });
  };

  return (
    <StepComponent
      {...props}
      id={id}
      stepIdx={stepIdx}
      onSuccess={() => setStepIdx(stepIdx + 1)}
      goToStep={(idx) => setStepIdx(idx)}
      disabled={
        (stepIdx === 0 && pendingNextStep !== 0) ||
        mode === "read-only" ||
        props.disabled
      }
      mode={mode}
      rebuildStep={rebuildStep}
    />
  );
}

export default ActionKBID;
