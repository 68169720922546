import axios from "lib/axios-config";
import { titleCase } from "lib/string";
import { toasterCacheSet } from "lib/toaster-cache";
import { cloneDeep, get, keyBy, map, set, uniq, flatten, filter } from "lodash";
import React, { Component } from "react";
import { FixedSizeList } from "react-window";
import SelectWithFilter from "../../../../common/select-with-filter";
import ActionRun from "./kbid-action-run";
import SectionForm from "./kbid-common-section-form";
import KbidContentLoader from "./kbid-content-loader";
import WeightingDownloadReportBtn from "./kbid-weighting-download-report-btn";

const cellStyle = {
  maxWidth: "280px",
  minWidth: "280px",
  width: "280px",
  wordWrap: "break-word",
  padding: "16px 10px",
};

const cellStyle2 = {
  maxWidth: "200px",
  minWidth: "200px",
  width: "200px",
  wordWrap: "break-word",
  padding: "16px 10px",
};

const inputStyle = {
  padding: "4px 12px",
  fontSize: "1em",
  resize: "none",
};

const clickable = {
  cursor: "pointer",
};

function EditingRow(props) {
  const {
    record,
    onInputChange,
    disabled,
    renderVirtualItem,
    statsVarNameList,
    selectedVarNames,
    onVarNameChange,
  } = props;
  const answers = record.answers || [];

  const handleChange = (value, idx) => {
    const clone = [...answers];
    clone[idx].weight = value;
    onInputChange("answers", clone);
  };

  return (
    <>
      <tr>
        <td style={cellStyle} colSpan={1}>
          <h6>Var Name</h6>
          <SelectWithFilter
            options={statsVarNameList}
            title={record.varname || "Select a Value"}
            onSelect={(val) => onVarNameChange(val)}
            excludeSet={selectedVarNames}
            disabled={disabled}
          />
        </td>
        <td colSpan={2}>
          <div
            className="d-flex ml-2 mt-2 font-weight-bold"
            style={{ height: "46px" }}
          >
            <div style={{ ...cellStyle, borderBottom: "1px solid lightgray" }}>
              Answer Text
            </div>
            <div style={{ ...cellStyle2, borderBottom: "1px solid lightgray" }}>
              Percentage
            </div>
            <div style={{ ...cellStyle, borderBottom: "1px solid lightgray" }}>
              Desired Weight
            </div>
          </div>
          <FixedSizeList
            className="table__body"
            height={300}
            width={770}
            itemData={{ answers, handleInputChange: handleChange, disabled }}
            itemCount={answers.length}
            itemSize={64}
          >
            {renderVirtualItem}
          </FixedSizeList>
        </td>
      </tr>
    </>
  );
}

const TableRow = ({ row, index, style, handleChange, disabled }) => {
  return (
    <div key={index} style={style} className="d-flex mt-2 mb-3">
      <div style={cellStyle} className="ellipsis-2" title={row.answer_text}>
        {row.answer_text}
      </div>
      <div style={cellStyle2} className="ellipsis-2">
        {row.answer_percentage}
      </div>
      <div style={cellStyle}>
        {disabled ? (
          <>{row.weight ?? ""}</>
        ) : (
          <input
            className="form-control"
            value={row.weight ?? ""}
            style={inputStyle}
            type="number"
            onChange={(e) => handleChange(e.target.value, index)}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

function HeaderRow() {
  return null;
}

function NoDataView(props) {
  return (
    <div
      className="col justify-content-center"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "40px",
      }}
    >
      <h4 className="text-center mt-4">No Rows</h4>
      <button
        className="btn btn-secondary mt-3 ml-3"
        onClick={(e) => {
          e.preventDefault();
          props.onAddRow();
        }}
      >
        Add Row
      </button>
    </div>
  );
}

const INITIAL_STATE = {
  lastId: -1,
  overallVisibilityState: "contract",
  sectionVisibility: {},
  weightedQuestions: [],
  weightedQuestionStats: [],
  statsWeightMap: {},
  statsVarNameList: [],
  selectedVarNames: new Set([]),
  isLoading: true,
  isSubmitting: false,
  interactionMode: "previewing",
};

class KBIDWeightingWeights extends Component {
  constructor(props) {
    super(props);
    this.goToNext = this.goToNext.bind(this);
    this.fetchInitialData = this.fetchInitialData.bind(this);
    this.saveAction = this.saveAction.bind(this);
    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.rebuildStep = this.rebuildStep.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.toggleSectionVisibility = this.toggleSectionVisibility.bind(this);
    this.toggleOverallVisibility = this.toggleOverallVisibility.bind(this);
    this.renderVirtualItem = this.renderVirtualItem.bind(this);
    this.handleRowAdd = this.handleRowAdd.bind(this);

    const kbids = get(
      props.action,
      ["options", "step-kbids", "form", "kbids"],
      [""]
    );

    this.state = { ...INITIAL_STATE, kbids };
  }

  componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData() {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/weighting`;
    const weightedQuestionsRequest = axios.get(uri, {
      params: { type: "weights" },
    });
    const statsColumnsRequest = axios.get(uri, {
      params: { type: "weights_stats" },
    });

    return Promise.all([weightedQuestionsRequest, statsColumnsRequest]).then(
      ([weightedQuestionsRes, statsRes]) => {
        const weightedQuestions = map(
          weightedQuestionsRes.data.weighted_questions || [],
          (col, idx) => ({ ...col, deleted: false, id: idx, updated: false })
        );

        const weightedQuestionStats = statsRes.data.weighted_questions;

        let sectionVisibility = {};
        weightedQuestions.forEach((_key, idx) => {
          sectionVisibility[idx] =
            this.state.overallVisibilityState === "expand";
        });

        const statsVarNameList = uniq(
          flatten(map(weightedQuestionStats, (rec) => rec.varname))
        ).map((name) => ({ name, id: name }));

        this.setState((prev) => ({
          ...prev,
          ...INITIAL_STATE,
          lastId: weightedQuestions.length - 1,
          weightedQuestions,
          weightedQuestionStats,
          selectedVarNames: new Set(map(weightedQuestions, "varname")),
          statsWeightMap: keyBy(weightedQuestionStats, "varname"),
          statsVarNameList,
          sectionVisibility,
          isLoading: false,
        }));
      }
    );
  }

  toggleSectionVisibility(id) {
    const updated = set(
      cloneDeep(this.state.sectionVisibility),
      id,
      !this.state.sectionVisibility[id]
    );
    this.setState({ sectionVisibility: updated });
  }

  toggleOverallVisibility() {
    this.setState((prevState) => {
      const newVisibilityState =
        prevState.overallVisibilityState === "expand" ? "contract" : "expand";
      let sectionVisibility = {};
      prevState.weightedQuestions.forEach((_key, idx) => {
        sectionVisibility[idx] = newVisibilityState === "expand";
      });
      return {
        ...prevState,
        overallVisibilityState: newVisibilityState,
        sectionVisibility,
      };
    });
  }

  handleRowUpdate(idx, path, value) {
    const updated = set(
      cloneDeep(this.state.weightedQuestions),
      `${idx}.${path}`,
      value
    );
    set(updated, `${idx}.updated`, true);

    if (path === "varname") {
      const statRecord = this.state.statsWeightMap[updated[idx].varname];
      set(updated, `${idx}.answers`, statRecord.answers);
    }
    const selectedWeightStateUpdate =
      path === "varname"
        ? { selectedVarNames: new Set(map(updated, "varname")) }
        : {};

    this.setState({ weightedQuestions: updated, ...selectedWeightStateUpdate });
  }

  handleRowAdd() {
    this.setState((prev) => ({
      ...prev,
      weightedQuestions: [
        ...prev.weightedQuestions,
        {
          varname: "",
          answers: [],
          deleted: false,
          updated: true,
          id: prev.lastId + 1,
          new: true,
        },
      ],
      lastId: prev.lastId + 1,
      interactionMode: "editing",
      sectionVisibility: { ...prev.sectionVisibility, [prev.lastId + 1]: true },
    }));
  }

  saveAction() {
    const { action, id } = this.props;
    action.status = "draft";
    action.options = {
      ...action.options,
      [id]: "done",
    };

    // :update action
    return axios.put(`/pipeline/actions/${action.id}`, {
      action_params: action,
    });
  }

  submitForm(submitData) {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/weighting`;

    return axios
      .post(uri, { type: "weights", data: submitData })
      .then((res) => res.data);
  }

  goToNext() {
    const { weightedQuestions: records } = this.state;
    const { goToStep, stepIdx, action, id, mode } = this.props;
    const isUpdated = records.some((q) => q.updated);

    // If re-visiting the step, dont submit data to api. Just go to next step.
    if ((!isUpdated && action.options[id] != null) || mode === "read-only") {
      goToStep(stepIdx + 1);
      return;
    }

    if (records.length < 1) return;

    const submitData = {
      weighted_questions: filter(records, (rec) => !rec.deleted).map((q) => ({
        varname: q.varname,
        answers: q.answers,
      })),
    };

    this.setState({ isSubmitting: true }, () => {
      // If no changes were made on initial visit too,
      // dont submit data to api. Just mark as done and go to next step.
      const submitToApi =
        action.options[id] == null && !isUpdated
          ? Promise.resolve()
          : this.submitForm(submitData);

      submitToApi
        .then(() => this.saveAction())
        .then(() => goToStep(stepIdx + 1))
        .catch(() => {
          this.setState({ isSubmitting: false });
          toasterCacheSet("Unknown error", "error");
        });
    });
  }

  rebuildStep(type = "weights") {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/rebuild_weighting`;

    return this.setState({ isLoading: true }, () => {
      axios
        .post(uri, { type })
        .then(() => this.props.rebuildStep())
        .catch(() => {
          this.setState({ isSubmitting: false });
          toasterCacheSet("Unknown error", "error");
        });
    });
  }

  renderRow({ record }) {
    return (
      <EditingRow
        statsVarNameList={this.state.statsVarNameList}
        selectedVarNames={this.state.selectedVarNames}
        record={record}
        onInputChange={(path, value) =>
          this.handleRowUpdate(record.id, path, value)
        }
        onVarNameChange={(value) =>
          this.handleRowUpdate(record.id, "varname", value)
        }
        disabled={
          this.props.mode === "read-only" ||
          this.state.interactionMode === "previewing"
        }
        renderVirtualItem={this.renderVirtualItem}
      />
    );
  }

  renderVirtualItem({ index, data, style }) {
    const item = data.answers[index];

    return (
      <TableRow
        index={index}
        style={style}
        row={item}
        handleChange={data.handleInputChange}
        disabled={data.disabled}
      />
    );
  }

  render() {
    const { action, stepIdx, goToStep, disabled, mode } = this.props;
    const {
      isSubmitting,
      isLoading,
      overallVisibilityState,
      sectionVisibility,
      weightedQuestions,
    } = this.state;

    const sections = weightedQuestions.filter((rec) => !rec.deleted);

    return (
      <div>
        <h4 className="text-center mt-4">Step 5 - Weights</h4>
        <div className="row justify-content-end">
          <button
            className="btn btn-sm btn-light mb-2 mr-2"
            onClick={(e) => {
              e.preventDefault();
              this.toggleOverallVisibility();
            }}
          >
            {overallVisibilityState === "expand"
              ? "Collapse All"
              : "Expand All"}
          </button>
          {mode !== "read-only" && (
            <button
              className="btn btn-sm btn-light mb-2 mr-2"
              onClick={(e) => {
                e.preventDefault();
                const nextMode =
                  this.state.interactionMode === "editing"
                    ? "previewing"
                    : "editing";

                this.setState({
                  interactionMode: nextMode,
                });
              }}
            >
              {titleCase(this.state.interactionMode)}
            </button>
          )}
        </div>
        <div>
          {isLoading ? (
            <KbidContentLoader />
          ) : sections.length === 0 ? (
            <NoDataView onAddRow={this.handleRowAdd} />
          ) : (
            map(sections, (record, idx) => (
              <SectionForm
                key={`${record.id}-${idx}`}
                rowKey={record.id}
                id={
                  record.varname === "" && record.new
                    ? `New Row ${idx}`
                    : record.varname
                }
                records={[record]}
                renderHeaderRow={HeaderRow}
                Row={this.renderRow}
                showVerified={false}
                isVisible={sectionVisibility[record.id]}
                toggleVisibility={() => this.toggleSectionVisibility(record.id)}
                deleteSection={
                  this.state.interactionMode === "editing"
                    ? () => this.handleRowUpdate(record.id, "deleted", true)
                    : undefined
                }
              />
            ))
          )}
        </div>
        {this.state.interactionMode === "editing" && (
          <div className="row justify-content-start">
            <button
              className="btn btn-secondary mt-3 ml-3"
              onClick={(e) => {
                e.preventDefault();
                this.handleRowAdd();
              }}
            >
              Add Row
            </button>
          </div>
        )}
        <ActionRun
          action={action}
          displayDeleteModal={this.props.displayDeleteModal}
          goToNext={this.goToNext}
          goToPrevious={() => goToStep(stepIdx - 1)}
          isSubmitting={isSubmitting}
          rebuildStep={this.rebuildStep}
          hasRebuildDropdown
          stepDisabled={disabled}
          renderCustomAction={() => (
            <WeightingDownloadReportBtn action={action} />
          )}
        />
      </div>
    );
  }
}

export default KBIDWeightingWeights;
