export function titleCase (text) {
  if(!text) return '';
  
  return text.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() })
}

export function isEmpty (string) {
  return string === null || string === undefined || string === '';
}

export function toLowerCase (string) {
  return string.toLowerCase();
}

function addWordOntoLine(line, word) {
  if (line.length != 0) {
      line += " ";
  }
  return(line += word);
}

export function chunkString(input, len) {
  var i;
  var output = [];
  var lineSoFar = "";
  var temp;
  var words = input.split(' ');
  for (i = 0; i < words.length;) {
      // check if adding this word would exceed the len
      temp = addWordOntoLine(lineSoFar, words[i]);
      if (temp.length > len) {
          if (lineSoFar.length == 0) {
              lineSoFar = temp;     // force to put at least one word in each line
              i++;                  // skip past this word now
          }
          output.push(lineSoFar);   // put line into output
          lineSoFar = "";           // init back to empty
      } else {
          lineSoFar = temp;         // take the new word
          i++;                      // skip past this word now
      }
  }
  if (lineSoFar.length > 0) {
      output.push(lineSoFar);
  }
  return(output);
}

export function exact (string) {
  return string;
}

export function humanFileSize(bytes, si) {
  var thresh = si ? 1000 : 1024;
  if(Math.abs(bytes) < thresh) {
      return bytes + ' B';
  }
  var units = si
      ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
      : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
  var u = -1;
  do {
      bytes /= thresh;
      ++u;
  } while(Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1)+' '+units[u];
}

export function match(value, filterValue) {
  if(!filterValue || filterValue === '') return true;
  if(!value || value === '') return false;

  const lowerCaseFilterValue = filterValue.toLowerCase();
  const words = value.split(" ").map((s) => s.toLowerCase())
  const lowerCaseValue = words.join(" ")

  if(lowerCaseFilterValue === lowerCaseValue || 
    lowerCaseValue.startsWith(lowerCaseFilterValue) || 
    lowerCaseValue.match(lowerCaseFilterValue)) {
    return true;
  }

  for(var i = 0; i < words.length; i++) {
    const word = words[i];

    if(word.startsWith(lowerCaseFilterValue)) {
      return true;
    }
  }

  return false;
}
