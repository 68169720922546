import axios from "lib/axios-config";
import { toasterCacheSet } from "lib/toaster-cache";
import React, { Component } from "react";
import ActionRun from "./action-kbid-form-components/kbid-action-run";

import get from "lodash/get";
import some from "lodash/some";
import KbidInput from "./action-kbid-form-components/kbid-input";

class ActionKBIDConcat extends Component {
  constructor(props) {
    super(props);
    this.onKbidChange = this.onKbidChange.bind(this);
    this.addNewRow = this.addNewRow.bind(this);
    this.deleteKbid = this.deleteKbid.bind(this);
    this.runAction = this.runAction.bind(this);
    this.saveAction = this.saveAction.bind(this);

    const kbids = get(
      props.action,
      ["options", "step-kbids", "form", "kbids"],
      [""]
    );

    this.state = {
      kbids,
      isSubmitting: false,
    };
  }

  addNewRow() {
    if (this.props.disabled) return;

    this.setState((prevState) => ({
      ...prevState,
      kbids: [...prevState.kbids, ""],
    }));
  }

  deleteKbid(idx) {
    if (this.props.disabled) return;

    const { kbids } = this.state;

    const clone = [...kbids];
    clone.splice(idx, 1);

    this.setState({ kbids: clone });
  }

  onKbidChange(idx, value) {
    if (this.props.disabled) return;

    const { kbids } = this.state;

    const clone = [...kbids];
    clone[idx] = value;

    this.setState({ kbids: clone });
  }

  saveAction(action, kbids, status) {
    action.status = status;
    if (status === "pending") {
      action.name = action.name;
      action.options = {
        ...action.options,
        export_mode: 'New List',
        destination_list_name: action.name,
        "step-kbids": {
          form: { kbids: kbids },
        },
      };
    }

    const isEdit = action.id != null;

    return isEdit
      ? axios.put(`/pipeline/actions/${action.id}`, { action_params: action })
      : axios.post("/pipeline/actions", { action_params: action });
  }

  runAction() {
    const { kbids = [] } = this.state;

    const validKbids = kbids
      .map((str) => str.trim())
      .filter((str) => str.length > 0);

    if (validKbids.length < 1) return;

    this.setState({ isSubmitting: true }, () => {
      this.saveAction(this.props.action, validKbids, "pending")
        .then(() => {
          toasterCacheSet("Action saved successfully", "success");
          window.location = "/pipeline/actions";
        })
        .catch(() => {
          this.setState({ isSubmitting: false });
          toasterCacheSet("Unknown error", "error");
        });
    });
  }

  render() {
    const { action } = this.props;
    const { kbids, isSubmitting } = this.state;
    const isValid =
      kbids.length > 0 && some(kbids, (val) => val && val.length > 0);

    return (
      <div>
        <h4 className="text-center mt-4">Step 1 - Enter KBIDs</h4>
        <div className="mt-4 mb-2">
          {kbids.map((kbid, idx) => (
            <KbidInput
              key={idx}
              name={idx}
              value={kbid}
              onChange={(e) => this.onKbidChange(idx, e.target.value)}
              onDelete={() => this.deleteKbid(idx)}
            />
          ))}
        </div>
        <div className="row mb-4">
          <button
            type="button"
            className="btn btn-secondary ml-3"
            onClick={this.addNewRow}
          >
            Add KBID
          </button>
        </div>
        {isValid ? (
          <ActionRun
            action={action}
            displayDeleteModal={this.props.displayDeleteModal}
            runAction={this.runAction}
            isSubmitting={isSubmitting}
            stepDisabled={false}
          />
        ) : null}
      </div>
    );
  }
}

export default ActionKBIDConcat;
