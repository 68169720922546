import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap'

export default class ParentListSelector extends React.Component {
  constructor(props) {
    super(props);

    this.selectList = this.selectList.bind(this)
  }

  selectList() {
    const { lists, parentListId } = this.props;
    if(!parentListId) return 'None';
    
    for(var i = 0; i < lists.length; i++) {
      if(parentListId === lists[i].id) {
        return lists[i].name
      }
    }
  }

  render() {
    const { lists } = this.props;

    return (
      <div>
        <DropdownButton 
          id="dropdown-basic-button"
          className='d-inline-block mr-2'
          title={'Parent List'} 
          size='sm'
          variant='light'
          onSelect={this.props.changeParentId}
        >
          <div style={{'overflowY': 'scroll', 'maxHeight': '200px'}}> 
            <Dropdown.Item eventKey={0}>None</Dropdown.Item>
            {
              lists.map((list, index) => {
                return (
                  <Dropdown.Item key={`list-option-${index}`} eventKey={list.id}>{list.name}</Dropdown.Item>
                )
              })
            }
          </div>
        </DropdownButton>

        <span className=''>
          { this.selectList() }
        </span>
      </div>
    );
  }
}
