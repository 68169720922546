import React from 'react'

import ResourceDeleteModal from '../ui/resource-delete-modal'

function withDeleteManager(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      
      this.displayDeleteModal = this.displayDeleteModal.bind(this)
      this.hideDeleteModal = this.hideDeleteModal.bind(this)
      this.renderDeleteModal = this.renderDeleteModal.bind(this)

      this.state = {
        deleteModalIsVisible: false,
        resource: null,
        resourceName: '',
        url: '',
        deleteHandler: null
      }
    }

    displayDeleteModal() {
      this.setState({deleteModalIsVisible: true})
    }

    hideDeleteModal() {
      this.setState({deleteModalIsVisible: false})
    }

    renderDeleteModal() {
      if(!this.state.deleteModalIsVisible) return null;

      return (
        <ResourceDeleteModal
          resource={this.state.resource}
          closeModal={this.hideDeleteModal}
          resourceName={this.state.resourceName} 
          url={this.state.url}
          visible={this.state.deleteModalIsVisible}
          handleDelete={this.state.deleteHandler}
        >
          <p>Are you sure you want to delete this {this.state.resourceName}?</p>
        </ResourceDeleteModal>
      ) 
    }

    render() {
      return (
        <div>
          { this.renderDeleteModal() }
          <WrappedComponent 
            {...this.props} 
            displayDeleteModal={this.displayDeleteModal}
            hideDeleteModal={this.hideDeleteModal}
            initializeDeleteModalResource={(opts) => {
              this.setState({
                resource: opts.resource,
                resourceName: opts.resourceName,
                url: opts.url,
                deleteHandler: opts.deleteHandler
              })
            }}
          />
        </div>
      )
    }
  }
}

export default withDeleteManager;