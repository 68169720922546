import React from 'react';

const ListShowNav = (props) => {
  const items = ['Details', 'Event Logs', 'Random Entries', 'Occurences'];

  const isActive = (item) => {
    return item === props.currentNavItem 
  }

  return (
    <ul className='list-show-nav'>
      {
        items.map((item, index) => {
          return (
            <li key={`list-nav-item-${index}`} className={'list-show-item' + (isActive(item) ? ' active' : '')} onClick={(e) => {
              e.preventDefault();
              props.changeNavItem(item)
            }}>
              { item }
            </li>
          )
        })
      }
    </ul> 
  )
}

export default ListShowNav;