import React from 'react';

import ResourceContainer from '../../ui/resource-container'
import ResourceHeader from '../../ui/resource-header'

import PaginationDropdown from '../../pagination/pagination-dropdown'
import PaginationSelector from '../../pagination/pagination-selector'

import axios from 'lib/axios-config'
import eventBus from 'lib/event-bus'

import withToaster from '../../common/toaster-wrapper'
import withDeleteManager from '../../common/delete-wrapper'

import moment from 'moment'

class NormalizationTables extends React.Component {
  constructor(props) {
    super(props);

    this.fetchNormalizationTables = this.fetchNormalizationTables.bind(this)

    this.state = {
      filterSearchVisible: false,
      normalizationTables: [],
      query: '',
      page: 1,
      perPage: 15,
      total: 0,
      q: '',
    }
  }

  fetchNormalizationTables(params = {}) {
    axios.get('/pipeline/normalization_tables', {
      params: {
        q: params.q,
        per_page: params.perPage,
        page: params.page,
      }
    })
      .then((response) => {
        console.log(response);

        this.setState({
          normalizationTables: response.data.normalization_tables,
          total: response.data.pagination.total
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  componentDidMount() {
    this.fetchNormalizationTables(this.state)

    eventBus.on('ActionSearch', (data) => {
      this.setState({
        q: data.q,
        page: 1,
      })

      this.fetchNormalizationTables({
        q: data.q, 
        page: 1, 
        perPage: this.state.perPage, 
      })
    })
  } 

  render() {
    return (
      <ResourceContainer>
        <ResourceHeader title='Pipeline' description='Manage your normalization tables' col={6}>
          <div className='float-right'>
            <button className='btn btn-dark btn-md' onClick={this.toggleFilterSearch}>
              <span className='fas fa-filter mr-2'/>
              Filter
            </button>
            <a href='/pipeline/normalization_tables/new' className='btn btn-primary btn-md ml-3'>
              <span className='fas fa-plus mr-2'/>
              New Normalization Table 
            </a>
          </div>
        </ResourceHeader>

        <div className='resource-body mt-2'>
          <div className='resource-table'>
            <table className='table'>
              <thead>
                <tr>
                  <th width="65%">Name</th>
                  <th>Created</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              
              <tbody>
                {
                  this.state.normalizationTables.map((table, index) => {
                    return (
                      <tr key={`normalization-row-${index}`} >
                        <td>
                          <div style={{'width': '400px', 'wordWrap': 'break-word'}}>
                            { table.name }
                          </div>
                        </td>

                        <td>
                          { moment(table.created_at).format('MM/DD/YYYY') }
                        </td>

                        <td>
                          <a href={`/pipeline/normalization_tables/${table.id}/edit`}>
                            Edit 
                          </a>
                        </td>
                      </tr>
                    )
                  })
                }

                <tr>
                  <td colSpan={2}>
                    <PaginationDropdown
                      perPage={this.state.perPage}
                      changePerPage={this.changePerPage}
                    />
                  </td>

                  <td colSpan={4}>
                    <span className='float-right'>
                      { this.state.total > 0 ? 
                          <PaginationSelector
                            page={this.state.page}
                            perPage={this.state.perPage}
                            changePage={this.changePage}
                            total={this.state.total}
                          />
                        : null
                      }
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ResourceContainer>
    );
  }
}

export default withToaster(withDeleteManager(NormalizationTables));
