import React from 'react';
import { connect } from 'react-redux'
import withProvider from '../common/provider-wrapper'

import ParentListSelector from './parent-list-selector'

import ResourceContainer from '../ui/resource-container'
import ResourceHeader from '../ui/resource-header'

import axios from 'lib/axios-config'

import withToaster from '../common/toaster-wrapper'

class ListsEdit extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this)
    this.fetchLists = this.fetchLists.bind(this)
    this.changeParentId = this.changeParentId.bind(this)

    this.state = {
      lists: [],
      list: {
        name: '',
        parent_id: null,
      }
    }
  }

  changeParentId(id) { 
    this.setState({ 
      list:{ 
        ...this.state.list,
        parent_id: !id ? null : parseInt(id)
      }
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    const { list } = this.state;

    axios.put(`/lists/${list.id}`, {
      name: list.name,
      parent_id: list.parent_id === 0 ? null : list.parent_id
    }).then((response) => {
      this.props.toast(`List ${list.name} succesfully updated!`) 
    }).catch((error) => {
      
    })
  }

  componentDidMount() {
    this.fetchLists();

    this.setState({
      list: this.props.list
    })
  }

  fetchLists() {
    axios.get('/lists/simple')
      .then((response) => {
        this.setState({
          lists: response.data.lists,
        });
      })
  }

  render() {
    const { list } = this.state;

    return (
      <ResourceContainer>
        <ResourceHeader title="Lists" description="Edit your list here" />
        <div className='row'>
          <div className='col-md-8'>
            <div className='resource-panel mb-5'>
              <div className='resource-body p-4'>
                <form className='form' onSubmit={this.handleSubmit}>
                  <div className='row'>
                    <div className='col-md-12'>
                      <label className='mb-1'><strong>Name</strong></label>
                      <input 
                        type='text' 
                        className='form-control' 
                        value={list.name} 
                        onChange={(e) => {
                          this.setState({list: {
                            ...this.state.list,
                            name: e.target.value
                          }})
                        }} 
                      />

                      <br/>

                      <label className='mb-1'><strong>Node</strong></label>
                      <ParentListSelector
                        lists={this.state.lists}
                        changeParentId={this.changeParentId}
                        parentListId={this.state.list.parent_id}
                      />
                    </div>
                  </div>

                  <div className='row mt-3'>
                    <div className='col-md-12'>
                      <div className='float-right'>
                        <a href={`/lists/${list.id}`} className='btn btn-secondary mr-3'>
                          Cancel
                        </a>

                        <button className='btn btn-success' type='submit'>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ResourceContainer>
    );
  }
}

export default withToaster(ListsEdit)