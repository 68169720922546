export function validateAction(action, actionType) {
  let validators = {
    options: {
      export_mode: function(s) {
        return s !== ''
      }
    }
  }

  // Run validation depending on Action Type
  switch(actionType.name) {
    case 'Filter':
      validators.options = {
        ...validators.options,
        filter_mode: function(s) {
          return s !== ''
        },
        filter_value: function(s) {
          return s !== '' || action.options.filter_mode === "Remove Blanks"
        }
      }
  }
  
  const keys = Object.keys(validators);
  for(var i = 0; i < keys.length; i++) {
    const field = keys[i];

    if (typeof validators[field] === 'object') {
      for(const validator in validators[field]) {
        const value = action[field][validator];
        const validatorFn = validators[field][validator];

        // If the validator function resolves to false,
        // the validation fails.
        if(!validatorFn(value)) {
          return {
            valid: false,
            message: `Action invalid: ${validator} == "${value}"`
          }
        }
      }
    }
  }

  // Made it through all validations, return true
  return {
    valid: true,
    message: 'Action is valid'
  } 
}
