import React from 'react';
import axios from 'lib/axios-config'

import withToaster from '../common/toaster-wrapper'

import ListDistinctValueCount from './list-distinct-value-count'

class ListOccurences extends React.Component {
  constructor(props) {
    super(props);

    this.generateDistinctColumnCount = this.generateDistinctColumnCount.bind(this)
    this.renderUniqueColumnButton = this.renderUniqueColumnButton.bind(this)
    this.renderUniqueColumnCount = this.renderUniqueColumnCount.bind(this)

  }

  generateDistinctColumnCount(e) {
    const { list } = this.props;
    e.preventDefault();

    axios.post(`/pipeline/actions/${list.id}/distinct_column_count`)
    .then((response) => {
      this.props.toast(`Counting distinct values for ${list.name}`)
      this.fetchList()
    })
  }

  renderUniqueColumnCount() {
    const { list } = this.props;
    
    return (
      <ListDistinctValueCount list={list} />
    )  
  }

  renderUniqueColumnButton() {
    return (
      <div>
        <button className='btn btn-light mt-2' onClick={this.generateDistinctColumnCount}>
          Count Unique Values
        </button>
      </div>
    )
  }

  renderOccurences() {
    const { list } = this.props;
    let occurences = []

    for (var key of Object.keys( list.occurences)) {
      occurences.push(
        <div key={`occurence-${key}`} className='mt-3'>
          <label><strong>{key}</strong></label>
            <ul className='list-group'>
              {
                list.occurences[key].map((o, j) => {
                  return (
                    <li className='list-group-item' key={`${key}-o-value-${j}`}>
                      { o || "Empty" }
                    </li>
                  )
                })
              }
            </ul>
        </div>
      )
    }

    return (
      <div>
        <h4 className='mt-4'>Occurences</h4>
        { occurences }
      </div>
    )
  }

  render() {
    const { list } = this.props;
    
    return (
      <div className='list-details p-4'>
        <div className='row'>
          <div className='col-md-2'>
            <h4>Rows</h4>
            { list && list.rows ? list.rows.toLocaleString('en-US') : null }
          </div>
          <div className='col-md-2'>
            <h4>Columns</h4>
            { list && list.headers ? list.headers.length.toLocaleString('en-US') : null }
          </div>
        </div>

        <h4 className='mt-4'>Distinct Column Count</h4> 
        { list.distinct_value_count ? 
            this.renderUniqueColumnCount() : this.renderUniqueColumnButton()
        }

        { list.occurences && !!Object.keys(list.occurences).length ? 
            this.renderOccurences() : null
        }
      </div>
    )
  }
}

export default withToaster(ListOccurences);
