import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap'

const PaginationDropdown = (props) => {
  return (
    <div>
      <span className='font-weight-bold'>Rows per page</span>

      <DropdownButton 
        id="dropdown-basic-button" 
        className='d-inline-block ml-3'
        title={props.perPage} 
        size='sm'
        variant='light'
        onSelect={props.changePerPage}
      >
        <Dropdown.Item eventKey={15}>15</Dropdown.Item>
        <Dropdown.Item eventKey={50}>50</Dropdown.Item>
        <Dropdown.Item eventKey={150}>150</Dropdown.Item>
        <Dropdown.Item eventKey={300}>300</Dropdown.Item>
      </DropdownButton>
    </div>
  )
}

export default PaginationDropdown;