import React from 'react';

import 'css/filter-search'

import { titleCase } from 'lib/string'
import eventBus from 'lib/event-bus'
import { DropdownButton, Dropdown } from 'react-bootstrap'

export default class FilterSearch extends React.Component {
  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.searchPlaceholder = this.searchPlaceholder.bind(this)
    this.handleChangeFilterValue = this.handleChangeFilterValue.bind(this)

    this.dispatchSearch = this.dispatchSearch.bind(this)
    this.renderFilters = this.renderFilters.bind(this)

    this.state = {
      visible: false,
      resource: '',
      q: '',
      filters: [],
      filterValues: {}
    }
  }

  handleSearch(e) {
    e.preventDefault();
    this.dispatchSearch({q: this.state.q, filterValues: this.state.filterValues})
  }

  handleChangeFilterValue(filter, value) {
    let { filterValues } = this.state;

    filterValues = {
      ...filterValues,
      [filter]: value
    }

    this.setState({
      filterValues,
    })

    this.dispatchSearch({ q: this.state.q, filterValues })
  }

  dispatchSearch(data) {
    eventBus.dispatch(`${this.state.resource}Search`, data )
  }

  componentDidMount() {
    eventBus.on('FilterSearchToggleOn', (data) => {
      const filterValues = {};

      if(data.filters) {
        data.filters.forEach((f) => {
          filterValues[f.name] = f.defaultValue 
        })
      }

      this.setState({
        resource: data.resource,
        visible: true,
        filters: data.filters,
        filterValues: filterValues,
        q: ''
      })
    })

    eventBus.on('FilterSearchToggleOff', (data) => {
      this.setState({
        visible: false,
      })
    })
  }

  handleInputChange(e) {
    this.setState({
      q: e.target.value
    })
  }

  componentWillUpdate(nextProps, nextState) {
    eventBus.remove('FilterSearchToggleOn')
    eventBus.remove('FilterSearchToggleOff')
  }

  searchPlaceholder() {
    const { resource } = this.state;

    if(!resource || resource === '') return 'Search';

    const string = 'Search for a '
    return string + resource 
  }

  renderFilters() {
    const { filters, filterValues } = this.state;

    return filters.map((filterData, index) => {
      return (
        <div 
          className='d-inline-block align-middle mr-3' 
          style={{'lineHeight': '15px'}} 
          key={`search-filter-${index}`}
        >
          <label className='mr-2'><strong>{ filterData.label }</strong></label>

          <DropdownButton 
            className='d-inline-block pl-0'
            title={titleCase(filterValues[filterData.name])} 
            size='sm'
            variant='dark'
            onSelect={(e) => {
              this.handleChangeFilterValue(filterData.name, e)
            }}
          >
            { filterData.options.map((option, oIndex) => {
              return (
                <Dropdown.Item 
                  key={`filter-${filterData.label}-option-${oIndex}`}
                  eventKey={option.value}>
                    { option.label }
                </Dropdown.Item>
              )
            })}
          </DropdownButton>
        </div>
      )
    })
  }

  render() {
    if(!this.state.visible) return null;

    return (
      <div className='filter-search'>
        <div className='container'>
          <form className='p-3' onSubmit={this.handleSearch}>
            <input 
              className='form-control d-inline-block align-middle mr-3' 
              style={{'width': '315px'}}
              placeholder={this.searchPlaceholder()} 
              value={this.state.q}
              onChange={this.handleInputChange} 
            />

            { this.renderFilters() }
          </form>
        </div>
      </div>
    );
  }
}
