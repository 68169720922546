import React from 'react';
import axios from 'lib/axios-config'

import { Form } from 'react-bootstrap'

import ActionRun from '../action-run'
import ActionListColumnsSelector from '../action-list-columns-selector'
import ActionListExportOptions from '../action-list-export-options'
import ActionHeaderDropdown from './action-header-dropdown'

import AutoMapSplitButton from './auto-map-split-button'
import ActionListSelector from '../action-list-selector'

import { isEmpty, match, toLowerCase, exact } from 'lib/string'

import wuzzy from 'wuzzy'

import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import filter from 'lodash/filter'
import pull from 'lodash/pull'

export default class ActionConcat extends React.Component {
  constructor(props) {
    super(props);

    this.selectListId = this.selectListId.bind(this)
    this.selectHeaders = this.selectHeaders.bind(this)
    this.fetchList = this.fetchList.bind(this)
    this.autoMapHeaders = this.autoMapHeaders.bind(this)
    this.clearAllHeaderMapping = this.clearAllHeaderMapping.bind(this)
    this.clearHeaderMapping = this.clearHeaderMapping.bind(this)
    this.addAppendHeader = this.addAppendHeader.bind(this)
    this.renderShowMappedStateToggle = this.renderShowMappedStateToggle.bind(this)

    this.getMappedHeader = this.getMappedHeader.bind(this)
    this.isMapped = this.isMapped.bind(this)

    this.renderAppendFields = this.renderAppendFields.bind(this)
    this.renderConcatOptions = this.renderConcatOptions.bind(this)
    this.renderRow = this.renderRow.bind(this)

    this.LEVENSTEIN_CUTTOF = 0.87;
    this.NGRAM_CUTOFF = 0.87;
    this.NGRAM_N = 3;

    this.state = {
      q: '',
      showMappedState: 'all',
      showMappedAppendState: 'unMappedOnly',

      selectList: null,
      reverseMappedIndexes: {},
      appendIndexes: {},
    }
  }

  prepareString(s) {
    if(isEmpty(s)) return '';
    return s.toLocaleString('en-US')
  }

  componentDidMount() {
    const { action } = this.props;

    if(action && action.id && action.options['append_list_id']) {
      const appendListId = action.options['append_list_id']

      axios.get(`/lists/${appendListId}`)
        .then((response) => {
          const headerMappings = action.options['header_mappings']
          const appendFields   = action.options['append_fields']

          const appendIndexes = {}
          const reverseMappedIndexes = {}

          for(var i = 0; i < appendFields.length; i++) {
            appendIndexes[appendFields[i]] = true
          }

          const keys = Object.keys(headerMappings)
          for(var i = 0; i < keys.length; i++) {
            const header = keys[i];
            const mapping = headerMappings[header];
            reverseMappedIndexes[mapping.append_header] = header
          }

          this.setState({
            selectedList: response.data,
            reverseMappedIndexes: reverseMappedIndexes,
            appendIndexes: appendIndexes,
          })
        })
    } else {
      this.props.setActionOptionValues({header_mappings: {}, append_fields: []})
    }
  }

  selectHeader(sourceIndex, sourceHeader, appendIndex, appendHeader) {
    let { reverseMappedIndexes, appendIndexes } = this.state
    const { action } = this.props;

    const headerMappings = action.options.header_mappings
    const appendFields = action.options.append_fields

    // Mapping already exists
    if(reverseMappedIndexes.hasOwnProperty(appendHeader)) {
      const existingSourceHeader = reverseMappedIndexes[appendHeader];
      const existingAppendHeader = headerMappings[existingSourceHeader].append_header;

      delete headerMappings[existingSourceHeader];
      delete reverseMappedIndexes[existingAppendHeader];
    } 

    // Mapping exists on this row
    if (headerMappings.hasOwnProperty(sourceHeader)) {
      const existingAppendHeader = headerMappings[sourceHeader].append_header;

      delete reverseMappedIndexes[existingAppendHeader];
    }

    // Custom mapping exists on Append Headers
    if(appendIndexes.hasOwnProperty(appendHeader)) {
      delete appendIndexes[appendHeader]
      pull(appendFields, appendHeader)
    }

    reverseMappedIndexes[appendHeader] = sourceHeader
    headerMappings[sourceHeader] = {
      source_index: sourceIndex,
      source_header: sourceHeader,
      append_index: appendIndex,
      append_header: appendHeader,
    }

    this.setState({reverseMappedIndexes})
    this.props.setActionOptionValues({
      header_mappings: headerMappings, append_fields: appendFields
    })
  }

  getMappedHeader(headerMappings, header) {
    return headerMappings.hasOwnProperty(header) ?
      headerMappings[header].append_header : null
  }

  isMapped(headerMappings, header) {
    const mapping = this.getMappedHeader(headerMappings, header);
    return mapping ?
      true : false 
  }

  addAppendHeader(appendField) {
    const { appendIndexes, reverseMappedIndexes } = this.state;
    const { action } = this.props;
    const options = action.options;
    const appendFields = options.append_fields;
    const headerMappings = options.header_mappings

    if(appendField === '') return;
    if(appendIndexes.hasOwnProperty(appendField)) return;

    if(reverseMappedIndexes.hasOwnProperty(appendField)) {
      const existingSourceHeader = reverseMappedIndexes[appendField];
      const existingAppendHeader = headerMappings[existingSourceHeader].append_header;

      delete headerMappings[existingSourceHeader];
      delete reverseMappedIndexes[existingAppendHeader];
    }

    appendIndexes[appendField] = true;
    reverseMappedIndexes[appendField] = appendField;

    this.setState({
      appendIndexes,
      reverseMappedIndexes,
    })

    appendFields.push(appendField)

    this.props.setActionOptionValue('append_fields', appendFields)
  }

  removeAppendHeader(header) {
    const { appendIndexes, reverseMappedIndexes } = this.state;
    const { action } = this.props;
    const options = action.options;
    const appendFields = options.append_fields;

    if(!appendIndexes.hasOwnProperty(header)) return;

    delete appendIndexes[header];
    delete reverseMappedIndexes[header];

    this.setState({
      appendIndexes
    })

    pull(appendFields, header)
    this.props.setActionOptionValue('append_fields', appendFields)
  }

  renderAppendFields() {
    const { 
      selectedList, 
      reverseMappedIndexes, 
      appendIndexes, 
    } = this.state;

    const { action } = this.props;
    const { options } = action;

    const headerMappings = options.header_mappings;
    const appendFields = options.append_fields;
    const renderedFields = []

    for(var i = 0; i < appendFields.length; i++) {
      const field = appendFields[i];

      renderedFields.push(
        <li className='list-group-item' key={`append-field-${i}`}>
          <div className='d-flex flex-row'>
            <div className='ml-auto'>
              <div className='d-inline-block mr-3'>
                { field }
              </div>
              <button onClick={(e) => {
                e.preventDefault();
                this.removeAppendHeader(field)
              }} className="btn btn-sm btn-light px-2 py-1 d-inline-block">X</button>
            </div>
          </div>
        </li> 
      )
    }

    renderedFields.push(
      <li className='list-group-item' key={`append-field-new-button`}>
        <div className='d-flex flex-row'>
          <div className='ml-auto'>
            <ActionHeaderDropdown
              showMappedState={this.state.showMappedAppendState}
              headers={selectedList.headers}
              reverseMappedIndexes={reverseMappedIndexes}
              displayHeader={'Select Field to Append'}
              selectHeader={(appendIndex, appendHeader) => {
                this.addAppendHeader(appendHeader)
              }}
            />
          </div>
        </div>
      </li> 
    )

    return renderedFields;
  }

  clearHeaderMapping(header) {
    const { 
      reverseMappedIndexes, 
      appendIndexes,
    } = this.state;

    const { action } = this.props;
    const { options } = action;

    const headerMappings = options.header_mappings;

    if(!header || !headerMappings.hasOwnProperty(header)) {
      return;
    }

    const mapping = headerMappings[header];
    const appendHeader = mapping.append_header;

    delete headerMappings[header];
    delete reverseMappedIndexes[appendHeader];

    this.setState({
      reverseMappedIndexes
    })

    this.props.setActionOptionValue('header_mappings', headerMappings)
  }
 
  renderRow(header, index, headerMappings) {
    return (
      <li className='list-group-item' key={`col-match-${index}`}>
        <div className='d-flex flex-row'>
          <span style={{'maxWidth': '200px'}}>{ header }</span>
          <div className='ml-auto'>
            <div className='d-inline-block'>
              <ActionHeaderDropdown
                showMappedState={this.state.showMappedAppendState}
                headers={this.state.selectedList.headers}
                reverseMappedIndexes={this.state.reverseMappedIndexes}
                displayHeader={this.getMappedHeader(headerMappings, header)}
                selectHeader={(appendIndex, appendHeader) => {
                  this.selectHeader(index, header, appendIndex, appendHeader)
                }}
              />
            </div>
            {
              this.isMapped(headerMappings, header) ?
                <button onClick={(e) => {
                  e.preventDefault();
                  this.clearHeaderMapping(header)
                }} className="btn btn-sm btn-light px-2 py-1 d-inline-block">X</button>
                : null
            }
          </div>
        </div>
      </li>
    )
  }

  autoMapHeaders(opts = {}) {
    const sourceList = this.props.selectedList;
    const appendList = this.state.selectedList; 
    if(!sourceList || !appendList) return;

    let transform = null
    let matcher = null

    if(opts.case_insentive) {
      transform = toLowerCase;
    } else {
      transform = exact;
    }

    switch(opts.partial_match) {
      case 'levenshtein':
        matcher = function(array, header) {
          let currentScore = -1;
          let currentIndex = -1;

          for(var i = 0; i < array.length; i++) {
            const item = array[i];
            const distance = wuzzy.levenshtein(transform(item), transform(header));
            if(distance >= this.LEVENSTEIN_CUTTOF) {
              if(currentScore < distance) {
                currentScore = distance;
                currentIndex = i;
              } 
            }
          }

          return currentIndex; 
        }.bind(this)

        break;
      case 'ngram':
        matcher = function(array, header) {
          let currentScore = -1;
          let currentIndex = -1;

          for(var i = 0; i < array.length; i++) {
            const item = array[i];
            const distance = wuzzy.ngram(transform(item), transform(header), this.NGRAM_N);
            if(distance >= this.NGRAM_CUTOFF) {
              if(currentScore < distance) {
                currentScore = distance;
                currentIndex = i;
              } 
            }
          }

          return currentIndex;
        }.bind(this)

        break;
      default:
        matcher = function(array, header) {
          return findIndex(array, (h) => transform(h) === transform(header))  
        }
    }

    const sourceHeaders = sourceList.headers;
    const appendHeaders = appendList.headers;

    const mappingObj = {}
    const reverseMappedIndexes = {}

    for(var sIndex = 0; sIndex < sourceHeaders.length; sIndex++) {
      const sHeader = sourceHeaders[sIndex];

      const aIndex = matcher(appendHeaders, sHeader)

      if(aIndex !== -1) {
        const aHeader = appendHeaders[aIndex];

        reverseMappedIndexes[aHeader] = sHeader
        mappingObj[sHeader] = {
          source_index: sIndex,
          append_index: aIndex,
          source_header: sHeader,
          append_header: aHeader 
        }
      }
    }

    this.setState({reverseMappedIndexes})
    this.props.setActionOptionValues({
      header_mappings: mappingObj, append_fields: []
    })
  }

  clearAllHeaderMapping(e) {
    e.preventDefault();

    this.setState({
      reverseMappedIndexes: {},
      appendIndexes: {},
      stagedAppendField: ''
    })
    
    this.props.setActionOptionValues({header_mappings: {}, append_fields: []})
  }

  selectHeaders(headers) {
    const { q } = this.state;
    const headerMappings = this.props.action.options['header_mappings']

    return filter(headers, (header) => {
      switch(this.state.showMappedState) {
        case 'all':
          return match(header, q);
        case 'mappedOnly':
          return match(header, q) && headerMappings.hasOwnProperty(header)
        case 'unMappedOnly':
          return match(header, q) && !headerMappings.hasOwnProperty(header)
        default:
          return match(header, q);
      }
    })
  }

  renderConcatOptions() {
    const { action, lists, selectedList } = this.props;
    const { options } = action;
    const { reverseMappedIndexes } = this.state;
    const headers = this.selectHeaders(selectedList.headers); 

    const headerMappings = options.header_mappings;

    return (
      <div className='row mt-3'>
        <div className='col-md-12'>
          <div className='mb-2'>
            <strong>Map Columns
              <span className="ml-2 badge badge-dark">
                { Object.keys(reverseMappedIndexes).length }
              </span>
            </strong>
          </div>

          <div className='mb-3'>
            <div className='row'>
              <div className='col-md-6'>
                <input type='text' className='form-control mb-1' value={this.state.q} 
                  onChange={(e) => {
                    this.setState({q: e.target.value})
                  }}
                />
                
              </div>
              <div className='col-md-6'>
                <div className='float-right'>
                  <button className='btn btn-sm btn-secondary mr-2' onClick={this.clearAllHeaderMapping}>
                    Clear Mapping
                  </button>

                  <AutoMapSplitButton
                    autoMapHeaders={this.autoMapHeaders}
                  />
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12 d-flex'>
                <div>
                  { this.renderShowMappedStateToggle('showMappedState', 'Source Headers') }
                </div>

                <div className='ml-auto'>
                  { this.renderShowMappedStateToggle('showMappedAppendState', 'Append Headers') }
                </div>
              </div>
            </div>
          </div>

          <div className='clearfix'></div>

          <ul className='list-group'>
            {
              headers.map((header, index) => {
                return this.renderRow(header, index, headerMappings)
              })
            }
            { this.renderAppendFields() }
          </ul>

        </div>
      </div>
    )
  }

  fetchList(id) {
    if(this.state.selectedList && this.state.selectedList.id === id) return;

    axios.get(`/lists/${id}`)
      .then((response) => {
        this.props.setActionOptionValues({
          append_list_id: id,
          header_mappings: {},
          append_fields: []
        })

        this.setState({
          selectedList: response.data,
          reverseMappedIndexes: {},
          appendIndexes: {},
          stagedAppendField: '',
        })
      })
  }

  selectListId(value) {
    this.props.selectListId(value)
      .then((list) => {
        this.props.setActionOptionValues({
          append_list_id: null,
          header_mappings: {},
          append_fields: []
        })

        this.setState({
          reverseMappedIndexes: {},
          appendIndexes: {},
          stagedAppendField: '',
        })
      })
  }

  renderShowMappedStateToggle(field, source) {
    let className = 'fas '
    let title = ''
    let newMappedState = null

    switch(this.state[field]) {
      case 'all':
        className += 'fa-eye';
        title = `All ${source}` 
        newMappedState = 'mappedOnly'
        break;
      case 'mappedOnly':
        className += 'fa-low-vision'
        title = `Mapped ${source} Only`
        newMappedState = 'unMappedOnly'
        break;
      case 'unMappedOnly':
        className += 'fa-eye-slash'
        title = `Un-Mapped ${source} Only`
        newMappedState = 'all'
        break;
      default:
        className += 'fa-eye'
        title = `All ${source}` 
        newMappedState = 'all'
    }

    return (
      <button className='btn btn-sm btn-muted mt-1' onClick={(e) => {
          e.preventDefault();
          this.setState({[field]: newMappedState})
        }}>

        <span className={className}></span>
        <span className='ml-3'>{ title }</span>
      </button>
    )
  }

  render() {
    const { action, lists, selectedList } = this.props;

    return (
      <div>
        <ActionListSelector
          action={action}
          lists={lists}
          list={selectedList}
          title="Lists"
          selectListId={this.selectListId}
        />

        {
          selectedList ?
            <ActionListSelector
              action={action}
              lists={lists}
              list={this.state.selectedList}
              title="Select List To Append"
              selectListId={this.fetchList}
            /> : null
        }

        { selectedList && this.state.selectedList ? 
            this.renderConcatOptions() : null 
        }

        {
          selectedList && this.state.selectedList ?
            <ActionListExportOptions
              forceNewList={false}
              selectedList={selectedList}
              setActionOptionValue={this.props.setActionOptionValue}
              setActionOptionValues={this.props.setActionOptionValues}
              setActionOptionHash={this.props.setActionOptionHash}
              action={action}
            /> : null
        }

        { selectedList && this.state.selectedList ? 
            <ActionRun
              saveAsDraft={this.props.saveAsDraft}
              runAction={this.props.runAction}
              cloneAction={this.props.cloneAction}
              displayDeleteModal={this.props.displayDeleteModal}
              action={action}
            /> : null
        }
      </div>
    );
  }
}
