import React from 'react';
import { DropdownButton, Dropdown, FormControl } from 'react-bootstrap'

import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'

import { match } from 'lib/string'

export default class NormalizationTableSelector extends React.Component {
  constructor(props) {
    super(props);

    this.selectTables= this.selectTables.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.selectNormalizationTable = this.selectNormalizationTable.bind(this)

    this.state = {
      q: ''
    }
  }

  handleToggle(e) {
    if(!e) {
      this.setState({q: ''})
    }
  }

  selectTables() {
    const { tables } = this.props;
    const { q } = this.state;

    return filter(tables, (table) => {
      return match(table.name, q)
    })
  }

  selectNormalizationTable(id) {
    const { tables } = this.props;

    const index = findIndex(tables, (table) => { return table.id === parseInt(id) })
    if(index === null || index < 0) return;

    this.props.selectNormalizationTable(tables[index])
  }

  render() {
    const tables = this.selectTables();

    return (
      <div className='row mt-3'>
        <div className='col-md-8'>
          <label><strong>{ "Load mappings from Normalization Table" }</strong></label>

          <DropdownButton 
            id="dropdown-basic-button"
            className='d-inline-block mr-2'
            title='Normalization Tables' 
            onToggle={this.handleToggle}
            size='md'
            variant='light'
            onSelect={this.selectNormalizationTable}
          >
            <div className='px-3'>
              <FormControl
                autoFocus
                className="my-2"
                placeholder="Type to filter..."
                onChange={(e) => {
                  this.setState({q: e.target.value})
                }}
                value={this.state.q}
              />
            </div>

            <div style={{'overflowY': 'scroll', 'maxHeight': '250px'}}>
              {
                tables.map((table, index) => {
                  return (
                    <Dropdown.Item 
                      key={`table-option-${index}-${table.id}`}
                      eventKey={table.id}
                    >
                      {table.name}
                    </Dropdown.Item>
                  )
                })
              }
            </div>
          </DropdownButton>
        </div>
      </div>
    );
  }
}
