import React from 'react'
import SidebarLink from './sidebar-link'
import NestedLink from './nested-link'

import 'css/sidebar'

export class AdminSidebar extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
  	return (
			<div className='card'>
				<ul className='vertical-nav'>
					<SidebarLink to="/polls" title="Polls" icon="poll" match="polls" />
					<SidebarLink to="/reports" title="Reports" icon="file-pdf" match="reports" />
					<SidebarLink to="/models" title="Models" icon="project-diagram" match="models"/>
					<SidebarLink to="/lists" title="Lists" icon="file-csv" match="lists" />

					<SidebarLink to="/pipeline/actions" title="Pipeline" icon="stream" match="pipeline">
						<NestedLink to="/pipeline/actions" title="Actions" match="actions" />
						<NestedLink to="/pipeline/normalization_tables" title="Normalization Tables" match="normalization_tables" />
					</SidebarLink>
				</ul>
			</div>
  	)
  }
}

export default AdminSidebar;
