import React from 'react';

const ActionDestinationListName = (props) => {
  const { action, list } = props;

  const value = action && action.options && action.options.destination_list_name ?
    action.options.destination_list_name : ''

  return (
    <div className='row mt-3'>
      <div className='col-md-12'>
        <label><strong>Destination List Name</strong></label>
        <input 
          type='text' 
          className='form-control' 
          value={value}
          onChange={(e) => {
            e.preventDefault();
            props.setActionListName(e.target.value)
          }} 
        />
      </div>
    </div>
  )
}

export default ActionDestinationListName;