import React from 'react';

export default class PollSelector extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { polls } = this.props;

    return (
      <ul className='list-group'>
        {
          polls.map((poll, index) => {
            return (
              <li className='list-group-item' key={`poll-${index}`}>
                <a href={`/polls/${poll.id}`}>
                  {poll.name}
                </a>
              </li>
            )
          })
        }
      </ul>
    );
  }
}
