import React from 'react';
import axios from 'lib/axios-config'

import ResourceContainer from '../../../ui/resource-container'
import ResourceHeader from '../../../ui/resource-header'

import OccurenceListSelector from './occurence-list-selector'
import NormalizationTable from './normalization-table'

import { titleCase} from 'lib/string'

export default class NormalizationTablesEditor extends React.Component {
  constructor(props) {
    super(props);

    this.selectListId = this.selectListId.bind(this)

    this.setField = this.setField.bind(this)
    this.transformOccurencesToNormalizationMappings = this.transformOccurencesToNormalizationMappings.bind(this)
    this.removeNormalizationTableMapping = this.removeNormalizationTableMapping.bind(this)
    this.resetMappings = this.resetMappings.bind(this)
    this.setAllUIVisibleState = this.setAllUIVisibleState.bind(this)
    this.setTableName = this.setTableName.bind(this)
    this.cloneTable = this.cloneTable.bind(this)
    this.addNewNormalizationTable = this.addNewNormalizationTable.bind(this)

    this.setMappingField = this.setMappingField.bind(this)
    this.addFieldMapping = this.addFieldMapping.bind(this)
    this.renameMappingField = this.renameMappingField.bind(this)
    this.removeMappingField = this.removeMappingField.bind(this)

    this.saveNormalizationTable = this.saveNormalizationTable.bind(this)

    this.renderNormalizationTablesEditing = this.renderNormalizationTablesEditing.bind(this)
    this.renderNormalizationTablesPreviewing = this.renderNormalizationTablesPreviewing.bind(this)
    this.renderMergeListPicker = this.renderMergeListPicker.bind(this);
    this.renderMergeSettingsIcon = this.renderMergeSettingsIcon.bind(this)

    this.processingOpenAI = this.processingOpenAI.bind(this)
    this.renderOpenAIComponent = this.renderOpenAIComponent.bind(this)

    this.BLANK_CHAR = 'BLANK'

    this.mergeMappings = this.mergeMappings.bind(this)

    this.state = {
      lists: [],
      allVisibleState: true,
      showDeleteState: false,
      mergeListMode: 'overwrite',
      showMergeMode: false,
      mappingUIVisible: {},
      interactionMode: 'previewing',
      latestFieldMappingIndexes: {},
      latestNewTableNameIndex: 0,
      normalization_table: {
        id: null,
        name: '',
        list_id: null,
        mappings: {}
      }
    }
  }

  renderMergeSettingsIcon() {
    return (
      <button className='btn btn-mute' onClick={(e) => {
        e.preventDefault();
        this.setState({
          showMergeMode: !this.state.showMergeMode 
        })
      }}>
        <i className="fas fa-cog"></i>
      </button>
    )
  }

  mergeMappings(mappingSource, mappingNew) {
    const mergeMode = this.state.mergeListMode

    if(mergeMode === 'overwrite') {
      return mappingNew;
    }

    // Create deep clone of source
    let merged = JSON.parse(JSON.stringify(mappingSource));

    // Traverse new mapping
    const newKeys = Object.keys(mappingNew);

    for(var i = 0; i < newKeys.length; i++) {
      const newKey = newKeys[i];

      if(merged.hasOwnProperty(newKey)) {
        if(mergeMode === 'appendAndReplace') {
          merged[newKey] = mappingNew[newKey]
        }
      } else {
        merged[newKey] = mappingNew[newKey]
      }
    }

    return merged;
  }

  setField(field, value) {
    this.setState({
      normalization_table: {
        ...this.state.normalization_table,
        [field]: value
      }
    })
  }

  componentDidMount() {
    const { normalization_table } = this.props;

    this.fetchLists();

    if(normalization_table) {
      this.setState({
        normalization_table: normalization_table,
      })
      
      this.setAllUIVisibleState(normalization_table.mappings, true) 
    }
  }

  saveNormalizationTable(e) {
    e.preventDefault();
    this.props.saveNormalizationTable(this.state.normalization_table)
  }

  fetchLists() {
    axios.get('/lists/simple', { params: {
      occurences: true
    }})
      .then((response) => {
        this.setState({
          lists: response.data.lists,
        }); 
      })
  }

  resetMappings() {
    this.setState({
      allVisibleState: true, 
      latestNewTableNameIndex: 0,
      latestFieldMappingIndexes: {}
    })

    this.setField('mappings', {})
  }

  setAllUIVisibleState(mappings, boolean = true) {
    const keys = Object.keys(mappings);
    let mappingUIVisible = {};
    
    for(var i = 0; i < keys.length; i++) {
      const columnName = keys[i];
      mappingUIVisible[columnName] = boolean
    }

    this.setState({
      mappingUIVisible: mappingUIVisible
    })
  }

  transformOccurencesToNormalizationMappings(mappings) {
    const keys = Object.keys(mappings);
    const rename_mappings = {}

    for(var i = 0; i < keys.length; i++) {
      const name = keys[i];
      const mappingArray = mappings[name];

      rename_mappings[name] = {}

      for(var j = 0; j < mappingArray.length; j++) {
        const field = mappingArray[j];

        if(field === null || field === '' || /^\s+$/.test(field)) {
          rename_mappings[name][this.BLANK_CHAR] = ''
        } else {
          rename_mappings[name][field] = field
        }
      }
    }

    return rename_mappings;
  }

  removeNormalizationTableMapping(name) {
    const { normalization_table } = this.state;

    if(!normalization_table.mappings.hasOwnProperty(name)) return;
 
    delete normalization_table.mappings[name];

    this.setState({
      normalization_table
    })

    this.forceUpdate();
  }

  selectListId(id) {
    axios.get(`/lists/${id}`)
      .then((response) => {
        const listMappings = this.transformOccurencesToNormalizationMappings(response.data.occurences)
        const mappings = this.mergeMappings(this.state.normalization_table.mappings, listMappings)

        this.setAllUIVisibleState(mappings, true)
        // this.setField('mappings', mappings)

        if(this.state.normalization_table.name === '') {
          this.setField('name', `${response.data.name}`)
        }

        this.setState({
          allVisibleState: true, 
          latestNewTableNameIndex: 0, 
          latestFieldMappingIndexes: {},
          normalization_table: {
            ...this.state.normalization_table,
            mappings,
            list_id: id
          }
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  addNewNormalizationTable() {
    let { latestNewTableNameIndex, normalization_table, mappingUIVisible } = this.state;

    let newTableName = 'Normalization Table '

    latestNewTableNameIndex += 1;
    newTableName += `${latestNewTableNameIndex}`

    this.setState({
      latestNewTableNameIndex: latestNewTableNameIndex,
      mappingUIVisible: {
        ...mappingUIVisible,
        [newTableName]: this.state.allVisibleState 
      },
      normalization_table: {
        ...normalization_table,
        mappings: {
          ...normalization_table.mappings,
          [newTableName]: {}
        }
      }
    })
  }

  addFieldMapping(tableName, newFieldName, latestNewFieldMappingIndex) {
    const { normalization_table, latestFieldMappingIndexes } = this.state;

    if(!newFieldName || newFieldName === '') return;

    if(!normalization_table.mappings.hasOwnProperty(tableName)) return;
    if(normalization_table.mappings[tableName].hasOwnProperty(newFieldName)) return;

    this.setState({
      latestFieldMappingIndexes: {
        ...latestFieldMappingIndexes,
        [tableName]: latestNewFieldMappingIndex
      },
      normalization_table: {
        ...normalization_table,
        mappings: {
          ...normalization_table.mappings,
          [tableName]: {
            ...normalization_table.mappings[tableName],
            [newFieldName]: ''
          }
        }
      }
    })
  }

  removeMappingField(tableName, field) {
    const { normalization_table } = this.state;
    if(!normalization_table.mappings.hasOwnProperty(tableName)) {
      return
    }

    if(!normalization_table.mappings[tableName].hasOwnProperty(field)) {
      return;
    }

    delete normalization_table.mappings[tableName][field];

    this.setState({
      normalization_table
    })
  }

  renameMappingField(tableName, oldFieldName, newFieldName) {
    const { normalization_table } = this.state;

    if(!normalization_table.mappings.hasOwnProperty(tableName)) {
      return Promise.reject("Table doesn't exist")
    }

    if(!normalization_table.mappings[tableName].hasOwnProperty(oldFieldName)) {
      return Promise.reject("Field doesn't exist")
    }

    if(normalization_table.mappings[tableName].hasOwnProperty(newFieldName)) {
      return Promise.reject("Field already exists")
    }


    const existingFieldValue = normalization_table.mappings[tableName][oldFieldName]
    delete normalization_table.mappings[tableName][oldFieldName];

    normalization_table.mappings[tableName][newFieldName] = existingFieldValue;

    this.setState({
      normalization_table
    });

    return Promise.resolve()
  }

  cloneTable(tableName) {
    const { normalization_table, mappingUIVisible } = this.state;
    const newTableName = `${tableName} copy`

    if(!normalization_table.mappings.hasOwnProperty(tableName)) {
      return;
    }

    if(normalization_table.mappings.hasOwnProperty(newTableName)) {
      return;
    }

    const clonedTable = JSON.parse(JSON.stringify(normalization_table.mappings[tableName]));

    this.setState({
      mappingUIVisible: {
        ...mappingUIVisible,
        [newTableName]: this.state.allVisibleState 
      },
      normalization_table: {
        ...normalization_table,
        mappings: {
          ...normalization_table.mappings,
          [newTableName]: clonedTable
        }
      }
    })
  }

  renderMergeListPicker() {
    let nextMode = null;
    let text = ''

    if(this.state.mergeListMode === 'overwrite') {
      text = "Overwrite all entries"
      nextMode = 'appendAndReplace'
    } else if(this.state.mergeListMode === 'appendAndReplace') {
      text = 'Append tables and replace duplicates'
      nextMode = 'appendAndSkip'
    } else {
      text = 'Append tables and skip duplicates'
      nextMode = 'overwrite'
    }

    return (
      <button className='btn btn-light btn-sm mt-3' onClick={(e) => {
        e.preventDefault();
        this.setState({
          mergeListMode: nextMode
        })
      }}>
        { text }
      </button>
    )
  }

  setTableName(oldName, newName) {
    const { normalization_table, mappingUIVisible } = this.state;

    if(!normalization_table.mappings.hasOwnProperty(oldName)) {
      return Promise.reject("Old table name doesn't exist");
    }

    if(normalization_table.mappings.hasOwnProperty(newName)) {
      return Promise.reject("New table name already exists");
    }

    const existingMappings = JSON.parse(JSON.stringify(normalization_table.mappings[oldName]));
    delete normalization_table.mappings[oldName];
    normalization_table.mappings[newName] = existingMappings;

    this.setState({
      normalization_table,
      mappingUIVisible: {
        ...mappingUIVisible,
        [newName]: this.state.allVisibleState 
      },
    })

    return Promise.resolve()
  }

  setMappingField(tableName, sourceName, replaceName) {
    const { normalization_table } = this.state;

    if(!normalization_table.mappings.hasOwnProperty(tableName)) return;
    if(!normalization_table.mappings[tableName].hasOwnProperty(sourceName)) return;

    this.setState({
      normalization_table: {
        ...normalization_table,
        mappings: {
          ...normalization_table.mappings,
          [tableName]: {
            ...normalization_table.mappings[tableName],
            [sourceName]: replaceName
          }
        }
      }
    })
  }

  renderNormalizationTablesPreviewing() {
    const { mappings } = this.state.normalization_table;

    let mappingKeys = Object.keys(mappings);
    if(mappingKeys.length === 0) {
      return (
        <div className='card'>
          <div className='card-body'>
            <h5 className='m-0'>No Normalization Tables</h5>
          </div>
        </div>
      )
    }

    mappingKeys = mappingKeys.sort() 

    let style = {};
    style['borderBottomLeftRadius'] = '0px' 
    style['borderBottomRightRadius'] = '0px' 
    style['borderBottom'] = '0px'

    return mappingKeys.map((key, i) => {
      return (
        <>
          <div className="card" style={style} key={`normalization-table-${key}-${i}`}>
            <div className="card-header" style={{'borderBottom': '0px'}} >
              <div className='row'>
                <div className='col-md-8'>
                  { key }
                </div>
              </div>
            </div>
          </div>
          <table className='table'>
            <thead>
              <tr>
                <th>Original</th>
                <th>Rename</th>
              </tr>
            </thead>
            <tbody>
              { Object.keys(mappings[key]).map((mapping, j) => { 
                  return (
                    <tr key={`preview-table-${key}-${j}`}>
                      <td width="50%">{mapping}</td>
                      <td width="50%">{mappings[key][mapping]}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </>
      )
    })
  }

  saveNormalizationTable(e) {
    e.preventDefault();
    this.props.saveNormalizationTable(this.state.normalization_table)
  }

  renderNormalizationTablesEditing() {
    const { mappings } = this.state.normalization_table;

    let mappingKeys = Object.keys(mappings);
    mappingKeys = mappingKeys.sort()

    let visibleStateClassName = 'mr-2 fas'
    if(this.state.allVisibleState) {
      visibleStateClassName += ' fa-eye'
    } else {
      visibleStateClassName += ' fa-eye-slash'
    }

    let deletingStateClassName = 'mr-2 fas';
    if(this.state.showDeleteState) {
      deletingStateClassName += ' fa-eye'
    } else {
      deletingStateClassName += ' fa-eye-slash'
    }

    return (
      <div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='d-flex mb-3'>
              <button className='btn btn-sm btn-secondary ml-auto mr-2 mb-2' onClick={(e) => {
                e.preventDefault();
                this.setAllUIVisibleState(mappings, !this.state.allVisibleState)
                this.setState({allVisibleState: !this.state.allVisibleState})
              }}>
                <i className={visibleStateClassName}></i>
                {this.state.allVisibleState ? 'Collapse All' : 'Expand All'}
              </button>

              <button className='btn btn-sm btn-secondary mr-2 mb-2' onClick={(e) => {
                e.preventDefault();
                this.setState({showDeleteState: !this.state.showDeleteState})
              }}>
                <i className={deletingStateClassName}></i>
                {this.state.showDeleteState ? 'Hide Delete' : 'Show Delete'}
              </button>

              <button className='btn btn-sm btn-secondary mb-2' onClick={(e) => {
                e.preventDefault();
                this.resetMappings()
              }}>
                <i className="mr-2 fas fa-redo-alt"></i>
                Clear All Tables
              </button>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
          {
            mappingKeys.map((key, index) => {
              const mapping = mappings[key];
              return (
                <NormalizationTable
                  key={`normalization-table-${key}-${index}`}
                  index={index}
                  latestNewFieldMappingIndex={this.state.latestFieldMappingIndexes[key]}
                  name={key}
                  mappings={mapping}
                  mappingUIVisible={this.state.mappingUIVisible}
                  removeNormalizationTableMapping={this.removeNormalizationTableMapping}
                  setTableName={this.setTableName}
                  cloneTable={this.cloneTable}
                  setMappingField={this.setMappingField}
                  addFieldMapping={this.addFieldMapping}
                  renameMappingField={this.renameMappingField}
                  removeMappingField={this.removeMappingField}
                  showDeleteState={this.state.showDeleteState}
                  fieldLabels={['Original', 'Rename']}
                  removeTableField={this.removeTableField}
                  toggleMappingVisible={(name, visible) => {
                    this.setState({
                      mappingUIVisible: {
                        ...this.state.mappingUIVisible,
                        [name]: visible
                      }
                    })
                  }}
                />
              )
            })
          }
          </div>
        </div>

        <div className="d-grid gap-2">
          <button className='btn btn-light' onClick={(e) => {
            e.preventDefault();
            this.addNewNormalizationTable() 
          }}>
            Add Empty Normalization Table
          </button>
        </div>
      </div>
    )
  }

  renderOpenAIComponent() {
    if(!this.props.edit || !this.props.normalization_table.list_id) return null;

    return (
      <button className={'mb-2 mr-2 btn btn-sm btn-primary'} onClick={(e) => {
        e.preventDefault()
        this.props.processOpenAI(this.props.normalization_table.id)
      }}>
        OpenAI Suggest
      </button>
    )
  }

  processingOpenAI() {
    return this.props.edit &&
      this.props.normalization_table.status !== 'initialized' &&
      this.props.normalization_table.list_id;
  }

  render() {
    const table = this.state.normalization_table;
    const { mappings } = this.state.normalization_table;

    return (
      <ResourceContainer>
        <ResourceHeader title='Normalization Tables' description='Create a new normalization table' />

        <div className='row'>
          <div className='col-md-8'>
            <div className='resource-panel mb-5'>
              <div className='resource-body p-4'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <label><strong>Name</strong></label>
                      <input 
                        type='text' 
                        placeholder='Use a description name for normalization table'
                        className='form-control' 
                        value={table.name}
                        onChange={(e) => {
                          this.setField('name', e.target.value)
                        }} 
                      />
                    </div>
                  </div>

                {
                  this.processingOpenAI() ? null :
                    <div className='row mt-3'>
                      <div className='col-md-12'>
                        <label><strong>Import from List Occurences</strong></label>
                        <div className='d-flex'>
                          <OccurenceListSelector
                            lists={this.state.lists}
                            selectListId={this.selectListId}
                          />
                          { this.renderMergeSettingsIcon() }
                        </div>
                        { this.state.showMergeMode ? this.renderMergeListPicker() : null }
                      </div>
                    </div>
                }
                {
                  this.processingOpenAI() ?
                    <h5 className={'mt-5 font-italic text-center'}>Processing Normalization Table</h5> :
                    <div className='row mt-3'>
                      <div className='col-md-12'>
                        <div className='mt-3'>
                          <div className='d-flex'>
                            <label className='align-self-end mb-0'><strong>Normalization Tables</strong></label>

                            <div className='ml-auto'>
                              { this.renderOpenAIComponent()}

                              <button className='btn btn-sm btn-light mb-2 mr-2' onClick={(e) => {
                                e.preventDefault();
                                const nextMode = this.state.interactionMode === 'editing' ?
                                  'previewing' : 'editing';

                                this.setState({
                                  interactionMode: nextMode
                                })
                              }}>
                                {titleCase(this.state.interactionMode)}
                              </button>
                            </div>

                          </div>
                          <hr className='mt-0'/>
                          { this.state.interactionMode === 'editing' ?
                            this.renderNormalizationTablesEditing() :
                            this.renderNormalizationTablesPreviewing()
                          }
                        </div>
                      </div>
                    </div>
                }

                  <div className='row mt-5'>
                    <div className='col-md-12'>
                      <div className='float-right'>
                        <button className='btn btn-light mr-2' onClick={(e) => {
                          e.preventDefault();
                          window.location = '/pipeline/normalization_tables'
                        }}>
                          Cancel
                        </button>

                        <button className='btn btn-success' onClick={this.saveNormalizationTable} disabled={this.processingOpenAI()}>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </ResourceContainer>
    );
  }
}
