import React from "react";
import WeightingRebuildReportBtn from "./kbid-weighting-rebuild-report-btn.jsx";

const ActionRun = (props) => {
  const {
    action,
    isSubmitting,
    isSubmitDisabled,
    stepDisabled,
    renderCustomAction = () => null,
    submitDisabledMessage = "There are few errors in form",
    hasRebuildDropdown = false,
  } = props;

  return (
    <div className="row mt-4">
      <div className="col-md-6 d-flex">
        {!stepDisabled && props.rebuildStep ? (
          hasRebuildDropdown ? (
            <WeightingRebuildReportBtn onSelect={props.rebuildStep} />
          ) : (
            <button
              type="button"
              className="btn btn-warning ml-3 mr-2"
              onClick={props.rebuildStep}
              disabled={isSubmitting}
            >
              Rebuild Step
            </button>
          )
        ) : null}
        {renderCustomAction()}
      </div>
      <div className="col-md-6">
        <div className="float-right">
          <a href="/pipeline/actions" className="btn btn-light">
            Cancel
          </a>

          {action && action.id ? (
            <button
              type="button"
              className="btn btn-danger ml-3"
              onClick={props.displayDeleteModal}
              disabled={isSubmitting}
            >
              Delete
            </button>
          ) : null}

          {props.goToPrevious && (
            <button
              type="button"
              className="btn btn-secondary ml-3"
              onClick={props.goToPrevious}
              disabled={isSubmitting}
            >
              Previous
            </button>
          )}

          {props.goToNext && (
            <button
              type="button"
              className="btn btn-success ml-3"
              onClick={props.goToNext}
              disabled={isSubmitting || isSubmitDisabled}
              title={isSubmitDisabled ? submitDisabledMessage : ""}
            >
              {isSubmitting ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"
                  />
                  <span>Submitting...</span>
                </>
              ) : (
                <span>Next</span>
              )}
            </button>
          )}

          {props.runAction && (
            <button
              type="button"
              className="btn btn-success ml-3"
              onClick={props.runAction}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"
                  />
                  <span>Submitting...</span>
                </>
              ) : (
                <span>{stepDisabled ? "Done" : "Save"}</span>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActionRun;
