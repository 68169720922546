import { map } from "lodash";
import React, { useState } from "react";

const sectionVisibileStyle = {
  borderBottomLeftRadius: "0px",
  borderBottomRightRadius: "0px",
  borderBottom: "0px",
};

const sectionInvisibleStyle = {
  marginBottom: "1.5rem",
};

function SectionHeader(props) {
  const {
    isVisible,
    id,
    toggleVisible,
    deleteSection,
    verifiedCount,
    totalCount,
    showVerified,
  } = props;
  const style = isVisible ? sectionVisibileStyle : sectionInvisibleStyle;
  const chevronClassName = isVisible
    ? "fas fa-chevron-down"
    : "fas fa-chevron-up";

  return (
    <div className="card" style={style}>
      <div className="card-header" style={{ borderBottom: "0px" }}>
        <div className="row">
          <div className="col-md-6">
            <div className="d-flex font-weight-bold">{id}</div>
          </div>
          <div className="col-md-6">
            <div className="float-right">
              {showVerified ? (
                <span className="font-weight-bold mr-2">
                  {verifiedCount} of {totalCount}
                </span>
              ) : null}
              {deleteSection && (
                <button
                  className="btn btn-sm btn-muted"
                  onClick={(e) => {
                    e.preventDefault();
                    deleteSection(id);
                  }}
                  title="Delete section"
                  style={{ marginRight: showVerified ? "24px" : "48px" }}
                >
                  <i className="fas fa-minus-circle" />
                </button>
              )}
              <button
                className="btn btn-sm btn-muted mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  toggleVisible();
                }}
              >
                <i className={chevronClassName}></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SectionContent(props) {
  const {
    id,
    toggleVerifiedVisibility,
    records,
    verifiedVisible,
    renderHeaderRow,
    Row,
    rowKey = props.id,
  } = props;

  return (
    <table className="table">
      <thead>
        {renderHeaderRow({ verifiedVisible, toggleVerifiedVisibility })}
      </thead>
      <tbody>
        {map(records, (record, idx) => {
          return verifiedVisible || !record.verified ? (
            <Row key={`${rowKey}-${idx}`} id={id} record={record} index={idx} />
          ) : null;
        })}
      </tbody>
    </table>
  );
}

function SectionForm(props) {
  const {
    id,
    records,
    renderHeaderRow,
    Row,
    showVerified = true,
    isVisible,
    toggleVisibility,
    deleteSection,
    rowKey,
  } = props;
  const [verifiedVisible, setVerifiedVisible] = useState(true);

  const toggleVerifiedVisibility = () =>
    setVerifiedVisible((prevState) => !prevState);

  return (
    <section>
      <SectionHeader
        id={id}
        isVisible={isVisible}
        toggleVisible={toggleVisibility}
        deleteSection={deleteSection}
        showVerified={showVerified}
        verifiedCount={records.filter((rec) => rec.verified).length}
        totalCount={records.length}
      />
      {isVisible && (
        <SectionContent
          id={id}
          rowKey={rowKey}
          verifiedVisible={verifiedVisible}
          toggleVerifiedVisibility={toggleVerifiedVisibility}
          records={records}
          renderHeaderRow={renderHeaderRow}
          Row={Row}
        />
      )}
    </section>
  );
}

export default SectionForm;
