import React from 'react';

import ActionColumnTags from './action-column-tags'

const ActionDedupSettings = (props) => {
  const { action } = props;
  const { options } = action;
  const { results } = action;

  let matched = 0;

  if(results && results.hasOwnProperty('matched')) {
    matched = results.matched
  }

  return (
    <div className='row mt-3 text-break'>
      <div className='col-md-4'>
        <h4>Columns</h4>
        <ActionColumnTags
          action={action}
          headerField='headers'
        />
      </div>
      <div className='col-md-4'>
        <h4>Duplicates Found</h4>
        { matched.toLocaleString('en-US') }
      </div>
    </div>
  );
}

export default ActionDedupSettings;
