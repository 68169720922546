import React from 'react';
import { 
  VictoryPie, 
  VictoryLegend, 
  VictoryContainer, 
  LineSegment,
  VictoryLabel
} from 'victory'

import { chunkString } from 'lib/string'

export default class PollDisplayPie extends React.Component {
  constructor(props) {
    super(props);

    this.prepareData = this.prepareData.bind(this)
    this.multiLineLabel = this.multiLineLabel.bind(this)

    this.colors = [
      '#D13839', 
      '#EB5E5E', 
      '#880404', 
      '#4C12CA', 
      '#ABCEEC', 
      '#1D4A96', 
      '#06192E', 
      '#E16259'
    ]

    this.state = {
      data: [],
      labels: [],
      sum: 0,
      lines: 0
    }
  }

  multiLineLabel(label) {
    if(!label || label === '') return [''];
    return chunkString(label, 50)
  }

  prepareData() {
    let data = [];
    let labels = [];
    let sum = 0;

    let keys = Object.keys(this.props.data);
    keys = keys.sort();

    let totalLines = 0;

    for(var i = 0; i < keys.length; i++) {
      const key = keys[i];
      sum += parseInt(this.props.data[key]);

      const lines = this.multiLineLabel(key);
      if(lines.length > 1) {
        totalLines += lines.length * 0.70
      } else {
        totalLines += lines.length
      }

      labels.push({
        name: `${lines.join("\n")}`,
        symbol: { type: "square" }
      })

      data.push({
        x: key,
        y: this.props.data[key] 
      })
    }

    this.setState({
      sum,
      data,
      labels,
      lines: totalLines,
    })
  }

  componentDidMount() {
    if(!this.props.data) return;
    this.prepareData();
  }

  render() {
    const { sum } = this.state;

    return (
      <div>
        <VictoryPie
          padding={{ top: 30, left: 50, right: 50, bottom: 10 }}
          containerComponent={<VictoryContainer responsive={false}/>}
          height={500}
          title={this.props.source}
          colorScale={this.colors}
          labels={({ datum }) => `${Math.round((datum.y / sum)*100)}%` }
          innerRadius={({ datum }) => 120}
          data={this.state.data}
          style={{labels: {fontSize: 15}}}
        />
        <VictoryLegend
          y={10}
          containerComponent={<VictoryContainer responsive={false}/>}
          padding={{ top: 0, bottom: 0 }}
          rowGutter={{ top: 0, bottom: 2 }}
          height={this.state.lines * 50}
          width={200}
          title={`Source - ${this.props.source}`}
          orientation="vertical"
          colorScale={this.colors}
          labelComponent={
            <VictoryLabel width={270} verticalAnchor='middle' />
          }
          data={this.state.labels}
          style={{
            labels: { fontSize: 15 },
            title: { fontSize: 15}
          }}
        />
      </div>
    );
  }
}
