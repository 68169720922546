import React from 'react';

import ResourceContainer from '../../ui/resource-container'
import ResourceHeader from '../../ui/resource-header'

import ActionDetails from './view/action-details'
import { toasterCacheSet } from 'lib/toaster-cache'

import axios from 'lib/axios-config'

import withDeleteManager from '../../common/delete-wrapper'

import 'css/lists'

class ActionsShow extends React.Component {
  constructor(props) {
    super(props);
    this.cloneAction = this.cloneAction.bind(this)
    this.handleDeleteAction = this.handleDeleteAction.bind(this)
  }

  componentDidMount() {
    this.props.initializeDeleteModalResource({
      resource: this.props.action,
      resourceName: 'action',
      url: '/pipeline/actions',
      deleteHandler: this.handleDeleteAction
    })
  }

  handleDeleteAction() {
    const { action } = this.props;
    if(!action || !action.id) return;

    axios.delete(`/pipeline/actions/${action.id}`)
      .then((response) => {
        this.props.hideDeleteModal()
        toasterCacheSet(`Action ${action.name} deleted.`, 'warning')
        window.location = '/pipeline/actions'
      })
  }

  cloneAction(e) {
    e.preventDefault();

    const { action } = this.props;
    if(!action || !action.id) return;

    axios.post(`/pipeline/actions/${action.id}/clone`)
      .then((response) => {
        toasterCacheSet('Action cloned', 'success')
        window.location = `/pipeline/actions/${response.data.id}/edit`
      })    
  }

  render() {
    const { action } = this.props;
    const colWidth = action?.action_type?.name === 'Normalize' ? 'col-12' : 'col-md-10';

    return (
      <ResourceContainer>
        <ResourceHeader title="Action" description="View details about your action here" />
        <div className={`resource-panel mb-5 ${colWidth}`}>
          <div className='resource-body'>
            <div className='row px-4 pt-4 text-break'>
              <div className='col-md-7'>
                <h2 className='font-weight-bolder'>{ action.name }</h2>
              </div>

              <div className='col-md-5'>
                <div className='float-right'>
                  <button className='btn btn-danger mr-3' onClick={this.props.displayDeleteModal}>
                    Delete
                  </button>

                  <button type="button" className='btn btn-dark' onClick={this.cloneAction}>
                    Clone
                  </button>
                </div>
              </div>
            </div>

            <ActionDetails
              action={action}
            />
          </div>
        </div>
      </ResourceContainer>
    );
  }
}

export default withDeleteManager(ActionsShow)