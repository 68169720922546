import React from "react";
import { Dropdown } from "react-bootstrap";

const WeightingRebuildReportBtn = (props) => {
  const { onSelect } = props;

  const onChange = (value) => {
    // type will get it's default value in each step when undefined
    onSelect(value === "all" ? value : undefined);
  };

  return (
    <div className="">
      <Dropdown
        className="d-inline-block mr-2"
        size="md"
        variant="secondary"
        title="Rebuild"
        onSelect={onChange}
      >
        <Dropdown.Toggle variant="secondary">Rebuild</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item key="all" eventKey="all">
            Rebuild Data
          </Dropdown.Item>
          <Dropdown.Item key="current_step" eventKey="current_step">
            Rebuild Step
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default WeightingRebuildReportBtn;
