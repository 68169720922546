import React from 'react';
import { DropdownButton, Dropdown, FormControl, Button, ButtonGroup } from 'react-bootstrap'

const AutoMapSplitButton = (props = {}) => {
  return (
    <Dropdown as={ButtonGroup} >
      <Button variant="primary" size='sm' onClick={(e) => {
        props.autoMapHeaders({case_insensitive: true})
      }}>Auto Map</Button>

      <Dropdown.Toggle split variant="primary" size='sm' id="dropdown-split-basic" />

      <Dropdown.Menu>
        <Dropdown.Item onClick={(e) => {
          props.autoMapHeaders({case_insensitive: false})
        }}>
          Auto Map - Case Sensitive
        </Dropdown.Item>

        <Dropdown.Item onClick={(e) => {
        props.autoMapHeaders({case_insensitive: true})
        }}>
          Auto Map - Case Insensitive
        </Dropdown.Item>

        <Dropdown.Item onClick={(e) => {
        props.autoMapHeaders({partial_match: 'levenshtein', case_insensitive: true})
        }}>
          Auto Map - Levenshtein Approx
        </Dropdown.Item>

        <Dropdown.Item onClick={(e) => {
          props.autoMapHeaders({partial_match: 'ngram', case_insensitive: true})
        }}>
          Auto Map - N-gram Approx
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default AutoMapSplitButton;