import React from 'react';
import moment from 'moment'

import ActionListDetail from './action-list-detail'

import ActionDestinationList from './action-destination-list'
import ActionFilterSettings from './action-filter-settings'
import ActionDedupSettings from './action-dedup-settings'
import ActionTagSettings from './action-tag-settings'
import ActionApiSettings from './action-api-settings'
import ActionConcatSettings from './action-concat-settings'
import ActionColumnSettings from './action-column-settings'
import ActionFindAndReplaceSettings from './action-find-and-replace-settings'
import ActionKBID from '../config/action-kbid';

const ActionDetails = (props) => {
  const { action } = props;
  const actionType = action.action_type?.name;

  const showDetailsRow = actionType !== "Normalize";

  function renderActionSettings() {
    if(!action || !action.action_type) return null; 

    switch(action.action_type.name) {
      case 'API':
        return (
          <ActionApiSettings
            action={action}
          />
        )
      case 'Dedup':
        return (
          <ActionDedupSettings
            action={action}
          />
        )
      case 'Column':
        return (
          <ActionColumnSettings
            action={action}
          />
        )
      case 'Concat':
        return (
          <ActionConcatSettings
            action={action}
          />
        )
      case 'Filter':
        return (
          <ActionFilterSettings
            action={action}
          />
        )
      case 'Find & Replace':
        return (
          <ActionFindAndReplaceSettings
            action={action}
          />
        )
      case 'Tag':
        return (
          <ActionTagSettings
            action={action}
          />
        )
      case 'Normalize':
        return <ActionKBID 
          action={action}
          mode="read-only"
        />
      default:
        return null;
    }
  }

  function renderActionDestination() {
    if(!action) return null;

    if(action.lists) {
      return (
        <ActionDestinationList
          action={action}
        /> 
      )
    } else {
      return null;
    }
  }

  if(!action) return null;

  return (
    <div className="list-details p-4">
      {showDetailsRow && (
        <div className="row text-break">
          <div className="col-md-4">
            <h4>Action Type</h4>
            {action.action_type.name}
          </div>

          <div className="col-md-4">
            <h4>Created At</h4>
            {moment(action.created_at).format("MM/DD/YYYY")}
          </div>

          <div className="col-md-4">
            <h4>Updated At</h4>
            {moment(action.updated_at).format("MM/DD/YYYY")}
          </div>
        </div>
      )}

      <ActionListDetail title="Source List" list={action.source_list} />

      {renderActionSettings()}
      {renderActionDestination()}

      {action && action.duration && (
        <div className="row mt-3 text-break">
          <div className="col-md-4">
            <h4>Run Time</h4>
            {`${action.duration.toLocaleString("en-US")} seconds`}
          </div>
        </div>
      )}
    </div>
  );
};

export default ActionDetails;
