import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, DropdownButton, FormControl } from "react-bootstrap";
import axios from "lib/axios-config";
import { useDebounce } from "use-debounce";

export default function AsyncListSelector(props) {
  const { title, onSelect, currentListId } = props;
  const [text, setText] = useState("");
  const [lists, setLists] = useState([]);
  const [debouncedText] = useDebounce(text, 250);

  const fetchLists = useCallback((q) => {
    axios
      .get("/lists/simple", { params: { q, page: 1, per_page: 15 } })
      .then((response) => {
        setLists(response.data.lists);
      });
  }, []);

  const handleToggle = (e) => {
    if (!e) {
      setText("");
    }
  };

  useEffect(() => {
    fetchLists(debouncedText);
  }, [debouncedText]);

  return (
    <div>
      <DropdownButton
        id="dropdown-basic-button"
        className="d-inline-block mr-2"
        title={title || "List"}
        onToggle={handleToggle}
        size="md"
        variant="light"
        onSelect={onSelect}
      >
        <div className="px-3">
          <FormControl
            autoFocus
            className="my-2"
            placeholder="Type to filter..."
            onChange={(e) => {
              setText(e.target.value);
            }}
            value={text}
          />
        </div>

        <div style={{ overflowY: "scroll", maxHeight: "250px" }}>
          {lists.map((list, index) => {
            const className = currentListId == list.id ? "active" : "";
            return (
              <Dropdown.Item
                key={`list-option-${index}`}
                eventKey={list.id}
                className={className}
              >
                {list.name}
              </Dropdown.Item>
            );
          })}
        </div>
      </DropdownButton>
    </div>
  );
}
