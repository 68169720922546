import React from "react";

const rowStyle = {
  width: "500px",
};

const KbidInput = ({ name, value, onChange, onDelete, disabled = false }) => (
  <div className="row mb-4 ml-4" style={rowStyle}>
    <div className="col-md-9">
      <label>
        <strong>KBID</strong>
      </label>
      <input
        type="text"
        placeholder="Enter your KBID here"
        className="form-control"
        value={value}
        name={name}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
    <div className="col-md-3 pt-2">
      {!disabled && (
        <button
          type="button"
          className="btn btn-danger btn-sm mt-4 btn-sm"
          onClick={onDelete}
        >
          Delete
        </button>
      )}
    </div>
  </div>
);

export default KbidInput;
