import React from 'react';

const ListDistinctValueCount = (props) => {
  const { list } = props;
  const { headers, distinct_value_count } = list;

  return (
    <div style={{'overflowX': 'scroll'}} className='mt-3'>
      <table className='table table-bordered'>
        <thead>
          <tr>
            { headers.map((header, index) => {
              return (
                <th key={`unique-column-header-${index}`}>
                  { header }
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            { headers.map((header, index) => {
              return (
                <td key={`unique-column-value-${index}`}>
                  { distinct_value_count[header] ? 
                      parseInt(distinct_value_count[header]).toLocaleString('en-US') : 0 }
                </td>
              )
            })}
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ListDistinctValueCount;