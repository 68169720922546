import React from 'react';

const ActionFindAndReplaceSettings = (props) => {
  const { action } = props;
  const { options } = action;
  const { results } = action;

  console.log(options);

  return (
    <div className='row mt-3 text-break'>
      <div className='col-md-6'>
      {
        Object.keys(options.rename_mappings).map((name, i) => {
          return (
            <div className='card mb-3' key={`mapping-${name}-${i}`}>
              <div className='card-header'>
                { name }
              </div>

              <table className='table'>
                <thead>
                  <tr>
                    <th>Original</th>
                    <th>Replaced</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Object.keys(options.rename_mappings[name]).map((original, j) => {
                      return (
                        <tr key={`mapping-${name}-${i}-${j}`}>
                          <td width="50%">{original}</td>
                          <td width="50%">{options.rename_mappings[name][original]}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          )
        })
      }

        <table className='table'>


        </table>
      </div>
    </div>
  );
}

export default ActionFindAndReplaceSettings;