import React from 'react';
import filesize from 'filesize'
import moment from 'moment'

import TagCount from '../ui/tag-count'

const ListDetails = (props) => {
  const { list } = props;

  function renderChildUrl() {
    return (
      <a href={`/lists/${list.parent_id}`}>
        Parent
      </a>
    )
  }

  function renderActionsUrl() {
    const { actions } = list;

    const urls = actions.map((action, index) => {
      return (
        <a className='d-block' href={`/pipeline/actions/${action.id}`} key={`action-${index}`}>
          {action.action_type.name}
        </a>
      )
    });

    return urls;
  }

  function renderListTags() {
    if(!list.tags) return null;
    return(
      <div>
        <h4 className='mt-4'>Tags</h4>
        <TagCount
          tags={list.tags}
        /> 
      </div>
    )
  }

  return (
    <div className='list-details p-4'>
      <div className='row text-break'>
        <div className='col-md-4'>
          <h4>File Name</h4>
          { list.filename }

          <h4 className='mt-4'>File Size</h4>
          { filesize(list.size, {base: 10}) }

          <h4 className='mt-4'>Rows</h4>
          { list && list.rows ? list.rows.toLocaleString('en-US') : null }

          <h4 className='mt-4'>Columns</h4>
          { list && list.headers ? list.headers.length : null }

          <h4 className='mt-4'>Node</h4>
          { list.parent_id ? renderChildUrl() : 'Root'}

          <h4 className='mt-4'>Source</h4>
          { list.actions ? renderActionsUrl() : 'Upload' }
        </div>

        <div className='col-md-4'>
          <h4>Download</h4>

          { list.csv_presigned_url ?
            <a href={list.csv_presigned_url} className='download'>
              <i className="fas fa-download"></i>
            </a> : 'Not Available'
          }

          <h4 className='mt-4'>Worker Server</h4>
          { list.server_name }

          <h4 className='mt-4'>Worker Private IP</h4>
          { list.server_ip }

          <h4 className='mt-4'>UUID</h4>
          { list.uuid }
          
          <h4 className='mt-4'>Cache Path</h4>
          { list.cache_path }
        </div>

        <div className='col-md-4'>
          <h4>Created At</h4>
          { moment(list.created_at).format('MM/DD/YYYY') }

          <h4 className='mt-4'>Updated At</h4>
          { moment(list.updated_at).format('MM/DD/YYYY') }

          { list.tags ?
            renderListTags() : null }
        </div>
      </div>
    </div>
  )
}

export default ListDetails;