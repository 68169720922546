import React, { useState } from "react";
import KBIDForm from "./action-kbid-form-components/kbid-form";
import axios from "lib/axios-config";
import KBIDWeightingNewColumns from "./action-kbid-form-components/kbid-weighting-new-columns";
import KBIDWeightingNewAnswers from "./action-kbid-form-components/kbid-weighting-new-answers";
import KBIDWeightingQuestionSheet from "./action-kbid-form-components/kbid-weighting-question-sheet";
import KBIDWeightingTemplateNames from "./action-kbid-form-components/kbid-weighting-template-names";
import KBIDWeightingWeights from "./action-kbid-form-components/kbid-weighting-weights";

const StepComponents = [
  {
    id: "step-kbids",
    Component: KBIDForm,
  },
  {
    id: "step-weighting-new-columns",
    Component: KBIDWeightingNewColumns,
  },
  {
    id: "step-weighting-new-answers",
    Component: KBIDWeightingNewAnswers,
  },
  {
    id: "step-weighting-question-sheet",
    Component: KBIDWeightingQuestionSheet,
  },
  {
    id: "step-weighting-weights",
    Component: KBIDWeightingWeights,
  },
  {
    id: "step-weighting-template-names",
    Component: KBIDWeightingTemplateNames,
  },
];

function ActionKBIDWeighting(props) {
  const { action, mode = "edit" } = props;
  const { options = [] } = action;
  const pendingNextStep = StepComponents.findIndex(
    (step) => options[step.id] == null
  );
  const stepToShow = pendingNextStep === -1 ? 0 : pendingNextStep;
  const [stepIdx, setStepIdx] = useState(stepToShow);

  if (StepComponents[stepIdx] == null) return <div>Unknown Step</div>;

  const { id, Component: StepComponent } = StepComponents[stepIdx];

  const rebuildStep = () => {
    const resetData = StepComponents.reduce((acc, step, idx) => {
      if (idx >= stepIdx) {
        acc[step.id] = null;
      }
      return acc;
    }, {});

    const actionData = { ...action };

    actionData.options = {
      ...actionData.options,
      ...resetData,
    };

    // :update action
    return axios
      .put(`/pipeline/actions/${actionData.id}`, {
        action_params: actionData,
      })
      .then(() => {
        window.location.reload();
      });
  };

  return (
    <StepComponent
      {...props}
      id={id}
      stepIdx={stepIdx}
      onSuccess={() => setStepIdx(stepIdx + 1)}
      goToStep={(idx) => setStepIdx(idx)}
      disabled={
        (stepIdx === 0 && pendingNextStep !== 0) ||
        mode === "read-only" ||
        props.disabled
      }
      mode={mode}
      rebuildStep={rebuildStep}
    />
  );
}

export default ActionKBIDWeighting;
