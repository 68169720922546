import React from 'react'
import { applyMiddleware, compose, createStore } from 'redux'
import reduxWebsocket from '@giantmachines/redux-websocket';

import thunk from 'redux-thunk'
import makeRootReducer, { history } from 'lib/reducers'

const configureStore = (initialState = {}) => {
  const middlewares = [thunk]
  const reduxWebsocketMiddleware = reduxWebsocket();

  middlewares.push(reduxWebsocketMiddleware)

  const enhancers = []
  let composeEnhancers = compose
  
  const store = createStore(
    makeRootReducer(),
    initialState,
    composeEnhancers(
      applyMiddleware(...middlewares),
      ...enhancers
    )
  )

  return store
}

export const StoreContext = React.createContext();

export default configureStore