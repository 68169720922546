import React from 'react';
import moment from 'moment'
import pretty from 'pretty-ms'
import { titleCase } from 'lib/string'

const ListEventLogs = (props) => {
  const { list } = props;

  return (
    <div className='resource-table-wrapper event-logs'>
      <table className='table mb-4'>
        <thead>
          <tr>
            <th>User</th>
            <th>Action</th>
            <th>Status</th>
            <th>Duration</th>
            <th>Details</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        
        <tbody>
          { list.events.map((event, index) => {
            const time = moment(event.created_at)
            
            return (
              <tr>
                <td className={event.status || 'info'}>
                  <h6 className='font-weight-bolder mb-0'>{ `${event.user.first_name} ${event.user.last_name}` }</h6>
                  { event.user.email }
                </td>
                <td>{ event.action }</td>
                <td>{ titleCase(event.status) }</td>
                <td>
                  { event.duration ? pretty(event.duration * 1000, {verbose: false}) : null }
                </td>
                <td>
                  { event.messages.map((message, index) => {
                    return (<p className='mt-0 mb-2'>{ message }</p>)
                  }) }
                </td>
                <td>
                  <h6 className='font-weight mb-0'>{ time.format('MM/DD/YYYY') }</h6>
                  { time.format("h:mma")}
                </td>
              </tr>
            )
          }) }

          <tr>
            <td colSpan={6}>
              <span className='float-right'>
                <a className='btn btn-primary' href={`/lists/${list.id}/export_event_logs`} target="_blank">
                  Export Event Logs
                </a> 
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ListEventLogs;