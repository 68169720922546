import axios from "lib/axios-config";
import { toasterCacheSet } from "lib/toaster-cache";
import { chunk, isEqual, keys, startCase, map } from "lodash";
import get from "lodash/get";
import React, { Component } from "react";
import ActionRun from "./kbid-action-run";
import KbidContentLoader from "./kbid-content-loader";
import TextareaAutosize from "react-textarea-autosize";
import WeightingDownloadReportBtn from "./kbid-weighting-download-report-btn";

const cellStyle = {
  maxWidth: "420px",
  minWidth: "420px",
  width: "420px",
  wordWrap: "break-word",
  padding: "16px 10px",
};

const inputStyle = {
  padding: "4px 12px",
  fontSize: "1em",
  resize: "none",
};

const INITIAL_STATE = {
  lastId: -1,
  template: {},
  templateOriginal: {},
  isLoading: true,
  isSubmitting: false,
};

class KBIDWeightingTemplateNames extends Component {
  constructor(props) {
    super(props);
    this.goToNext = this.goToNext.bind(this);
    this.fetchInitialData = this.fetchInitialData.bind(this);
    this.saveAction = this.saveAction.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.rebuildStep = this.rebuildStep.bind(this);

    const kbids = get(
      props.action,
      ["options", "step-kbids", "form", "kbids"],
      [""]
    );

    this.state = { ...INITIAL_STATE, kbids };
  }

  componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData() {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/weighting`;
    const templateReq = axios.get(uri, {
      params: { type: "template_names" },
    });

    return Promise.all([templateReq]).then(([templateRes]) => {
      this.setState((prev) => ({
        ...prev,
        ...INITIAL_STATE,
        template: templateRes.data,
        templateOriginal: templateRes.data,
        isLoading: false,
      }));
    });
  }

  handleUpdate(path, value) {
    this.setState((prev) => ({
      ...prev,
      template: { ...prev.template, [path]: value },
    }));
  }

  saveAction() {
    const { action, id } = this.props;
    action.status = "draft";
    action.options = {
      ...action.options,
      [id]: "done",
    };

    // :update action
    return axios.put(`/pipeline/actions/${action.id}`, {
      action_params: action,
    });
  }

  submitForm(submitData) {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/weighting`;

    return axios
      .post(uri, { type: "template_names", data: submitData })
      .then((res) => res.data);
  }

  goToNext() {
    const { template, templateOriginal } = this.state;
    const { goToStep, stepIdx, action, id, mode } = this.props;
    const isUpdated = !isEqual(template, templateOriginal);

    // If re-visiting the step, dont submit data to api. Just go to next step.
    if ((!isUpdated && action.options[id] != null) || mode === "read-only") {
      window.location = "/pipeline/actions";
      return;
    }

    this.setState({ isSubmitting: true }, () => {
      // If no changes were made on initial visit too,
      // dont submit data to api. Just mark as done and go to next step.
      const submitToApi =
        action.options[id] == null && !isUpdated
          ? Promise.resolve()
          : this.submitForm(template);

      submitToApi
        .then(() => this.saveAction())
        .then(() => {
          toasterCacheSet("Action saved successfully", "success");
          window.location = "/pipeline/actions";
        })
        .catch(() => {
          this.setState({ isSubmitting: false });
          toasterCacheSet("Unknown error", "error");
        });
    });
  }

  rebuildStep(type = "template_names") {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/rebuild_weighting`;

    return this.setState({ isLoading: true }, () => {
      axios
        .post(uri, { type })
        .then(() => this.props.rebuildStep())
        .catch(() => {
          this.setState({ isSubmitting: false });
          toasterCacheSet("Unknown error", "error");
        });
    });
  }

  render() {
    const { action, stepIdx, goToStep, disabled } = this.props;
    const { isSubmitting, isLoading, template } = this.state;
    const templateKeys = chunk(keys(template), 2);

    return (
      <div>
        <h4 className="text-center mt-4">Step 6 - Template Names</h4>
        <div>
          {isLoading ? (
            <KbidContentLoader />
          ) : (
            <table>
              <tbody>
                {map(templateKeys, (rowKeys) => (
                  <tr key={rowKeys.join("")}>
                    {rowKeys.map((key) => (
                      <td style={cellStyle} key={key}>
                        <h6>{startCase(key)}</h6>
                        <TextareaAutosize
                          value={template[key]}
                          className="form-control"
                          style={inputStyle}
                          onChange={(e) =>
                            this.handleUpdate(key, e.target.value)
                          }
                          disabled={disabled}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <ActionRun
          action={action}
          displayDeleteModal={this.props.displayDeleteModal}
          goToNext={this.goToNext}
          goToPrevious={() => goToStep(stepIdx - 1)}
          isSubmitting={isSubmitting}
          rebuildStep={this.rebuildStep}
          hasRebuildDropdown
          stepDisabled={disabled}
          renderCustomAction={() => (
            <WeightingDownloadReportBtn action={action} />
          )}
        />
      </div>
    );
  }
}

export default KBIDWeightingTemplateNames;
