import axios from "lib/axios-config";
import { toasterCacheSet } from "lib/toaster-cache";
import cloneDeep from "lodash/cloneDeep";
import flatten from "lodash/flatten";
import flattenDeep from "lodash/flattenDeep";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import set from "lodash/set";
import some from "lodash/some";
import isEmpty from "lodash/isEmpty";
import partition from "lodash/partition";
import values from "lodash/values";
import React, { Component } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import ActionRun from "./kbid-action-run";
import SectionForm from "./kbid-common-section-form";
import KbidContentLoader from "./kbid-content-loader";
import TextareaAutosize from "react-textarea-autosize";

const cellStyle = {
  maxWidth: "420px",
  minWidth: "420px",
  width: "420px",
  wordWrap: "break-word",
  padding: "16px 10px",
};

const inputStyle = {
  padding: "4px",
  fontSize: "1em",
  resize: "none",
};

const verifiedCellStyle = {
  width: "50px",
  minWidth: "50px",
  maxWidth: "50px",
  padding: "0px 8px",
};

const clickable = {
  cursor: "pointer",
};

function Row(props) {
  const { record, onInputChange, onVerifiedToggle, onOptionChange, disabled } =
    props;

  return (
    <tr>
      <td style={cellStyle}>{record.old_column_name}</td>
      <td style={cellStyle}>
        <DropdownButton
          id="dropdown-basic-button"
          className="mr-2"
          title={record.action || "Select Question"}
          size="md"
          variant="light"
          onSelect={onOptionChange}
        >
          <Dropdown.Item
            eventKey="RENAME"
            active={record.action === "RENAME"}
            disabled={disabled}
          >
            RENAME
          </Dropdown.Item>
          <Dropdown.Item
            eventKey={record.new_column_name}
            active={record.action === record.new_column_name}
            disabled={disabled}
          >
            {record.new_column_name}
          </Dropdown.Item>
        </DropdownButton>
        {record.action === "RENAME" && (
          <>
            <TextareaAutosize
              className={`form-control mt-3 ${
                record.error ? "border-danger" : ""
              }`}
              style={inputStyle}
              rows={1}
              value={record.updated_new_column_name}
              onChange={(e) => onInputChange(e.target.value)}
              disabled={disabled}
            />
            {record.error && (
              <span className="text-danger">Value already in use.</span>
            )}
          </>
        )}
      </td>
      <td style={verifiedCellStyle}>
        <input
          className="mr-1"
          type="checkbox"
          style={clickable}
          checked={record.verified}
          onChange={() => onVerifiedToggle(!record.verified)}
        />
      </td>
    </tr>
  );
}

function HeaderRow(props) {
  const { verifiedVisible, toggleVerifiedVisibility } = props;
  return (
    <tr>
      <th>Original</th>
      <th>Rename</th>
      <th style={verifiedCellStyle}>
        <i
          className={verifiedVisible ? "fas fa-eye-slash" : "fas fa-eye"}
          onClick={toggleVerifiedVisibility}
          style={clickable}
        />
      </th>
    </tr>
  );
}

class KBIDHeaderForm extends Component {
  constructor(props) {
    super(props);
    this.goToNext = this.goToNext.bind(this);
    this.saveAction = this.saveAction.bind(this);
    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.rebuildStep = this.rebuildStep.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.toggleSectionVisibility = this.toggleSectionVisibility.bind(this);
    this.toggleOverallVisibility = this.toggleOverallVisibility.bind(this);

    const kbids = get(
      props.action,
      ["options", "step-kbids", "form", "kbids"],
      [""]
    );

    this.state = {
      kbids,
      demos: [],
      overallVisibilityState: "contract",
      sectionVisibility: {},
      questions: {},
      filenames: [],
      newColumnNames: {},
      hasError: false,
      isLoading: true,
      isSubmitting: false,
    };
  }

  componentDidMount() {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/header_keys`;

    return axios.get(uri).then((res) => {
      const filenames = res.data.filenames;
      const questions = (res.data.questions || []).map((q, qIdx) => {
        return filenames.map((filename, idx) => ({
          new_column_name: q.new_column_name,
          old_column_name: q.old_column_names[idx],
          action: q.new_column_name, // or RENAME
          filename,
          verified: false,
          updated: false,
          error: false,
          updated_new_column_name: q.new_column_name,
          questionId: qIdx,
        }));
      });

      let sectionVisibility = {};
      filenames.forEach((fn) => {
        sectionVisibility[fn] = this.state.overallVisibilityState === "expand";
      });

      // filter records that have empty original column
      const filteredQuestions = flatten(questions).filter(
        (q) => !isEmpty(q.old_column_name)
      );

      // Create an index to keep track whether a value is duplicated
      // A value can't have duplicates within same filename
      const newColumnNames = (res.data.questions || []).reduce((acc, q) => {
        acc[`${q.new_column_name}-${q.filename}`] = true;
        return acc;
      }, {});

      this.setState({
        filenames,
        sectionVisibility,
        newColumnNames,
        questions: groupBy(filteredQuestions, "filename"),
        demos: res.data.demos,
        isLoading: false,
      });
    });
  }

  toggleSectionVisibility(id) {
    const updated = set(
      cloneDeep(this.state.sectionVisibility),
      id,
      !this.state.sectionVisibility[id]
    );
    this.setState({ sectionVisibility: updated });
  }

  toggleOverallVisibility() {
    this.setState((prevState) => {
      const newVisibilityState =
        prevState.overallVisibilityState === "expand" ? "contract" : "expand";
      let sectionVisibility = {};
      prevState.filenames.forEach((fn) => {
        sectionVisibility[fn] = newVisibilityState === "expand";
      });
      return {
        ...prevState,
        overallVisibilityState: newVisibilityState,
        sectionVisibility,
      };
    });
  }

  handleRowUpdate(filename, idx, path, value) {
    const { newColumnNames } = this.state;
    const cloned = cloneDeep(this.state.questions);

    // if value for one filename is updated, update it for all filenames
    set(cloned, `${filename}.${idx}.${path}`, value);
    set(cloned, `${filename}.${idx}.updated`, true);

    if (path === "updated_new_column_name") {
      const initialValue = get(cloned, `${filename}.${idx}.new_column_name`);

      // dont error if user reverts back to the original value
      const resetToInitial = initialValue === value;

      const valueExistsAlready =
        newColumnNames[`${value}-${filename}`] === true;
      set(
        cloned,
        `${filename}.${idx}.error`,
        !resetToInitial && valueExistsAlready
      );
    }

    const records = flatten(values(cloned));
    const hasError = some(records, (rec) => rec.error);

    this.setState({ questions: cloned, hasError });
  }

  saveAction() {
    const { action, id } = this.props;
    const { filenames } = this.state;
    action.status = "draft";
    action.options = {
      ...action.options,
      [id]: {
        response: { filenames },
      },
    };

    // :update action
    return axios.put(`/pipeline/actions/${action.id}`, {
      action_params: action,
    });
  }

  submitForm(submitData) {
    const { action } = this.props;
    const { filenames, demos } = this.state;
    const uri = `/pipeline/actions/${action.id}/kbid/header_keys`;

    const data = {
      filenames,
      questions: submitData,
      demos,
    };

    return axios.post(uri, data).then((res) => res.data);
  }

  goToNext() {
    const { questions, filenames } = this.state;
    const records = flatten(values(questions));
    const { goToStep, stepIdx, action, id, mode } = this.props;
    const isUpdated = records.some((q) => q.updated);

    // If re-visiting the step, dont submit data to api. Just go to next step.
    if ((!isUpdated && action.options[id] != null) || mode === "read-only") {
      goToStep(stepIdx + 1);
      return;
    }

    if (records.length < 1) return;

    const renameValueMap = records.reduce((acc, q) => {
      if (q.action === "RENAME") {
        acc[q.updated_new_column_name] = true;
      }
      return acc;
    }, {});
    const groupedById = values(groupBy(records, "questionId"));
    const [groupsWithRenames, groupsWithNoChanges] = partition(
      groupedById,
      (qGroup) =>
        some(
          qGroup,
          (q) =>
            q.action === "RENAME" || renameValueMap[q.updated_new_column_name]
        )
    );
    const recordsWithNoChanges = map(groupsWithNoChanges, (qGroup) => {
      const old_column_names = filenames.map((filename) => {
        const question = qGroup.find((q) => q.filename === filename);
        return question?.old_column_name || "";
      });

      return {
        old_column_names,
        new_column_name: qGroup[0].updated_new_column_name,
      };
    });

    const groupedByValue = groupBy(
      flattenDeep(groupsWithRenames),
      "updated_new_column_name"
    );
    const recordsWithRenames = map(groupedByValue, (qGroup) => {
      const old_column_names = filenames.map((filename) => {
        const question = qGroup.find((q) => q.filename === filename);
        return question?.old_column_name || "";
      });

      return {
        old_column_names,
        new_column_name: qGroup[0].updated_new_column_name,
      };
    });

    const submitData = flattenDeep([recordsWithRenames, recordsWithNoChanges]);

    this.setState({ isSubmitting: true }, () => {
      // If no changes were made on initial visit too,
      // dont submit data to api. Just mark as done and go to next step.
      const submitToApi =
        action.options[id] == null && !isUpdated
          ? Promise.resolve()
          : this.submitForm(submitData);

      submitToApi
        .then(() => this.saveAction())
        .then(() => goToStep(stepIdx + 1))
        .catch(() => {
          this.setState({ isSubmitting: false });
          toasterCacheSet("Unknown error", "error");
        });
    });
  }

  rebuildStep() {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/rebuild_headers`;

    return this.setState({ isLoading: true }, () => {
      axios
        .post(uri)
        .then(() => this.props.rebuildStep())
        .catch(() => {
          this.setState({ isSubmitting: false });
          toasterCacheSet("Unknown error", "error");
        });
    });
  }

  renderRow({ record, index, id }) {
    return (
      <Row
        record={record}
        onOptionChange={(value) => {
          this.handleRowUpdate(id, index, "action", value);
        }}
        onInputChange={(value) =>
          this.handleRowUpdate(id, index, "updated_new_column_name", value)
        }
        onVerifiedToggle={(verified) =>
          this.handleRowUpdate(id, index, "verified", verified)
        }
        disabled={this.props.mode === "read-only"}
      />
    );
  }

  render() {
    const { action, stepIdx, goToStep, disabled } = this.props;
    const {
      questions,
      isSubmitting,
      isLoading,
      overallVisibilityState,
      sectionVisibility,
      hasError,
    } = this.state;

    return (
      <div>
        <h4 className="text-center mt-4">Step 3 - Question Headers</h4>
        <div className="row justify-content-end">
          <button
            className="btn btn-sm btn-light mb-2 mr-2"
            onClick={(e) => {
              e.preventDefault();
              this.toggleOverallVisibility();
            }}
          >
            {overallVisibilityState === "expand"
              ? "Collapse All"
              : "Expand All"}
          </button>
        </div>
        <div className="overflow-auto">
          {isLoading ? (
            <KbidContentLoader />
          ) : (
            map(questions, (records, filename) =>
              records?.length > 0 ? (
                <SectionForm
                  key={filename}
                  id={filename}
                  records={records}
                  renderHeaderRow={HeaderRow}
                  Row={this.renderRow}
                  isVisible={sectionVisibility[filename]}
                  toggleVisibility={() =>
                    this.toggleSectionVisibility(filename)
                  }
                />
              ) : null
            )
          )}
        </div>
        <ActionRun
          action={action}
          displayDeleteModal={this.props.displayDeleteModal}
          goToNext={this.goToNext}
          goToPrevious={() => goToStep(stepIdx - 1)}
          isSubmitting={isSubmitting}
          isSubmitDisabled={hasError}
          rebuildStep={this.rebuildStep}
          stepDisabled={disabled}
        />
      </div>
    );
  }
}

export default KBIDHeaderForm;
