import React from 'react';
import VersionString from './version-string'
import sortBy from 'lodash/sortBy'

export default class VersionPicker extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { poll, versions, isMobile } = this.props;

    let sortedVersions = sortBy(versions, 'version')

    return (
      <ul className="list-inline">
        {
          sortedVersions.map((version, index) => {
            return (
              <li className="list-inline-item" key={`v-${version.id}-${index}`}>
                <button className='btn btn-sm btn-muted mr-2' onClick={(e) => {
                  e.preventDefault();
                  this.props.selectVersion(poll, version);
                }}>
                  {
                    version.id === this.props.currentVersion.id ?
                      <strong><VersionString version={version} /></strong> :
                      <VersionString version={version} />
                  } 
                </button>
              </li>
            )
          })
        }

        { !isMobile && 
          <li className='list-inline-item'>
            <a 
              href={`/polls/${poll.id}/versions/new`} 
              className='btn btn-sm btn-light'
            >
              New Version
            </a>
          </li>
        }
      </ul>
    );
  }
}
