import React from 'react';

import ResourceContainer from '../ui/resource-container'
import ResourceHeader from '../ui/resource-header'

import ListRow from './list-row'
import ListHeader from './list-header'
import PaginationDropdown from '../pagination/pagination-dropdown'
import PaginationSelector from '../pagination/pagination-selector'

import axios from 'lib/axios-config'
import eventBus from 'lib/event-bus'
import withToaster from '../common/toaster-wrapper'

class ListsIndex extends React.Component {
  constructor(props) {
    super(props);

    this.toggleFilterSearch = this.toggleFilterSearch.bind(this)
    this.fetchLists = this.fetchLists.bind(this)
    this.changePerPage = this.changePerPage.bind(this)
    this.changePage = this.changePage.bind(this)

    this.resource = 'List'
    this.filterOptions = [{
          label: 'Status',
          name: 'status',
          defaultValue: 'all',
          options: [{
            value: 'all',
            label: 'All'
          }, {
            value: 'initialized',
            label: 'Initialized'
          }, {
            value: 'processing',
            label: 'Processing'
          }, {
            value: 'success',
            label: 'Success'
          }, {
            value: 'error',
            label: 'Error'
          }]
        }, {
          label: 'Node',
          name: 'node',
          defaultValue: 'all',
          options: [{
            value: 'all',
            label: 'All'
          }, {
            value: 'parent',
            label: 'Parent'
          }, {
            value: 'child',
            label: 'Child'
          }]
        }]

    this.state = {
      filterSearchVisible: false,
      query: '',
      page: 1,
      perPage: 15,
      total: 0,
      lists: [],
      q: '',
      filterValues: {
        status: 'all',
        node: 'all'
      }
    }
  }

  changePage(page) {
    const newPage = page.selected + 1;
    this.setState({
      page: newPage
    });

    this.fetchLists({
      perPage: this.state.perPage, 
      q: this.state.q, 
      page: newPage,
      filterValues: this.state.filterValues,
    })
  }

  changePerPage(count) {
    this.setState({
      perPage: count,
      page: 1,
    })

    this.fetchLists({
      perPage: count,  
      page: 1, 
      q: this.state.q, 
      filterValues: this.state.filterValues
    })
  }

  fetchLists(options) {
    axios.get('/lists', {
      params: {
        q: options.q,
        per_page: options.perPage,
        page: options.page,
        ...options.filterValues
      }
    })
      .then((response) => {
        this.setState({
          lists: response.data.lists,
          total: response.data.pagination.total
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  componentDidMount() {
    this.fetchLists(this.state)

    eventBus.on('ListSearch', (data) => {
      this.setState({
        q: data.q,
        page: 1,
        filterValues: data.filterValues,
      })

      this.fetchLists({
        q: data.q, 
        page: 1, 
        perPage: this.state.perPage, 
        filterValues: data.filterValues 
      })
    })
  }

  componentWillUnmount() {
    eventBus.remove('ListSearch')
  }

  toggleFilterSearch(e) {
    e.preventDefault()

    if(this.state.filterSearchVisible) {
      eventBus.dispatch('FilterSearchToggleOff')
    } else {
      eventBus.dispatch('FilterSearchToggleOn', { 
        resource: this.resource,
        filters: this.filterOptions 
      })
    }

    this.setState({filterSearchVisible: !this.state.filterSearchVisible})
  }

  render() {
    return (
      <ResourceContainer>
        <ResourceHeader title='Lists' description='Manage your lists here' col={6}>
          <div className='float-right'>
            <button className='btn btn-dark btn-md' onClick={this.toggleFilterSearch}>
              <span className='fas fa-filter mr-2'/>
              Filter
            </button>
            <a href='/lists/new' className='btn btn-primary btn-md ml-3'>
              <span className='fas fa-plus mr-2'/>
              New List
            </a>
          </div>
        </ResourceHeader>

        <div className='resource-body mt-2'>
          <div className='resource-table'>
            <table className='table'>
              <ListHeader /> 
              
              <tbody>
                {
                  this.state.lists.map((list, index) => {
                    return (
                      <ListRow key={`list-row-${index}`} index={index} list={list} /> 
                    )
                  })
                }
                <tr>
                  <td colSpan={6}>
                    <PaginationDropdown
                      perPage={this.state.perPage}
                      changePerPage={this.changePerPage}
                    />
                  </td>

                  <td colSpan={2}>
                    <span className='float-right'>
                      { this.state.total > 0 ? 
                          <PaginationSelector
                            page={this.state.page}
                            perPage={this.state.perPage}
                            changePage={this.changePage}
                            total={this.state.total}
                          />
                        : null
                      }
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ResourceContainer>
    );
  }
}

export default withToaster(ListsIndex) 