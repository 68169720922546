import React from 'react';
import VersionString from './version-string'
import moment from 'moment'

import Select from 'react-select'

export default class VersionInfo extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this)
  }

  getListClass(column, currentQuestion) {
    if(column === currentQuestion) {
      return 'active list-group-item'
    }  else {
      return 'list-group-item'
    }
  }

  handleSelect(option) {
    this.props.selectQuestion(option.value)
  }

  render() {
    const { version, mappings, columns, currentQuestion } = this.props;
    if(!version) return null;

    let options = columns.map((column, index) => {
      return {
        value: column, label: mappings[column]
      }
    })

    return (
      <div className='d-flex'>
        <div className='form-group'>
          <h5><strong>Version</strong></h5>
          <VersionString version={version} />
        </div>

        <div className='ml-auto'>

        </div>

        <div className='form-group'>
          <h5 className='mt-4'><strong>Import Date</strong></h5>
          <h6>{moment(version.created_at).format("MM/DD/YYYY")}</h6>
        </div>{}

        <div className='d-flex mt-4 mb-2'>
          <h5 className='my-0'><strong>Questions</strong></h5>
          <span className='ml-2 badge badge-pill badge-light'>
            {columns.length}
          </span>
        </div>
      </div>
    );
  }
}
