import React from 'react';

import ActionColumnTags from './action-column-tags'

const ActionFilterSettings = (props) => {
  const { action } = props;
  const { options } = action;
  const { results } = action;

  let matched = 0

  switch(options.filter_mode) {
    case 'Remove Blanks':
      if(results && results.hasOwnProperty('matched')) {
        matched = action.source_list.rows - results.matched
      }

      return (
        <div className='row mt-3 text-break'>
          <div className='col-md-4'>
            <h4>Filter Mode</h4>
            { options.filter_mode }
          </div>

          <div className='col-md-4'>
            <h4>Columns</h4>
            <ActionColumnTags
              action={action}
              headerField='headers'
            />
          </div>
          <div className='col-md-4'>
            <h4>Matched</h4>
            { matched.toLocaleString('en-US') }
          </div>
        </div>
      );
    case 'Match Value':
      if(results && results.hasOwnProperty('matched')) {
        matched = results.matched
      }

      return (
        <div className='row mt-3 text-break'>
          <div className='col-md-4'>
            <h4>Filter Mode</h4>
            { options.filter_mode }
          </div>

          <div className='col-md-4'>
            <h4>Filter Columns</h4>
            <ActionColumnTags
              action={action}
              headerField='headers'
            />
          </div>
          <div className='col-md-4'>
            <h4>Filter</h4>
            { action.options.operator } { action.options.filter_value }
          </div>
        </div>
      )
    case 'Comparison':
      if(results && results.hasOwnProperty('matched')) {
        matched = results.matched
      }

      return (
        <div className='row mt-3 text-break'>
          <div className='col-md-4'>
            <h4>Filter Mode</h4>
            { options.filter_mode }
          </div>

          <div className='col-md-4'>
            <h4>Filter Columns</h4>
            <ActionColumnTags
              action={action}
              headerField='headers'
            />
          </div>
          <div className='col-md-4'>
            <h4>Filter</h4>
            { action.options.operator } { action.options.filter_value }
          </div>
        </div>
      )
    case 'Lookup':
      if(results && results.hasOwnProperty('matched')) {
        matched = results.matched
      }

      return (
        <div className='row mt-3 text-break'>
          <div className='col-md-4'>
            <h4>Filter Mode</h4>
            { options.filter_mode }
          </div>

          <div className='col-md-4'>
            <h4>Filter Columns</h4>
            <ActionColumnTags
              action={action}
              headerField='headers'
            />
          </div>

          <div className='col-md-4'>
            <h4>Lookup Columns</h4>
            <ActionColumnTags
              action={action}
              headerField='lookup_headers'
            />
          </div>
        </div>
      )
    default:
      return null;
  }
}

export default ActionFilterSettings;
