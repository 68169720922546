import React from 'react';
import ReactPaginate from 'react-paginate';

const PaginationSelector = (props) => {
  const { page, perPage, total } = props;

  const currentPage = page; 
  let boundaryRight = perPage >= total || page >= total ? total :  parseInt(perPage) * parseInt(currentPage);
  boundaryRight = boundaryRight >= total ? total : boundaryRight;

  const boundaryLeft = perPage >= total || page == total ? currentPage : ((parseInt(currentPage) - 1) * parseInt(perPage)) + 1;

  const pageInfo = `${boundaryLeft}-${boundaryRight} of ${total}`

  let selectors = null;
  const pageCount = Math.ceil(total / perPage)

  return (
    <div>
      <span className='font-weigth-bolder mr-3'>
        { pageInfo }
      </span>

      { perPage < total ?
        <span className='d-inline-block'>
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'btn btn-sm btn-light'}
            pageLinkClassName={'btn btn-light btn-sm'}
            nextLinkClassName={'btn btn-light btn-sm'}
            marginPagesDisplayed={2}
            previousLinkClassName={'btn btn-light btn-sm'}
            pageCount={pageCount}
            pageRangeDisplayed={0}
            onPageChange={props.changePage}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </span>
        : null
      }
    </div>
  )
}

export default PaginationSelector;
