import React from 'react';
import ListSelector from '../../../lists/list-selector'

export default class OccurenceListSelector extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { lists, list } = this.props;

    return (
      <ListSelector
        lists={lists}
        title={'Select a list'}
        selectListId={this.props.selectListId}
      />
    );
  }
}
