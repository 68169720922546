import React from 'react';

import { DropdownButton, Dropdown, FormControl } from 'react-bootstrap'
import ActionRun from '../action-run'
import ActionListColumnsSelector from '../action-list-columns-selector'
import ActionListExportOptions from '../action-list-export-options'

import ActionListSelector from '../action-list-selector'

export default class ActionDedup extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { action, lists, selectedList } = this.props;

    return (
      <div>
        <ActionListSelector
          action={action}
          lists={lists}
          list={selectedList}
          title="Lists"
          selectListId={this.props.selectListId}
        />

        <ActionListColumnsSelector
          setActionOptionValue={this.props.setActionOptionValue}
          setActionOptionHash={this.props.setActionOptionHash}
          title="Select Columns To Process"
          headerField='headers'
          action={action} 
          list={selectedList}
        /> 

        {
          selectedList ?
            <ActionListExportOptions
              selectedList={selectedList}
              setActionOptionValue={this.props.setActionOptionValue}
              setActionOptionValues={this.props.setActionOptionValues}
              setActionOptionHash={this.props.setActionOptionHash}
              action={action}
            /> : null
        }

        { selectedList ? 
            <ActionRun
              saveAsDraft={this.props.saveAsDraft}
              runAction={this.props.runAction}
              cloneAction={this.props.cloneAction}
              displayDeleteModal={this.props.displayDeleteModal}
              action={action}
            /> : null
        }
      </div>
    );
  }
}
