import React from 'react';
import { connect } from 'react-redux'
import withProvider from '../common/provider-wrapper'

import ResourceContainer from '../ui/resource-container'
import ResourceHeader from '../ui/resource-header'
import ResourceDeleteModal from '../ui/resource-delete-modal'
import ListShowNav from './list-show-nav'

import ListDetails from './list-details'
import ListEventLogs from './list-event-logs'
import ListRandomEntries from './list-random-entries'
import ListOccurences from './list-occurences'

import { toasterCacheSet} from 'lib/toaster-cache'
import { DropdownButton, Dropdown } from 'react-bootstrap'

import axios from 'lib/axios-config'

import 'css/lists'

export default class ListsShow extends React.Component {
  constructor(props) {
    super(props);

    this.changeNavItem = this.changeNavItem.bind(this)
    this.displayDeleteModal = this.displayDeleteModal.bind(this)
    this.hideDeleteModal = this.hideDeleteModal.bind(this)
    this.handleDeleteList = this.handleDeleteList.bind(this)
    this.fetchList = this.fetchList.bind(this)
    this.createAction = this.createAction.bind(this)

    this.renderDeleteModal = this.renderDeleteModal.bind(this)

    this.state = {
      currentNavItem: 'Details',
      deleteModalIsVisible: false,
      list: null,
    }
  }

  createAction(id) {
    const action = {
      source_list_id: this.state.list.id,
      status: 'draft',
      action_type_id: parseInt(id),
      options: {},
      name: this.state.list.name,
    }

    axios.post('/pipeline/actions', {action_params: action})
      .then((response) => {
        toasterCacheSet(response.data.message, 'success')
        window.open(`${window.location.origin}/pipeline/actions/${response.data.action.id}/edit`)
      })
  }

  hideDeleteModal() {
    this.setState({deleteModalIsVisible: false})
  }

  displayDeleteModal(e) {
    e.preventDefault();

    this.setState({
      deleteModalIsVisible: true,
    })
  }

  componentDidMount() {
    this.setState({
      list: this.props.list
    })
  }

  fetchList() {
    const { list } = this.state;
    axios.get(`/lists/${list.id}`)
      .then((resource) => {
        this.setState({
          list: resource.data
        })
      })
  }

  handleDeleteList() {
    const { list } = this.state;

    axios.delete(`/lists/${list.id}`)
      .then((response) => {
        this.setState({deleteModalIsVisible: false})
        toasterCacheSet(`List ${list.name} deleted.`, 'warning')

        window.location = '/lists'
      })
      .error((error) => {

      })
  }

  renderDeleteModal() {
    if(!this.state.deleteModalIsVisible) return null;

    const { list } = this.state;

    return (
      <ResourceDeleteModal
        resource={list}
        closeModal={this.hideDeleteModal}
        resourceName={'List'} 
        url={'/list'}
        visible={this.state.deleteModalIsVisible}
        handleDelete={this.handleDeleteList}
      >
        <p>Are you sure you want to delete this list?</p>
      </ResourceDeleteModal>
    )
  }

  changeNavItem(item) {
    this.setState({currentNavItem: item})
    this.fetchList()
  }

  renderNavSection() {
    const { currentNavItem } = this.state;
    const { list } = this.state;

    switch(currentNavItem) {
      case 'Details':
        return (
          <ListDetails
            list={list}
          />
        )
      case 'Event Logs':
        return (
          <ListEventLogs
            list={list}
          />
        )
      case 'Random Entries':
        return (
          <ListRandomEntries
            list={list}
            fetchList={this.fetchList}
          />
        )
      case 'Occurences':
        return (
          <ListOccurences
            list={list}
            fetchList={this.fetchList}
          />
        )
      default:
        return null;
    }
  }

  render() {
    const { list } = this.state;
    const { actionTypes } = this.props;

    if(!list) return null;

    return (
      <ResourceContainer>
        <ResourceHeader title="Lists" description="View details about your list here" />
        { this.renderDeleteModal() }
        <div className='resource-panel mb-5'>
          <div className='resource-body'>
            <div className='row px-4 pt-4 text-break'>
              <div className='col-md-7'>
                <h2 className='font-weight-bolder'>{ list.name }</h2>
              </div>

              <div className='col-md-5'>
                <div className='float-right'>

                  <DropdownButton 
                    id="dropdown-basic-button" 
                    className='d-inline-block mr-3'
                    title={'Run Action '} 
                    size='md'
                    variant='secondary'
                    onSelect={this.createAction}
                  >
                    { actionTypes.map((actionType, index) => {
                        return (
                          <Dropdown.Item 
                            key={`action-type-${index}`}
                            eventKey={actionType.id}>
                            { actionType.name }
                          </Dropdown.Item>
                        )
                    })}
                  </DropdownButton>

                  <button className='btn btn-danger mr-3' onClick={this.displayDeleteModal}>
                    Delete
                  </button>

                  <a href={`/lists/${list.id}/edit`} className='btn btn-light edit-resource-btn'>
                    <i className="fas fa-cog"></i>
                  </a>
                </div>
              </div>
            </div>

            <ListShowNav
              currentNavItem={this.state.currentNavItem}
              changeNavItem={this.changeNavItem}
            />

            { this.renderNavSection() }
          </div>
        </div>
      </ResourceContainer>
    );
  }
}
