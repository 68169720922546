import React from 'react'
import { toasterCacheGet, toasterCacheClear } from 'lib/toaster-cache'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'css/toaster'

function withToaster(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.enqueueToast = this.enqueueToast.bind(this)
    }

    componentDidMount() {
      const result = toasterCacheGet();

      if(result){
        this.enqueueToast(result.message, { type: result.type })
        toasterCacheClear()
      }
    }

    enqueueToast(message, options = {}) {
      options.position = toast.POSITION.BOTTOM_RIGHT;
      options.autoClose = 3500

      toast(message, options)
    }

    render() {
      return (
        <div>
          <WrappedComponent {...this.props} toast={this.enqueueToast} />
          <ToastContainer />
        </div>
      ) 
    }
  }
}

export default withToaster;