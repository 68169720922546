import React from 'react';
import ActionListDetail from './action-list-detail'

const ActionConcatSettings = (props) => {
  const { action } = props;
  const { options } = action;

  const appendList = options.append_list;
  if(!appendList) return;

  const headerMappings = options.header_mappings;

  console.log(options)

  return (
    <div>
      <ActionListDetail
        title="Append List"
        list={appendList}
      />

      <div className='row mt-3 text-break'>
        <div className='col-md-12'>
          <h4>Mappings</h4>

          <ul className='list-group overflow-auto' style={{'height': '400px'}}>
            {
              action.source_list.headers.map((header, index) => {
                return (
                  <li className='list-group-item' key={`list-mapping-${index}`}>
                    <div className='d-flex flex-row'>
                      <div>{ header }</div>
                      <div className='ml-auto'>
                        { 
                          headerMappings.hasOwnProperty(header) ?
                            headerMappings[header].append_header : null 
                        }
                      </div>
                    </div>
                  </li>
                )
              })
            }

            {
              options.append_fields.map((header, index) => {
                return (
                  <li className='list-group-item' key={`list-append-field-${index}`}>
                    <div className='d-flex flex-row'>
                      <div className='ml-auto'>
                        { header }
                      </div>
                    </div>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ActionConcatSettings;