import axios from "lib/axios-config";
import { titleCase } from "lib/string";
import { toasterCacheSet } from "lib/toaster-cache";
import _, { pick } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import get from "lodash/get";
import map from "lodash/map";
import set from "lodash/set";
import mapValues from "lodash/mapValues";
import keyBy from "lodash/keyBy";
import React, { Component, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import ActionRun from "./kbid-action-run";
import SectionForm from "./kbid-common-section-form";
import KbidContentLoader from "./kbid-content-loader";
import WeightingDownloadReportBtn from "./kbid-weighting-download-report-btn";
import { List, arrayMove } from "react-movable";

const cellStyle = {
  maxWidth: "140px",
  minWidth: "140px",
  width: "140px",
  wordWrap: "break-word",
  padding: "16px 10px",
};

const cellStyle2 = {
  maxWidth: "260px",
  minWidth: "260px",
  width: "260px",
  wordWrap: "break-word",
  padding: "16px 10px",
};

const inputStyle = {
  padding: "4px 12px",
  fontSize: "1em",
  resize: "none",
};

const clickable = {
  cursor: "pointer",
};

const headerRowStyle = {
  border: "0px",
};

const lightBorder = {
  borderColor: "lightgray",
};

function PreviewBox({ values }) {
  return (
    <div className="col border rounded" style={lightBorder}>
      {map(values, (value, idx) => (
        <div key={`${value}-${idx}`} className="mt-2 mb-3">
          {value}
        </div>
      ))}
    </div>
  );
}

function PreviewRow(props) {
  const { record } = props;

  return (
    <>
      <tr style={headerRowStyle}>
        <th style={cellStyle} className="font-weight-bold">
          Varname
        </th>
        <th style={cellStyle2} colSpan={2} className="font-weight-bold">
          Question
        </th>
        <th style={cellStyle} className="font-weight-bold">
          Recode
        </th>
        <th style={cellStyle} className="font-weight-bold">
          Order
        </th>
        <th style={cellStyle} className="font-weight-bold">
          Combined
        </th>
        <th style={cellStyle} className="font-weight-bold">
          Horz. Order
        </th>
      </tr>
      <tr style={headerRowStyle}>
        <td style={cellStyle}>{record.varname}</td>
        <td style={cellStyle2} colSpan={2}>
          {record.question}
        </td>
        <td style={cellStyle}>{record.recode}</td>
        <td style={cellStyle}>{record.order}</td>
        <td style={cellStyle}>{record.combined}</td>
        <td style={cellStyle}>{record.horiz_order}</td>
      </tr>
      <tr>
        <th style={cellStyle} className="font-weight-bold">
          Tops
        </th>
        <th style={cellStyle} className="font-weight-bold">
          Horizontal
        </th>
        <th style={cellStyle} className="font-weight-bold">
          Vertical
        </th>
        <th style={cellStyle} className="font-weight-bold">
          Net
        </th>
        <th style={cellStyle} className="font-weight-bold">
          By Case
        </th>
        <th style={cellStyle} className="font-weight-bold">
          Display N
        </th>
        <th style={cellStyle} className="font-weight-bold">
          Filter Blanks
        </th>
      </tr>
      <tr>
        <td style={cellStyle}>{record.tops ? "True" : "False"}</td>
        <td style={cellStyle}>{record.horizontal ? "True" : "False"}</td>
        <td style={cellStyle}>{record.vertical ? "True" : "False"}</td>
        <td style={cellStyle}>{record.net ? "True" : "False"}</td>
        <td style={cellStyle}>{record.by_case ? "True" : "False"}</td>
        <td style={cellStyle}>{record.display_n ? "True" : "False"}</td>
        <td style={cellStyle}>{record.filter_blanks ? "True" : "False"}</td>
      </tr>
    </>
  );
}

function EditingRow(props) {
  const { record, onInputChange: onChange } = props;

  return (
    <>
      <tr style={headerRowStyle}>
        <th style={cellStyle} className="font-weight-bold">
          Varname
        </th>
        <th style={cellStyle2} colSpan={2} className="font-weight-bold">
          Question
        </th>
        <th style={cellStyle} className="font-weight-bold">
          Recode
        </th>
        <th style={cellStyle} className="font-weight-bold">
          Order
        </th>
        <th style={cellStyle} className="font-weight-bold">
          Combined
        </th>
        <th style={cellStyle} className="font-weight-bold">
          Horz. Order
        </th>
      </tr>
      <tr style={headerRowStyle}>
        <td style={cellStyle}>
          <TextareaAutosize
            className="form-control"
            style={inputStyle}
            rows={1}
            value={record.varname ?? ""}
            onChange={(e) => {
              onChange("varname", e.target.value.replace(/(\r\n|\n|\r)/gm, ""));
            }}
          />
        </td>
        <td style={cellStyle2} colSpan={2}>
          <TextareaAutosize
            className="form-control"
            style={inputStyle}
            rows={1}
            value={record.question ?? ""}
            onChange={(e) => onChange("question", e.target.value)}
          />
        </td>
        <td style={cellStyle}>
          <TextareaAutosize
            className="form-control"
            style={inputStyle}
            rows={1}
            value={record.recode ?? ""}
            onChange={(e) => {
              onChange("recode", e.target.value.replace(/(\r\n|\n|\r)/gm, ""));
            }}
          />
        </td>
        <td style={cellStyle}>
          <TextareaAutosize
            className="form-control"
            style={inputStyle}
            rows={1}
            value={record.order ?? ""}
            onChange={(e) => {
              onChange("order", e.target.value.replace(/(\r\n|\n|\r)/gm, ""));
            }}
          />
        </td>
        <td style={cellStyle}>
          <TextareaAutosize
            className="form-control"
            style={inputStyle}
            rows={1}
            value={record.combined ?? ""}
            onChange={(e) => {
              onChange(
                "combined",
                e.target.value.replace(/(\r\n|\n|\r)/gm, "")
              );
            }}
          />
        </td>
        <td style={cellStyle}>
          <input
            className="form-control"
            style={inputStyle}
            type="number"
            value={record.horiz_order ?? ""}
            onChange={(e) => {
              onChange("horiz_order", e.target.value);
            }}
          />
        </td>
      </tr>
      <tr>
        <th style={cellStyle} className="font-weight-bold">
          Tops
        </th>
        <th style={cellStyle} className="font-weight-bold">
          Horizontal
        </th>
        <th style={cellStyle} className="font-weight-bold">
          Vertical
        </th>
        <th style={cellStyle} className="font-weight-bold">
          Net
        </th>
        <th style={cellStyle} className="font-weight-bold">
          By Case
        </th>
        <th style={cellStyle} className="font-weight-bold">
          Display N
        </th>
        <th style={cellStyle} className="font-weight-bold">
          Filter Blanks
        </th>
      </tr>
      <tr>
        <td style={cellStyle}>
          <input
            className="mr-1"
            type="checkbox"
            style={clickable}
            checked={record.tops}
            onChange={(e) => {
              onChange("tops", !record.tops);
            }}
          />
        </td>
        <td style={cellStyle}>
          <input
            className="mr-1"
            type="checkbox"
            style={clickable}
            checked={record.horizontal}
            onChange={(e) => {
              onChange("horizontal", !record.horizontal);
            }}
          />
        </td>
        <td style={cellStyle}>
          <input
            className="mr-1"
            type="checkbox"
            style={clickable}
            checked={record.vertical}
            onChange={(e) => {
              onChange("vertical", !record.vertical);
            }}
          />
        </td>
        <td style={cellStyle}>
          <input
            className="mr-1"
            type="checkbox"
            style={clickable}
            checked={record.net}
            onChange={(e) => {
              onChange("net", !record.net);
            }}
          />
        </td>
        <td style={cellStyle}>
          <input
            className="mr-1"
            type="checkbox"
            style={clickable}
            checked={record.by_case}
            onChange={(e) => {
              onChange("by_case", !record.by_case);
            }}
          />
        </td>
        <td style={cellStyle}>
          <input
            className="mr-1"
            type="checkbox"
            style={clickable}
            checked={record.display_n}
            onChange={(e) => {
              onChange("display_n", !record.display_n);
            }}
          />
        </td>
        <td style={cellStyle}>
          <input
            className="mr-1"
            type="checkbox"
            style={clickable}
            checked={record.filter_blanks}
            onChange={(e) => {
              onChange("filter_blanks", !record.filter_blanks);
            }}
          />
        </td>
      </tr>
    </>
  );
}

function HeaderRow(props) {
  return null;
}

function NoDataView(props) {
  return (
    <div
      className="col justify-content-center"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "40px",
      }}
    >
      <h4 className="text-center mt-4">No Columns</h4>
      <button
        className="btn btn-secondary mt-3 ml-3"
        onClick={(e) => {
          e.preventDefault();
          props.onAddRow();
        }}
      >
        Add Row
      </button>
    </div>
  );
}

function OrderRow(props) {
  const { record, dragProps, isDragged, isSelected } = props;

  return (
    <section
      {...dragProps}
      style={{
        ...dragProps.style,
        cursor: isDragged ? "grabbing" : "grab",
        backgroundColor: isDragged || isSelected ? "#EEE" : "#FFF",
      }}
    >
      <div className="card" style={{ marginBottom: "1.5rem" }}>
        <div className="card-header" style={{ borderBottom: "0px" }}>
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex font-weight-bold">
                {record.new_column_name === "" && record.new
                  ? `New Row ${record.id}`
                  : record.varname}
              </div>
            </div>
            <div className="col-md-6">
              <div className="float-right">
                <button className="btn btn-sm btn-muted mr-2">
                  <i className="fas fa-up-down-left-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function OrderingView(props) {
  return (
    <List
      values={props.records}
      onChange={({ oldIndex, newIndex }) =>
        props.onChange(arrayMove(props.records, oldIndex, newIndex))
      }
      renderList={({ children, props, isDragged }) => (
        <div {...props} style={{ cursor: isDragged ? "grabbing" : undefined }}>
          {children}
        </div>
      )}
      renderItem={({ value, props, isDragged, isSelected }) => (
        <OrderRow
          key={value.id}
          dragProps={props}
          record={value}
          isDragged={isDragged}
          isSelected={isSelected}
        />
      )}
    />
  );
}

const INITIAL_STATE = {
  lastId: -1,
  overallVisibilityState: "contract",
  sectionVisibility: {},
  questionSheets: [],
  orderedSections: [],
  isLoading: true,
  isSubmitting: false,
  interactionMode: "previewing",
};

class KBIDWeightingQuestionSheet extends Component {
  constructor(props) {
    super(props);
    this.goToNext = this.goToNext.bind(this);
    this.fetchInitialData = this.fetchInitialData.bind(this);
    this.saveAction = this.saveAction.bind(this);
    this.handleRowAdd = this.handleRowAdd.bind(this);
    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.rebuildStep = this.rebuildStep.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.toggleSectionVisibility = this.toggleSectionVisibility.bind(this);
    this.toggleOverallVisibility = this.toggleOverallVisibility.bind(this);
    this.toggleOrdering = this.toggleOrdering.bind(this);

    const kbids = get(
      props.action,
      ["options", "step-kbids", "form", "kbids"],
      [""]
    );

    this.state = { ...INITIAL_STATE, kbids };
  }

  componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData() {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/weighting`;
    const questionSheetsRequest = axios.get(uri, {
      params: { type: "question_sheet" },
    });

    return Promise.all([questionSheetsRequest]).then(([questionSheetsRes]) => {
      const questionSheets = map(
        questionSheetsRes.data.questions || [],
        (col, idx) => ({ ...col, deleted: false, id: idx, updated: false })
      );
      let sectionVisibility = {};
      questionSheets.forEach((_key, idx) => {
        sectionVisibility[idx] = this.state.overallVisibilityState === "expand";
      });

      this.setState((prev) => ({
        ...prev,
        ...INITIAL_STATE,
        lastId: questionSheets.length - 1,
        questionSheets,
        sectionVisibility,
        isLoading: false,
      }));
    });
  }

  toggleSectionVisibility(id) {
    const updated = set(
      cloneDeep(this.state.sectionVisibility),
      id,
      !this.state.sectionVisibility[id]
    );
    this.setState({ sectionVisibility: updated });
  }

  toggleOrdering(e) {
    e.preventDefault();
    if (this.state.interactionMode === "ordering") {
      const questionSheetsById = keyBy(this.state.questionSheets, "id");
      const questionSheets = map(this.state.orderedSections, (section, idx) => {
        return {
          ...questionSheetsById[section.id],
          id: idx,
          updated: true,
        };
      });
      this.setState({
        interactionMode: "previewing",
        orderedSections: [],
        questionSheets,
        sectionVisibility: mapValues(this.state.sectionVisibility, () => false),
      });
    } else {
      this.setState({
        interactionMode: "ordering",
        orderedSections: this.state.questionSheets.filter(
          (rec) => !rec.deleted
        ),
      });
    }
  }

  toggleOverallVisibility() {
    this.setState((prevState) => {
      const newVisibilityState =
        prevState.overallVisibilityState === "expand" ? "contract" : "expand";
      let sectionVisibility = {};
      prevState.questionSheets.forEach((_key, idx) => {
        sectionVisibility[idx] = newVisibilityState === "expand";
      });
      return {
        ...prevState,
        overallVisibilityState: newVisibilityState,
        sectionVisibility,
      };
    });
  }

  handleRowUpdate(idx, path, value) {
    const updated = set(
      cloneDeep(this.state.questionSheets),
      `${idx}.${path}`,
      value
    );
    set(updated, `${idx}.updated`, true);
    this.setState({ questionSheets: updated });
  }

  saveAction() {
    const { action, id } = this.props;
    action.status = "draft";
    action.options = {
      ...action.options,
      [id]: "done",
    };

    // :update action
    return axios.put(`/pipeline/actions/${action.id}`, {
      action_params: action,
    });
  }

  handleRowAdd() {
    this.setState((prev) => ({
      ...prev,
      questionSheets: [
        ...prev.questionSheets,
        {
          // actual fields
          varname: "",
          question: "",
          tops: false,
          horiz_order: 1,
          horizontal: false,
          vertical: false,
          recode: "",
          net: false,
          order: "",
          combined: "",
          by_case: false,
          display_n: false,
          filter_blanks: false,
          // custom fields
          deleted: false,
          updated: true,
          id: prev.lastId + 1,
          new: true,
        },
      ],
      lastId: prev.lastId + 1,
      interactionMode: "editing",
      sectionVisibility: { ...prev.sectionVisibility, [prev.lastId + 1]: true },
    }));
  }

  submitForm(submitData) {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/weighting`;

    return axios
      .post(uri, { type: "question_sheet", data: submitData })
      .then((res) => res.data);
  }

  goToNext() {
    const { questionSheets: records } = this.state;
    const { goToStep, stepIdx, action, id, mode } = this.props;
    const isUpdated = records.some((q) => q.updated);

    // If re-visiting the step, dont submit data to api. Just go to next step.
    if ((!isUpdated && action.options[id] != null) || mode === "read-only") {
      goToStep(stepIdx + 1);
      return;
    }

    if (records.length < 1) return;

    const submitData = {
      questions: filter(records, (rec) => !rec.deleted).map((q) =>
        pick(q, [
          "varname",
          "question",
          "tops",
          "horiz_order",
          "horizontal",
          "vertical",
          "recode",
          "net",
          "order",
          "combined",
          "by_case",
          "display_n",
          "filter_blanks",
        ])
      ),
    };

    this.setState({ isSubmitting: true }, () => {
      // If no changes were made on initial visit too,
      // dont submit data to api. Just mark as done and go to next step.
      const submitToApi =
        action.options[id] == null && !isUpdated
          ? Promise.resolve()
          : this.submitForm(submitData);

      submitToApi
        .then(() => this.saveAction())
        .then(() => goToStep(stepIdx + 1))
        .catch(() => {
          this.setState({ isSubmitting: false });
          toasterCacheSet("Unknown error", "error");
        });
    });
  }

  rebuildStep(type = 'question_sheet') {
    const { action } = this.props;
    const uri = `/pipeline/actions/${action.id}/kbid/rebuild_weighting`;

    return this.setState({ isLoading: true }, () => {
      axios
        .post(uri, { type })
        .then(() => this.props.rebuildStep())
        .catch(() => {
          this.setState({ isSubmitting: false });
          toasterCacheSet("Unknown error", "error");
        });
    });
  }

  renderRow({ record, index, id }) {
    const Row =
      this.state.interactionMode !== "editing" ||
      this.props.mode === "read-only"
        ? PreviewRow
        : EditingRow;

    return (
      <Row
        record={record}
        onInputChange={(path, value) =>
          this.handleRowUpdate(record.id, path, value)
        }
        disabled={this.props.mode === "read-only"}
      />
    );
  }

  render() {
    const { action, stepIdx, goToStep, disabled, mode } = this.props;
    const {
      isSubmitting,
      isLoading,
      overallVisibilityState,
      sectionVisibility,
      questionSheets,
    } = this.state;

    const sections = questionSheets.filter((rec) => !rec.deleted);

    return (
      <div>
        <h4 className="text-center mt-4">Step 4 - Question Sheet</h4>
        <div className="row justify-content-end">
          <button
            className="btn btn-sm btn-light mb-2 mr-2"
            onClick={(e) => {
              e.preventDefault();
              this.toggleOverallVisibility();
            }}
          >
            {overallVisibilityState === "expand"
              ? "Collapse All"
              : "Expand All"}
          </button>
          {mode !== "read-only" && (
            <>
              <button
                className="btn btn-sm btn-light mb-2 mr-2"
                onClick={this.toggleOrdering}
              >
                {this.state.interactionMode === "ordering"
                  ? "Done"
                  : "Order Questions"}
              </button>
              {this.state.interactionMode !== "ordering" && (
                <button
                  className="btn btn-sm btn-light mb-2 mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    const nextMode =
                      this.state.interactionMode === "editing"
                        ? "previewing"
                        : "editing";

                    this.setState({
                      interactionMode: nextMode,
                    });
                  }}
                >
                  {titleCase(this.state.interactionMode)}
                </button>
              )}
            </>
          )}
        </div>
        <div>
          {isLoading ? (
            <KbidContentLoader />
          ) : sections.length === 0 ? (
            <NoDataView onAddRow={this.handleRowAdd} />
          ) : this.state.interactionMode === "ordering" ? (
            <OrderingView
              records={this.state.orderedSections}
              onChange={(orderedSections) => this.setState({ orderedSections })}
            />
          ) : (
            map(sections, (record, idx) => (
              <SectionForm
                key={`${record.id}-${idx}`}
                rowKey={record.id}
                id={
                  record.new_column_name === "" && record.new
                    ? `New Row ${record.id}`
                    : record.varname
                }
                records={[record]}
                renderHeaderRow={HeaderRow}
                Row={this.renderRow}
                showVerified={false}
                isVisible={sectionVisibility[record.id]}
                toggleVisibility={() => this.toggleSectionVisibility(record.id)}
                deleteSection={
                  this.state.interactionMode === "editing"
                    ? () => this.handleRowUpdate(record.id, "deleted", true)
                    : undefined
                }
              />
            ))
          )}
        </div>
        {this.state.interactionMode === "editing" && (
          <div className="row justify-content-start">
            <button
              className="btn btn-secondary mt-3 ml-3"
              onClick={(e) => {
                e.preventDefault();
                this.handleRowAdd();
              }}
            >
              Add New Question
            </button>
          </div>
        )}
        <ActionRun
          action={action}
          displayDeleteModal={this.props.displayDeleteModal}
          goToNext={this.goToNext}
          goToPrevious={() => goToStep(stepIdx - 1)}
          isSubmitting={isSubmitting}
          rebuildStep={this.rebuildStep}
          hasRebuildDropdown
          isSubmitDisabled={this.state.interactionMode === "ordering"}
          submitDisabledMessage="Exit from ordering by clicking 'Done'"
          stepDisabled={disabled}
          renderCustomAction={() => (
            <WeightingDownloadReportBtn action={action} />
          )}
        />
      </div>
    );
  }
}

export default KBIDWeightingQuestionSheet;
