import React from 'react';
import { DropdownButton, Dropdown, FormControl } from 'react-bootstrap'

import ActionRun from '../action-run'
import ActionListSelector from '../action-list-selector'
import ActionListExportOptions from '../action-list-export-options'
import NormalizationTableSelector from './normalization-table-selector'

import axios from 'lib/axios-config'

export default class ActionFindAndReplace extends React.Component {
  constructor(props) {
    super(props);

    this.renderOccurencesTable = this.renderOccurencesTable.bind(this)
    this.renderMappingTables = this.renderMappingTables.bind(this)
    this.renderNormalizationTableSelector = this.renderNormalizationTableSelector.bind(this)

    this.selectListId = this.selectListId.bind(this)
    this.setViewState = this.setViewState.bind(this)
    this.setOccurenceMappings = this.setOccurenceMappings.bind(this)
    this.selectNormalizationTable = this.selectNormalizationTable.bind(this)

    this.BLANK_CHAR = 'BLANK'

    this.state = {
      occurenceShow: {},
      tables: [],
    }
  }

  componentDidMount() {
    const { action } = this.props;

    axios.get('/pipeline/normalization_tables/all')
      .then((response) => {
        this.setState({
          tables: response.data,
        })
      })

    if(action && action.id) {
      const { options } = action;

      if(!options['rename_mappings']) {
        this.selectListId(action.source_list_id)
      } else {
        this.props.selectListId(action.source_list_id)
          .then((list) => {
            this.setViewState(Object.keys(list.occurences), true)
          })
      }
    }
  }

  selectNormalizationTable(table) {
    this.props.setActionOptionValues({rename_mappings: table.mappings})
    this.setViewState(Object.keys(table.mappings), true)
  }

  renderOccurencesTable(name, index) {
    if(!this.state.occurenceShow[name]) {
      return (
        <div key={`occurence-mapping-table-${index}`} className='mb-3'>
          <div className="card">
            <div className="card-header">
              <div className='row'>
                <div className='col-md-10'>
                  { name }
                </div>

                <div className='col-md-2'>
                  <div className='float-right'>
                    <button className='btn btn-sm btn-muted' onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        occurenceShow: {
                          ...this.state.occurenceShow,
                          [name]: true
                        }
                      })
                    }}>
                      <i className="fas fa-chevron-up"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    const { action } = this.props;
    const { rename_mappings } = action.options;
    if(!rename_mappings) return null;

    const mappings = rename_mappings[name]

    return (
      <div key={`occurence-mapping-table-${index}`} className='mb-3'>
        <div className="card">
          <div className="card-header">
            <div className='row'>
              <div className='col-md-10'>
                { name }
              </div>

              <div className='col-md-2'>
                <div className='float-right'>
                  <button className='btn btn-sm btn-muted' onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      occurenceShow: {
                        ...this.state.occurenceShow,
                        [name]: false
                      }
                    })
                  }}>
                    <i className="fas fa-chevron-down"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <table className='table'>
            <thead>
              <tr>
                <th>Original</th>
                <th>Rename</th>
              </tr>
            </thead>
            <tbody>
              {
                Object.keys(mappings).map((mapping, index) => {
                  return (
                    <tr key={`occurences-${name}-${index}`}>
                      <td width="50%">
                        { mapping }
                      </td>

                      <td width="50%">
                        <input 
                          type="text"
                          value={mappings[mapping]}
                          className='form-control'
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.setActionOptionHash('rename_mappings', name, {
                              ...mappings,
                              [mapping]: e.target.value
                            })
                          }}
                        />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  renderNormalizationTableSelector() {
    return (
      <NormalizationTableSelector
        tables={this.state.tables}
        selectNormalizationTable={this.selectNormalizationTable}
      />
    )
  }

  renderMappingTables() {
    const { selectedList } = this.props;
    const { occurences } = selectedList;

    const { rename_mappings } = this.props.action.options;
    if(!rename_mappings) { 
      return (<h6>No occurences generated</h6>);
    }

    const tableNames = Object.keys(rename_mappings)

    return (
      <div className='mt-3'>
        <label><strong>Map Occurences</strong></label>
        {
          tableNames.map((key, index) => {
            return this.renderOccurencesTable(key, index)
          })
        }
      </div>
    )
  }

  setViewState(fields, boolean = true) {
    let occurenceShow = {};
    
    for(var i = 0; i < fields.length; i++) {
      occurenceShow[fields[i]] = boolean
    }

    this.setState({
      occurenceShow: occurenceShow
    })
  }

  setOccurenceMappings(occurences) {
    const keys = Object.keys(occurences);
    const rename_mappings = {}

    for(var i = 0; i < keys.length; i++) {
      const name = keys[i];
      const occurenceArray = occurences[name];

      rename_mappings[name] = {}

      for(var j = 0; j < occurenceArray.length; j++) {
        const field = occurenceArray[j];

        if(field === null || field === '' || /^\s+$/.test(field)) {
          rename_mappings[name][this.BLANK_CHAR] = ''
        } else {
          rename_mappings[name][field] = field
        }
      }
    }

    this.props.setActionOptionValues({rename_mappings})
  }

  selectListId(id) {
    this.props.selectListId(id)
      .then((list) => {
        if(list && list.occurences && Object.keys(list.occurences).length > 0) {
          this.setViewState(Object.keys(list.occurences), true)
          this.setOccurenceMappings(list.occurences)
        }
      })
  }

  render() {
    const { action, lists, selectedList } = this.props;

    return (
      <div>
        <ActionListSelector
          action={action}
          lists={lists}
          list={selectedList}
          title="Lists"
          selectListId={this.selectListId}
        />

        { selectedList ? this.renderNormalizationTableSelector() : null }
        { selectedList ? this.renderMappingTables() : null }

        {
          selectedList ?
            <ActionListExportOptions
              selectedList={selectedList}
              setActionOptionValue={this.props.setActionOptionValue}
              setActionOptionValues={this.props.setActionOptionValues}
              setActionOptionHash={this.props.setActionOptionHash}
              action={action}
            /> : null
        }

        { selectedList ? 
            <ActionRun
              saveAsDraft={this.props.saveAsDraft}
              runAction={this.props.runAction}
              cloneAction={this.props.cloneAction}
              displayDeleteModal={this.props.displayDeleteModal}
              action={action}
            /> : null
        }
      </div>
    );
  }
}
