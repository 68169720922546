import React from 'react';

const SidebarLink = (props) => {
	const isActive = (url) => {
		return window.location.pathname.includes(props.match)
	}

	let className = "vm-sidebar-link"
	className += isActive(props.to) ? " active" : ""

	let children = null

	if(isActive(props.to) && props.children) {
	  children = (
	    <ul className='vm-nested-link-group'>
	      { props.children } 
	    </ul>
	  )
	}

  return (
  	<li className={className}>
  		<a href={ props.to }>
  			<i className={"fas fa-" + props.icon}></i>
  			<span className='ml-3'>
	  			{ props.title }
  			</span>
  		</a>

	    { children }
  	</li>
  )
}

export default SidebarLink;