import { match } from "lib/string";
import filter from "lodash/filter";
import React, { useState } from "react";
import { Dropdown, DropdownButton, FormControl } from "react-bootstrap";

function SelectWithFilter(props) {
  const {
    title = "List",
    onSelect,
    options = [],
    selectedId,
    className = "",
    excludeSet = new Set([]),
    disabled = false,
  } = props;
  const [text, setText] = useState("");
  const filteredOptions = filter(options, (o) => {
    return match(o.name, text) && !excludeSet.has(o.name);
  });

  const handleToggle = (e) => {
    if (!e) setText("");
  };
  return (
    <DropdownButton
      id="dropdown-basic-button"
      className={className}
      title={title}
      onToggle={handleToggle}
      size="md"
      variant="light"
      onSelect={onSelect}
      disabled={disabled}
    >
      <div className="px-3">
        <FormControl
          autoFocus
          className="my-2"
          placeholder="Type to filter..."
          onChange={(e) => {
            setText(e.target.value);
          }}
          value={text}
        />
      </div>

      <div style={{ overflowY: "scroll", maxHeight: "250px" }}>
        {filteredOptions.map((option) => {
          const className = selectedId == option.id ? "active" : "";
          return (
            <Dropdown.Item
              key={`list-option-${option.id}-${option.name}`}
              eventKey={option.id}
              className={className}
            >
              {option.name}
            </Dropdown.Item>
          );
        })}
      </div>
    </DropdownButton>
  );
}

export default SelectWithFilter;
