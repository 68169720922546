import React, { Component, PropTypes } from 'react';

import ResourceContainer from '../../ui/resource-container'
import ResourceHeader from '../../ui/resource-header'

import ActionRow from './action-row'
import ActionHeader from './action-header'
import PaginationDropdown from '../../pagination/pagination-dropdown'
import PaginationSelector from '../../pagination/pagination-selector'

import axios from 'lib/axios-config'
import eventBus from 'lib/event-bus'

import withToaster from '../../common/toaster-wrapper'
import withDeleteManager from '../../common/delete-wrapper'

class ActionsIndex extends Component {
  constructor(props) {
    super(props);

    this.resource = 'Action'

    this.toggleFilterSearch = this.toggleFilterSearch.bind(this)
    this.fetchActions = this.fetchActions.bind(this)
    this.changePerPage = this.changePerPage.bind(this)
    this.changePage = this.changePage.bind(this)

    this.filterOptions = [{
      label: 'Status',
      name: 'status',
      defaultValue: 'all',
      options: [{
        value: 'all',
        label: 'All'
      }, {
        value: 'initialized',
        label: 'Initialized'
      }, {
        value: 'processing',
        label: 'Processing'
      }, {
        value: 'success',
        label: 'Success'
      }, {
        value: 'error',
        label: 'Error'
      }]
    }, {
      label: 'Type',
      name: 'type',
      defaultValue: 'all',
      options: [{
        value: 'all',
        label: 'All'
      }, {
        value: 'API',
        label: 'API'
      }, {
        value: 'Chunk',
        label: 'Chunk'
      }, {
        value: 'Column Headers',
        label: 'Column Headers'
      }, {
        value: 'concat',
        label: 'Concat'
      }, {
        value: 'deduplicate',
        label: 'Deduplicate'
      }, {
        value: 'filter',
        label: 'Filter'
      }, {
        value: 'Find and Replace',
        label: 'Find & Replace'
      }]
    }]

    this.state = {
      filterSearchVisible: false,
      actions: [],
      query: '',
      page: 1,
      perPage: 15,
      total: 0,
      q: '',
      filterValues: {
        status: 'all',
        type: 'all'
      }
    }
  }

  changePage(page) {
    const newPage = page.selected + 1;
    this.setState({
      page: newPage
    });

    this.fetchActions({
      perPage: this.state.perPage, 
      q: this.state.q, 
      page: newPage,
      filterValues: this.state.filterValues,
    })
  }

  changePerPage(count) {
    this.setState({
      perPage: count,
      page: 1,
    })

    this.fetchActions({
      perPage: count,  
      page: 1, 
      q: this.state.q, 
      filterValues: this.state.filterValues
    })
  }

  fetchActions(options) {
    axios.get('/pipeline/actions', {
      params: {
        q: options.q,
        per_page: options.perPage,
        page: options.page,
        ...options.filterValues
      }
    })
      .then((response) => {
        this.setState({
          actions: response.data.actions,
          total: response.data.pagination.total
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  
  componentDidMount() {
    this.fetchActions(this.state)

    eventBus.on('ActionSearch', (data) => {
      this.setState({
        q: data.q,
        page: 1,
        filterValues: data.filterValues,
      })

      this.fetchActions({
        q: data.q, 
        page: 1, 
        perPage: this.state.perPage, 
        filterValues: data.filterValues 
      })
    })
  } 

  componentWillUnmount() {
    eventBus.remove('ActionsSearch')
  }

  toggleFilterSearch(e) {
    e.preventDefault()

    if(this.state.filterSearchVisible) {
      eventBus.dispatch('FilterSearchToggleOff')
    } else {
      eventBus.dispatch('FilterSearchToggleOn', { 
        resource: this.resource,
        filters: this.filterOptions 
      })
    }

    this.setState({filterSearchVisible: !this.state.filterSearchVisible})
  }

  render() {
    return (
      <ResourceContainer>
        <ResourceHeader title='Pipeline' description='Manage your actions here' col={6}>
          <div className='float-right'>
            <button className='btn btn-dark btn-md' onClick={this.toggleFilterSearch}>
              <span className='fas fa-filter mr-2'/>
              Filter
            </button>
            <a href='/pipeline/actions/new' className='btn btn-primary btn-md ml-3'>
              <span className='fas fa-plus mr-2'/>
              New Action 
            </a>
          </div>
        </ResourceHeader>

        <div className='resource-body mt-2'>
          <div className='resource-table'>
            <table className='table'>
              <ActionHeader /> 
              
              <tbody>
                {
                  this.state.actions.map((action, index) => {
                    return (
                      <ActionRow 
                        key={`action-row-${index}`} 
                        index={index} 
                        action={action} 
                      />
                    )
                  })
                }
                <tr>
                  <td colSpan={2}>
                    <PaginationDropdown
                      perPage={this.state.perPage}
                      changePerPage={this.changePerPage}
                    />
                  </td>

                  <td colSpan={4}>
                    <span className='float-right'>
                      { this.state.total > 0 ? 
                          <PaginationSelector
                            page={this.state.page}
                            perPage={this.state.perPage}
                            changePage={this.changePage}
                            total={this.state.total}
                          />
                        : null
                      }
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ResourceContainer>
    );
  }
}

export default withToaster(withDeleteManager(ActionsIndex));
