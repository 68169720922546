import React from 'react';

export default class UIInputEditor extends React.Component {
  constructor(props) {
    super(props);

    this.enableHover = this.enableHover.bind(this)
    this.disableHover = this.disableHover.bind(this)

    this.enableEditing = this.enableEditing.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)

    this.cancelEdit = this.cancelEdit.bind(this)
    this.saveEdit = this.saveEdit.bind(this)

    this.state = {
      isEditing: false,
      isHovering: false,
      name: ''
    }
  }

  componentDidMount() {
    this.setState({name: this.props.name})
  }

  handleInputChange(e) {
    e.preventDefault();
    this.setState({
      name: e.target.value
    })
  }

  enableEditing(e) {
    e.preventDefault();
    this.setState({
      isEditing: true
    })
  }

  enableHover(e) {
    e.preventDefault();
    if(this.state.isEditing) return;
    this.setState({
      isHovering: true
    })
  }

  disableHover(e) {
    e.preventDefault();
    if(this.state.isEditing) return;
    this.setState({
      isHovering: false 
    })
  }

  saveEdit(e) {
    e.preventDefault();
    if(this.state.name === '') return;

    if(this.state.name === this.props.name) {
      this.setState({
        isEditing: false
      })
    }

    this.props.swapFields(this.props.name, this.state.name)
      .then(() => {
        this.setState({
          isEditing: false
        })
      })
      .catch(() => {
      })
  }

  cancelEdit(e) {
    e.preventDefault();

    this.setState({
      isEditing: false,
      isHovering: false,
      name: this.props.name
    })
  }

  render() {
    if(this.state.isEditing) {
      return (
        <form>
          <div className='d-flex'>
            <input
              type="text"
              className='form-control'
              value={this.state.name}
              style={{'width': this.props.inputWidth || '50%'}}
              onChange={this.handleInputChange}
            />

            <div className='btn-group ml-2'>
              <button className='btn btn-sm btn-dark' onClick={this.saveEdit}>
                <i className="fas fa-check"></i>
              </button>
              <button className='btn btn-sm btn-muted' onClick={this.cancelEdit}>
                <i className="fas fa-times"></i>
              </button>
            </div>
          </div>
        </form>
      )
    } else {
      return (
        <div className='d-flex'
          onMouseEnter={this.enableHover}
          onMouseLeave={this.disableHover}
        >
          <div className=''>
            { this.state.name }
          </div>

          { this.state.isHovering ?
            <button className='btn btn-sm btn-muted ml-2' onClick={this.enableEditing}>
              <i className="fas fa-pencil-alt"></i>
            </button> : <span className='pr-5'/>
          }
        </div>
      );
    }
  }
}

