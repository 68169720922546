import React from 'react';
import moment from 'moment'

const VersionString = ({version}) => {
  return (
    <>
      {`V${version.version}`}
    </>
  )
}

export default VersionString;