import React from 'react';

const ActionRun = (props) => {
  const { action } = props;

  return (
    <div className='row mt-3'>
      <div className='col-md-12'>
        <div className='float-right'>
          <a href='/pipeline/actions' className='btn btn-light'>
            Cancel
          </a>

          {
            action && action.id ?
              <button type="button" className='btn btn-dark ml-3' onClick={props.cloneAction}>
                Clone
              </button> : null
          }

          {
            action && action.id ?
              <button type="button" className='btn btn-danger ml-3' onClick={props.displayDeleteModal}>
                Delete
              </button> : null
          }
          
          
          <button type='button' className='btn btn-secondary ml-3' onClick={props.saveAsDraft}>
            Save As Draft
          </button>

          <button type='button' className='btn btn-success ml-3' onClick={props.runAction}>
            Run
          </button>
        </div>
      </div>
    </div>
  )
}

export default ActionRun;