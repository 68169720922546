import React from 'react'
import moment from 'moment'
import filesize from 'filesize'
import StatusBadge from '../common/status-badge'

const ListRow = (props) => {
  const { index, list } = props;

  return (
    <tr key={`list-${index}`}>
      <td>
        <div style={{'width': '200px', 'wordWrap': 'break-word'}}>
          { list.name }
        </div>
      </td>
      <td>
        { list.parent_id ? 'Child' : 'Parent'}
      </td>
      <td>
        { list && list.rows ? list.rows.toLocaleString('en-US') : null }
      </td>
      <td>
         { list && list.headers ? list.headers.length : null }
      </td>
      <td>
        { filesize(list.size, {base: 10}) }
      </td>
      <td>
        <StatusBadge status={list.status} />
      </td>
      <td>
        { moment(list.created_at).format('MM/DD/YYYY') }
      </td>
      <td>
        <a href={ `/lists/${list.id}` }>
          View
        </a> |&nbsp;
        <a href={ `/lists/${list.id}/edit` }>
          Edit 
        </a>
      </td>
    </tr>
  )
}

export default ListRow;